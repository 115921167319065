/**
 * @description Change base root main color (--company-color)
 * @param company - Could be 'santander' or 'sos'
 */
export const changeLookAndFeel: (company: string) => void = (company: string): void => {
  const selectedCompany: string = company.toLowerCase();

  if (selectedCompany === 'sos') {
    return;
  } else if (selectedCompany === 'santander') {
    document.documentElement.setAttribute('data-theme', 'santander');
  }
};
