import { Directive, AfterViewChecked, OnInit, Input, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appSelectBox]'
})
export class SelectBoxDirective implements AfterViewChecked, OnInit {
  @Input() inputAriaLabel: string;

  public currentElement: any;

  /**
   * Constructor function
   * @param el - a ElementRef object
   * @param translate - a TranslateService object
   */
  constructor(private el: ElementRef, private translate: TranslateService) {
    this.el = el;
    this.translate = translate;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.currentElement = this.el.nativeElement;
  }

  /**
   * AfterViewChecked method
   */
  ngAfterViewChecked(): void {
    const inputId: any = [...this.currentElement.querySelectorAll('input')][0];
    const selectElem: any = this.currentElement.querySelector('select');
    const liElements: Array<any> = [...this.currentElement.querySelectorAll('li', 'ui-dropdown-item')];

    let ariaLabel: string;

    this.translate.get(this.inputAriaLabel).subscribe((res) => (ariaLabel = res));

    selectElem.setAttribute('aria-label', ariaLabel);
    inputId.setAttribute('aria-label', ariaLabel);
    liElements.forEach((li) => {
      li.setAttribute('tabindex', 0);
    });
  }
}
