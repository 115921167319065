import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
// Confirmation component
export class ConfirmationModalComponent {
  // Recived text to show in the modal
  @Input() textToShow: string;
}
