import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DevolverMensaje } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/DevolverMensaje';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';

@Component({
  selector: 'app-attach-note-modal',
  templateUrl: './attach-note-modal.component.html',
  styleUrls: ['./attach-note-modal.component.scss']
})
export class AttachNoteModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  // Manage subscriptions
  private subscriptions: Subscription[] = [];
  private orderId: string;

  public noteForm: FormGroup = new FormGroup({
    note: new FormControl('', Validators.required)
  });

  public invalidForm: boolean;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   * @param authServ - a AuthService object
   */
  constructor(private manageReqSOSUtils: ManageRequestServiceUtils, private authServ: AuthService) {
    this.manageReqSOSUtils = manageReqSOSUtils;
    this.authServ = authServ;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Check if the noteForm is valid and call a method to upload the note
   */
  public handleClick(): void {
    if (this.noteForm.valid) {
      this.invalidForm = false;
      const note: any = this.noteForm.get('note').value;

      this.subscriptions.push(
        this.uploadNote$(note)
          .pipe(
            finalize(() => {
              this.manageSelfDisplay.emit(false);
            })
          )
          .subscribe(
            (res: any) => {
              this.successOperation.emit({ success: true, text: 'NOTE-ATTACHED', translate: true });
              this.updateTableData.emit();
            },
            (err: HttpErrorResponse) => {
              this.successOperation.emit({ success: false });
            }
          )
      );
    } else if (!this.noteForm.valid) {
      this.invalidForm = true;
    } else {
      return;
    }
  }

  /**
   * Upload a note
   * @param note - The note to insert
   * @returns The saved note
   */
  private uploadNote$(note: string): Observable<DevolverMensaje | HttpErrorResponse> {
    const orderID: string = this.orderId;
    const encargoID = this.selectedDetail.datosCaso.casoID || '';
    const userId = this.authServ.getUserParam('userName') + ' ' + this.authServ.getUserParam('userSurname');

    const isProcesal = (this.selectedDetail && (this.selectedDetail.datosCaso.tipoFDC || '').toLowerCase() === 'procesal') || false;

    let procesal: string = null;
    if (isProcesal) {
      procesal = 'FDC-Procesal';
    }

    return this.manageReqSOSUtils.provAsegInsertarNota$(encargoID, orderID, userId, note, procesal);
  }
}
