import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component {

  /**
   * Constructor function
   * @param location - a Location object
   * @param router - a Router object
   */
  constructor(private location: Location, private router: Router) {
    this.location = location;
    this.router = router;
  }

  /**
   * Navigate the previous page
   */
  onNavigateBack(): void {
    this.location.back();
  }

  /**
   * Navigate a url
   * @param url - the url to navigate
   */
  onNavigateTo(url: string): void {
    this.router.navigate([url]);
  }
}
