import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { BootService } from '../providers/local/boot/boot.service';
import { Numbers } from '../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenInterceptor implements HttpInterceptor {

  /**
   * Constructor function
   * @param http - a HttpClient object
   * @param bootService - a BootService object
   */
  constructor(public http: HttpClient, private bootService: BootService) {
    this.http = http;
    this.bootService = bootService;
  }

  /**
   * Check the URL of the request and performs additional actions if the URL meets certain conditions.
   * @param req - HttpRequest object
   * @param next - HttpHandler object
   * @returns HttpEvent observable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url: string = req.url;
    let returnPipe = false;

    // return next.handle(req);return next.handle(req);
    if (url.includes('/identidad-digital') || url.includes('/isos/datos-comunes')) {
      returnPipe = true;
    }

    let pipeToReturn: Observable<HttpEvent<any>> = next.handle(req);

    if (returnPipe) {
      pipeToReturn = next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          const foo: (tokenObservable: Observable<any>, localStorageId: string) => Observable<HttpEvent<any>> = this.manageErrorRequest(err, req, next);
          let fooToReturn: Observable<HttpEvent<any>> = foo(this.bootService.getTokenRequest('comunes'), 'X-TOKEN-DatosComunes');

          if ( err.url.includes('/identidad-digital')) {
            fooToReturn = foo(this.bootService.getTokenRequest('identidad'), 'X-TOKEN-IdentidadDigital');
          }
          return fooToReturn;
        })
      );
    }
    return pipeToReturn;
  }

  /**
   * manage a error request
   * @param err - the error
   * @param req - the request
   * @param next - a HttpHandler
   * @returns - HttpEvent observable
   */
  private manageErrorRequest(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): (tokenObservable: Observable<any>, localStorageId: string) => Observable<HttpEvent<any>> {
    return (tokenObservable: Observable<any>, localStorageId: string) => {
      if (err instanceof HttpErrorResponse && (err.status === Numbers.number401 || err.status === Numbers.number403)) {
        return tokenObservable.pipe(
          flatMap((result) => {
            localStorage.setItem(localStorageId, result.access_token);
            if (result.access_token) {
              req = req.clone({
                setHeaders: {
                  authorization: `Bearer ${result.access_token}`
                }
              });
            }
            return next.handle(req);
          })
        );
      }
      throw err;
    };
  }
}
