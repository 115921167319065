import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';

import { DevolverAvancesPega } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvancesPega';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { DevolverMensaje } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/DevolverMensaje';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TableDataSource } from '../../no-category/table/models/table';
import { Utils } from '../../../utils/utils';
import { StatusModalService } from '../status-modal/status-modal.service';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';
import { ProveedoresService } from 'projects/portal-proveedores-front/src/app/core/providers/api/proveedores/proveedores.service';

@Component({
  selector: 'app-change-finished-modal',
  templateUrl: './change-finished-modal.component.html',
  styleUrls: ['./change-finished-modal.component.scss']
})
export class ChangeFinishedModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedData: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  // Manage Subscriptions
  private subscriptions: Subscription[] = [];
  public fileStored: File;
  public finishedForm: FormGroup;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   * @param authServ - a AuthService object
   * @param filterService - a FilterService object
   * @param statusModal - a StatusModalService object
   */
  constructor(
    private manageReqSOS: ManageRequestSosService,
    private manageReqSOSUtils: ManageRequestServiceUtils,
    private authServ: AuthService,
    private filterService: FilterService,
    readonly statusModal: StatusModalService
  ) {
    this.manageReqSOS = manageReqSOS;
    this.manageReqSOSUtils = manageReqSOSUtils;
    this.authServ = authServ;
    this.filterService = filterService;
    this.statusModal = statusModal;
  }

  /**
   * onInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));

    this.finishedForm = new FormGroup({
      finishedDocsSelected: new FormControl([...this.selectedData], Validators.required),
      finishedFile: new FormControl(null, Validators.required)
    });
  }

  /**
   * onDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all the active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Save the files
   * @param files - files to save
   */
  saveFiles(files: File[]): void {
    this.finishedForm.value.finishedFile = files;
  }

  /**
   * Upload a file if exists.
   */
  handleClick(): void {
    if (this.fileStored) {
      this.subscriptions.push(
        this.uploadDeliverFile$([...this.selectedData]).subscribe(
          (uploadDeliverFileRes: DevolverMensaje) => {
            this.successOperation.emit({ success: true, text: 'DELIVERY-PROOF-ATTACHED', translate: true });

            this.sendToFinishedSubscription(true);
          },
          (err: HttpErrorResponse) => {
            this.successOperation.emit({ success: false, text: err.error.mensaje, translate: false });
            const errorMsg: string = Utils.error.displayServerErrorInConsole(err);
            this.manageReqSOS.insertarErrorTabla(errorMsg);
          }
        )
      );
    } else if (this.fileStored === undefined) {
      this.sendToFinishedSubscription(false);
    } else {
      return;
    }
  }

  /**
   * Send the state to finished
   * @param hasAttachedFile - indicate if has been attached a file.
   */
  private sendToFinishedSubscription(hasAttachedFile: boolean): void {
    let successMessage = 'TO-FINISHED-WITHOUT-PROOF';
    if (hasAttachedFile) {
      successMessage = 'TO-FINISHED-WITH-PROOF';
    }
    this.subscriptions.push(
      this.advanceStateToFinished$([...this.selectedData])
        .pipe(
          delay(Numbers.number2000),
          finalize(() => {
            this.filterService.clearSavedSelectedRows();
            this.closeModalComponent();
            this.updateTableDataWithDelay();
          })
        )
        .subscribe((res: DevolverAvancesPega) => {
          this.successOperation.emit({ success: true, text: successMessage, translate: true });
        }, (error) => {
          if (error.status === Numbers.number504) {
            this.statusModal.displayErrorMessage('TIMEOUT-ERROR', true);
          }
        })
    );
  }

  /**
   * close modal component
   */
  private closeModalComponent(): void {
    this.manageSelfDisplay.emit(false);
  }

  /**
   * Update table with 3 seconds delay
   */
  private updateTableDataWithDelay(): void {
    setTimeout(() => {
      this.updateTableData.emit();
    }, Numbers.number3000);
  }

  /**
   * Set the property fileStored with the recived parameter
   * @param event - the file to assign
   */
  public storeFile(event: any): void {
    this.fileStored = event;
  }

  /**
   * Save a deliver file
   * @param selectedData - the deliver file
   * @returns a message and code response with the results
   */
  private uploadDeliverFile$(selectedData: TableDataSource[]): Observable<DevolverMensaje | HttpErrorResponse> {
    const identificador: any = selectedData[0].numListadoEntrega;
    const uploadFile: File = this.fileStored;

    return this.manageReqSOSUtils.provAsegGuardarJustificanteEntrega$(identificador, uploadFile);
  }

  /**
   * change the order state to finished
   * @param selectedData - the order
   * @returns -
   */
  private advanceStateToFinished$(selectedData: any[]): Observable<DevolverAvancesPega | HttpErrorResponse> {
    const listaReferencias: any = selectedData.map((elem) => {
      let fichEntrega = false;
      if (this.fileStored) {
        fichEntrega = true;
      }
      return {
        identificador: (elem.encargoID as string) || (elem.datosCaso.encargoID as string),
        ficheroEntregaAdjuntado: fichEntrega
      };
    });

    const idioma: string = this.authServ.getUserParam('lang');
    const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

    return this.manageReqSOS.provActualizarEstadoPasandoSiguiente$(listaReferencias, idioma, USER_ID);
  }
}
