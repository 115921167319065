import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appAvoidSpecialCharacters]'
})
export class AvoidSpecialCharactersDirective {
  public regexStr = '^[a-zA-ZÀ-ÿ0-9_ ]*$';
  @Input() isAlphaNumeric: boolean;

  /**
   * constructor function
   * @param el - a ElementRef object
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }

  /**
   * Verify if the character matches the regular expression
   * @param event - the pressed character
   * @returns if the character matches the regExp
   */
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): boolean {
    return new RegExp(this.regexStr).test(event.key);
  }

  /**
   * Validate the fields
   * @param event - a paste event from the clipboard
   */
  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent): void {
    this.validateFields(event);
  }

  /**
   * Replace any character that is not a letter, a number, a whitespace, or an underscore with an empty string.
   * @param event - clipBoard event
   */
  validateFields(event: KeyboardEvent): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^[a-zA-ZÀ-ÿ0-9_ ]/g, '');
      event.preventDefault();
    }, Numbers.number100);
  }
}
