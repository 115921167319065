import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeycloakGuard implements CanActivate {

  /**
   * Constructor function
   * @param keyCloakServ - a KeycloakService object
   * @param router - a Router object
   */
  constructor(private keyCloakServ: KeycloakService, private router: Router) {
    this.keyCloakServ = keyCloakServ;
    this.router = router;
  }

  /**
   * decide if a route can be activated
   * @returns if a route can be activated
   */
  canActivate(): Promise<boolean> | boolean {
    return (async () => {
      let resultOK = false;
      try {
        const userData: any = await this.keyCloakServ.loadUserProfile();
        const userType: any = await userData.attributes.userType[0];
        if (userType === 'Administrador') {
          resultOK = true;
        }
        if (!resultOK) {
          throw new Error('Navegación no permitida');
        }
      } catch (err) {
        this.router.navigate(['/certifications']);
        console.warn(err);
        resultOK = false;
      }
      return resultOK;
    })();
  }
}
