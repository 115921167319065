// Import components, modules, pipes, directives...
// Included primeNg modules
// Included angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { SortByPipe } from './pipes/sort-by.pipe';
import { FilterMenuComponent } from './components/menus/filter-menu/filter-menu.component';
import { ButtonComponent } from './components/no-category/button/button.component';
import { ElemCounterComponent } from './components/no-category/elem-counter/elem-counter.component';
import { PageSelectorComponent } from './components/selectors/page-selector/page-selector.component';
import { UserMenuComponent } from './components/menus/user-menu/user-menu.component';
import { MessagePopUpComponent } from './components/no-category/message-pop-up/message-pop-up.component';
import { DataViewerDropdownComponent } from './components/no-category/data-viewer-dropdown/data-viewer-dropdown.component';
import { DataFilterComponent } from './components/no-category/data-filter/data-filter.component';
import { HelpModalComponent } from './components/modals/help-modal/help-modal.component';
import { BackdropRibbonModalComponent } from './components/modals/backdrop-ribbon-modal/backdrop-ribbon-modal.component';
import { TimerComponent } from './components/no-category/timer/timer.component';
import { TableComponent } from './components/no-category/table/table.component';
import { TabsComponent } from './components/selectors/tabs/tabs.component';
import { NotificationMenuComponent } from './components/menus/notification-menu/notification-menu.component';
import { MobileFilterMenuComponent } from './components/menus/mobile-filter-menu/mobile-filter-menu.component';
import { BasicModalComponent } from './components/modals/basic-modal/basic-modal.component';
import { CardDataComponent } from './components/cards/card-data/card-data.component';
import { UploadFileComponent } from './components/no-category/upload-file/upload-file.component';
import { ControlIconsComponent } from './components/no-category/table/control-icons/control-icons.component';
import { BackdropNavComponent } from './components/no-category/backdrop-nav/backdrop-nav.component';
import { PaginatorComponent } from './components/no-category/paginator/paginator.component';
import { PreventDefaultInputDirective } from './directives/prevent-default-input.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { InputAriaDirective } from './directives/input-aria.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { NavigateCalendarDirective } from './directives/navigate-calendar.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { InputMaskModule } from 'primeng/inputmask';
import { ThousandSepDirective } from './directives/thousand-sep.directive';
import { SelectBoxDirective } from './directives/select-box.directive';
import { CaptureIntroDirective } from './directives/capture-intro.directive';
import { AvoidSpecialCharactersDirective } from './directives/avoid-special-characters.directive';
import { ChangeIncidenceModalComponent } from './components/modals/change-incidence-modal/change-incidence-modal.component';
import { ChangeFinishedModalComponent } from './components/modals/change-finished-modal/change-finished-modal.component';
import { DeliverListModalComponent } from './components/modals/deliver-list-modal/deliver-list-modal.component';
import { RegisterListModalComponent } from './components/modals/register-list-modal/register-list-modal.component';
import { AttachFileModalComponent } from './components/modals/attach-file-modal/attach-file-modal.component';
import { AttachNoteModalComponent } from './components/modals/attach-note-modal/attach-note-modal.component';
import { UpdateDateModalComponent } from './components/modals/update-date-modal/update-date-modal.component';
import { UpdateUserModalComponent } from './components/modals/update-user-modal/update-user-modal.component';
import { DeleteFileModalComponent } from './components/modals/delete-file-modal/delete-file-modal.component';
import { DeleteNoteModalComponent } from './components/modals/delete-note-modal/delete-note-modal.component';
import { ChangeNewFinishedModalComponent } from './components/modals/change-new-finished-modal/change-new-finished-modal.component';
import { ChangeAcceptFinishedModalComponent } from './components/modals/change-accept-finished-modal/change-accept-finished-modal.component';
import { ChangeActiveFinishedModalComponent } from './components/modals/change-active-finished-modal/change-active-finished-modal.component';
import { StatusModalComponent } from './components/modals/status-modal/status-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { AcceptsRegexCharactersDirective } from './directives/accepts-regex-characters.directive';
import { ConvertDataInfoPipe } from './pipes/convert-data-info.pipe';
import { ExpirationPipe } from './pipes/expiration.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { PrintEnvelopeModalComponent } from './components/modals/print-envelope-modal/print-envelope-modal.component';
import { PrintExcelModalComponent } from './components/modals/print-excel-modal/print-excel-modal.component';
import { EnvelopeModalComponent } from './components/modals/envelope-modal/envelope-modal.component';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { DocuListModalComponent } from './components/modals/docu-list-modal/docu-list-modal.component';

// Components
// These components are common to the whole app
const components: any = [
  FilterMenuComponent,
  ElemCounterComponent,
  PageSelectorComponent,
  UserMenuComponent,
  MessagePopUpComponent,
  DataFilterComponent,
  DataViewerDropdownComponent,
  ButtonComponent,
  HelpModalComponent,
  BackdropRibbonModalComponent,
  TimerComponent,
  TableComponent,
  TabsComponent,
  NotificationMenuComponent,
  PaginatorComponent,
  MobileFilterMenuComponent,
  BasicModalComponent,
  CardDataComponent,
  UploadFileComponent,
  ControlIconsComponent,
  BackdropNavComponent,
  LoaderComponent,
  ChangeIncidenceModalComponent,
  ChangeFinishedModalComponent,
  DeliverListModalComponent,
  RegisterListModalComponent,
  AttachFileModalComponent,
  AttachNoteModalComponent,
  UpdateDateModalComponent,
  UpdateUserModalComponent,
  DeleteFileModalComponent,
  DeleteNoteModalComponent,
  ChangeNewFinishedModalComponent,
  ChangeAcceptFinishedModalComponent,
  ChangeActiveFinishedModalComponent,
  StatusModalComponent,
  ConfirmationModalComponent,
  PrintEnvelopeModalComponent,
  EnvelopeModalComponent,
  StatusModalComponent,
  ConfirmationModalComponent,
  PrintExcelModalComponent,
  InfoModalComponent,
  DocuListModalComponent
];

// Directives
// These directives are common to the whole app
const directives: any = [
  PreventDefaultInputDirective,
  InputAriaDirective,
  AutoFocusDirective,
  OnlyNumbersDirective,
  NavigateCalendarDirective,
  ThousandSepDirective,
  SelectBoxDirective,
  CaptureIntroDirective,
  AvoidSpecialCharactersDirective,
  AcceptsRegexCharactersDirective
];
// Pipes
// These pipes are common to the whole app
const pipes: any = [SortByPipe, ConvertDataInfoPipe, ExpirationPipe, ReversePipe];

// Use PrimeNg. Indicate the modules used
// These modules are common to the whole app
const primeNGModules: any = [ButtonModule, CheckboxModule, DialogModule, TableModule, DropdownModule, InputTextModule, CalendarModule, RadioButtonModule, InputMaskModule];

// Modules used
// These modules are common to the whole app
const modules: any = [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, RouterModule, HttpClientModule, ...primeNGModules];

@NgModule({
  // Declarate all modules, components, directives and pipes
  declarations: [...components, ...directives, ...pipes],
  entryComponents: [MobileFilterMenuComponent],
  // Import modules
  imports: [...modules],
  // Export all modules, components, directives and pipes which will be used by other modules
  exports: [...modules, ...components, ...directives, ...pipes]
})
// Shared Module class
export class SharedModule {}
