import { userInfo } from 'os';
import { SelectItem } from 'primeng/components/common/selectitem';

// User data object
export interface UserData {
  name: string;
  profile: string;
  initials: string;
  color: string;
}

// Personal user data model
export interface PersonalUserData {
  user: string;
  name: string;
  surname1: string;
  surname2: string;
  email: string;
  docType: string;
  id: string;
}

// User type: Tomador, Asegurado, Beneficiario, AD
export enum UserType {
  TM = 'Tomador',
  AS = 'Asegurado',
  BE = 'Beneficiario',
  AD = 'AD'
}

// User data: Name, surname1, surname2, email.
export const USER_DATA_MAP_USER_DATA = {
  nombre: 'name',
  primerApellido: 'surname1',
  segundoApellido: 'surname2',
  correo: 'email',
  'isos.tipoDocumentoIdentidad': 'docType',
  'isos.usuario': 'id'
};

// User data document type options: DNI, NIF, CIF
export const USER_DATA_DOCUMENT_TYPE_OPTIONS = [
  { label: 'DNI', value: 'DNI' },
  { label: 'NIF', value: 'NIF' },
  { label: 'CIF', value: 'CIF' }
];
