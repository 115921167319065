import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  /**
   * Constructor function
   */
  constructor() {}

  /**
   * decide if a route can be activated
   * @returns if a route can be activated
   */
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
