import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { UpdateCita } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/UpdateCita';
import { DevolverAvances } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvances';
import { DevolverAvancesPega } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvancesPega';
import { ListaReferencias } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListaReferencias';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { ManageRequestServiceUtilsExtended } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils-extended';
import { ResMaestros } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/ResMaestros';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { StatusModalService } from '../status-modal/status-modal.service';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { ResultadoMaestros } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/ResultadoMaestros';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-change-new-finished-modal',
  templateUrl: './change-new-finished-modal.component.html',
  styleUrls: ['./change-new-finished-modal.component.scss']
})
export class ChangeNewFinishedModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  private subscriptions: Subscription[] = [];
  private orderId: string;
  public rejectionReasons: Array<ResMaestros>;
  public rejectForm: FormGroup = new FormGroup({
    selectedRejReason: new FormControl(null, Validators.required),
    rejReason: new FormControl(null)
  });
  public invalidForm: string;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param manageReqSOSUtilsExtended - a ManageRequestServiceUtilsExtended object
   * @param authServ - a AuthService object
   * @param filterService - a FilterService object
   * @param statusModal - a StatusModalService object
   */
  constructor(
    private manageReqSOS: ManageRequestSosService,
    private manageReqSOSUtilsExtended: ManageRequestServiceUtilsExtended,
    private authServ: AuthService,
    private filterService: FilterService,
    readonly statusModal: StatusModalService
  ) {
    this.manageReqSOS = manageReqSOS;
    this.manageReqSOSUtilsExtended = manageReqSOSUtilsExtended;
    this.authServ = authServ;
    this.filterService = filterService;
    this.statusModal = statusModal;
  }

  /**
   * onInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;

    this.subscriptions.push(
      this.manageReqSOSUtilsExtended
        .maestrosMostrarMotivosCancelacion$()
        .pipe(
          map((data: ResultadoMaestros) => {
            // Set OTHER value as the last one
            let other: ResMaestros;
            const dataFiltered: ResMaestros[] = data.pxResults.filter((elem) => {
              if (elem.id === 'Other') {
                other = elem;
              }

              return elem.id !== 'Other';
            });
            dataFiltered.push(other);

            return dataFiltered;
          })
        )
        .subscribe((res: Array<ResMaestros>) => {
          const initialVaule: string = res[0].id;
          this.rejectForm.controls.selectedRejReason.setValue(initialVaule);

          this.rejectionReasons = res;
        })
    );
  }

  /**
   * OnDestoy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Determine if the reason input is valid
   * @param selectedValue -selected value
   * @returns - if is valid
   */
  public reasonInputValid(selectedValue: string): boolean {

    let isValid = true;
    if (selectedValue === 'Other' && !this.rejectForm.get('rejReason').value) {
      isValid = false;
    }
    return isValid;
  }

  /**
   * Determine if the rejectForm is valid and update the order data.
   * @returns void
   */
  public handleClick(): void {
    if (this.rejectForm.valid && this.reasonInputValid(this.rejectForm.get('selectedRejReason').value)) {
      this.invalidForm = null;

      this.subscriptions.push(
        this.updateOrderData$()
          .pipe(
            finalize(() => {
              this.manageSelfDisplay.emit(false);
            })
          )
          .subscribe((res) => {
            let languageId: number = Numbers.number2;
            if (this.authServ.getUserParam('lang') === 'ES') {
              languageId = Numbers.number0;
            }

            const refId: Array<ListaReferencias> = [
              {
                identificador: this.orderId
              }
            ];
            this.advanceState$(refId, languageId).subscribe((data: any) => {
              this.filterService.clearSavedSelectedRows();
              this.successOperation.emit({
                success: true,
                text: data.mensaje,
                translate: false
              });
              this.updateTableData.emit();
            }, (error) => {
              if (error.status === Numbers.number504) {
                this.statusModal.displayErrorMessage('TIMEOUT-ERROR', true);
              }
              this.filterService.clearSavedSelectedRows();
              this.updateTableData.emit();
            });
          })
      );
    } else if (!this.reasonInputValid(this.rejectForm.get('selectedRejReason').value)) {
      this.invalidForm = 'invalidState2';
    } else {
      this.invalidForm = 'invalidState1';
      return;
    }
  }

  /**
   * Update the order data
   * @returns th results
   */
  private updateOrderData$(): Observable<DevolverAvances | HttpErrorResponse> {
    const idOrder: string = this.orderId;

    let bodyOrder: UpdateCita;

    if (this.selectedDetail.estado === 'ACEPTADO') {
      bodyOrder = {
        motivoCancelacionCita: this.rejectForm.get('selectedRejReason').value,
        observacionCancelacionCita: this.rejectForm.get('rejReason').value || '',
        resultadoAsesoramiento: 'Canceled'
      };
    } else {
      bodyOrder = {
        motivoCancelacionCita: this.rejectForm.get('selectedRejReason').value,
        observacionCancelacionCita: this.rejectForm.get('rejReason').value || ''
      };
    }

    return this.manageReqSOS.provActualizarAsesoramiento$(idOrder, bodyOrder);
  }

  /**
   * Change state of a references list
   * @param body - a reference list that change state
   * @param lang - language id
   * @returns - The results
   */
  private advanceState$(body: ListaReferencias[], lang: number): Observable<DevolverAvancesPega | HttpErrorResponse> {
    const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

    return this.manageReqSOS.provActualizarEstadoPasandoSiguiente$(body, lang.toString(), USER_ID);
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
