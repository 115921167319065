import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from './tab.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: [ './tabs.component.scss' ]
})
export class TabsComponent {
  @Input() tabs: Tab[];
  @Input() counter$?: Observable<number>;
  @Input() selectedTab: string;

  @Output() tabChange = new EventEmitter<{
    index: number;
    tab: Tab;
  }>();
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }

  /**
   * Change the tab
   * @param index - the index tab
   * @param tab - the tab
   */
  public onChangeTab(index: number, tab: Tab): void {
    this.tabChange.emit({
      index,
      tab
    });
  }
}
