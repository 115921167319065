import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNavigateCalendar]'
})
export class NavigateCalendarDirective implements AfterViewInit {

  /**
   * constructor function
   * @param el - a ElementRef object
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }

  /**
   * AfterViewInit method
   */
  ngAfterViewInit(): void {
    const prevButton: any = [...this.el.nativeElement.querySelectorAll('.ui-datepicker-prev')][0];
    const nextButton: any = [...this.el.nativeElement.querySelectorAll('.ui-datepicker-next')][0];
    const currentMonth: any = [...this.el.nativeElement.querySelectorAll('.ui-datepicker-month')][0].innerHTML;
    const currentYear: any = [...this.el.nativeElement.querySelectorAll('.ui-datepicker-year')][0].innerHTML;

    const prevButtonMessage = 'Retroceder al mes anterior, actualmente en ' + currentMonth + 'del ' + currentYear;
    const nextButtonMessage = 'Avanzar al mes siguiente, actualmente en ' + currentMonth + 'del ' + currentYear;
    prevButton.setAttribute('aria-label', prevButtonMessage );
    nextButton.setAttribute('aria-label', nextButtonMessage );

    this.fixNavigation();
  }

  /**
   * Adjust the navigation and accessibility of certain elements on the page by adding or removing specific
   * attributes and classes.
   */
  private fixNavigation(): void {
    const failArrows: any = [...this.el.nativeElement.querySelectorAll('a.ng-tns-c29-2')];
    const calendarDays: any = [...this.el.nativeElement.querySelectorAll('a.ui-state-default')];
    const hourPicker: any = [...this.el.nativeElement.querySelectorAll('span.pi')];

    failArrows.forEach((a) => {
      a.removeAttribute('tabindex');
    });

    calendarDays.forEach((a) => {
      a.classList.add('u-focus');
      a.setAttribute('tabindex', 0);
    });

    hourPicker.forEach((span) => {
      span.classList.add('u-focus');
      span.setAttribute('tabindex', 0);
    });
  }

  /**
   * Make an action when the pressed key is the white space
   * @param event - the keydown event
   */
  @HostListener('keydown', ['$event'])
  keyNavigation(event: any): void {
    if (event.key === ' ') {
      event.preventDefault();
      event.target.click();
    }
  }
}
