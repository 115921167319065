// All utils imports
import { markAllInputsAsTouched, transformIntoDropdownValues } from './library/form.utils';
import { convertDate, extractCurrentDateWithoutHours } from './library/date.utils';
import { displayServerErrorInConsole, displayClientErrorInConsole } from './library/error.utils';
import { changeLookAndFeel } from './library/look-feel.utils';
import { downloadPDF, showPDF } from './library/manage-pdf.utils';
import { simplifyUserDataSL } from './library/manage-server-data.utils';
import { generateRandomPass } from './library/pass.utils';
import { speak } from './library/screen-reader.util';
import { getValidatorFromType, validateNIF } from './library/validator-gen.utils';
import { EMAIL_REGEX, NAME_REGEX } from './library/variables.utils';
import { capitalizeFirstLetterOfEveryWord, deleteUnderscoreAndCapitalize } from './library/text.utils';

// Create a variable utils with several utils
// Included errors, forms and more.
export const Utils = {
  date: { extractCurrentDateWithoutHours, convertDate },
  error: { displayServerErrorInConsole, displayClientErrorInConsole },
  form: { markAllInputsAsTouched, transformIntoDropdownValues },
  lookFeel: { changeLookAndFeel },
  managePdfUtils: { downloadPDF, showPDF },
  manageServerData: { simplifyUserDataSL },
  pass: { generateRandomPass },
  screenReader: { speak },
  text: { capitalizeFirstLetterOfEveryWord, deleteUnderscoreAndCapitalize },
  variables: { EMAIL_REGEX, NAME_REGEX },
  validatorGen: { getValidatorFromType, validateNIF }
};
