import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestData } from 'projects/portal-proveedores-front/src/app/modules/order-finder/data/order-finder-dataConfig';
import { StatusModalService } from 'projects/portal-proveedores-front/src/app/shared/components/modals/status-modal/status-modal.service';
import { Utils } from 'projects/portal-proveedores-front/src/app/shared/utils/utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActualizarConsulta } from '../../../models/api/datos-comunes/ActualizarConsulta';
import { InsertarConsulta } from '../../../models/api/datos-comunes/InsertarConsulta';
import { ResActualizarConsulta } from '../../../models/api/datos-comunes/ResActualizarConsulta';
import { ResConsultasAsegurado } from '../../../models/api/datos-comunes/ResConsultasAsegurado';
import { ResInsertarConsulta } from '../../../models/api/datos-comunes/ResInsertarConsulta';
import { ResListadoMisConsultas } from '../../../models/api/datos-comunes/ResListadoMisConsultas';
import { ResMisConsultas } from '../../../models/api/datos-comunes/ResMisConsultas';

import { BusquedaEncargos } from '../../../models/api/proveedores/BusquedaEncargos';
import { DatosContacto } from '../../../models/api/proveedores/DatosContacto';
import { DetallesSalida } from '../../../models/api/proveedores/DetallesSalida';
import { DevolverAvances } from '../../../models/api/proveedores/DevolverAvances';
import { DevolverAvancesPega } from '../../../models/api/proveedores/DevolverAvancesPega';
import { EncargoActualizarEntrada } from '../../../models/api/proveedores/EncargoActualizarEntrada';
import { EncargosSal } from '../../../models/api/proveedores/EncargosSal';
import { Incidencias } from '../../../models/api/proveedores/Incidencias';
import { JustificantesEntrega } from '../../../models/api/proveedores/JustificantesEntrega';
import { ListadoRegistroEntregaSalida } from '../../../models/api/proveedores/ListadoRegistroEntregaSalida';
import { ListadoRegistroSalida } from '../../../models/api/proveedores/ListadoRegistroSalida';
import { ListadoRegistrosEntrada } from '../../../models/api/proveedores/ListadoRegistrosEntrada';
import { ListaEncargosEntrada } from '../../../models/api/proveedores/ListaEncargosEntrada';
import { ListaReferencias } from '../../../models/api/proveedores/ListaReferencias';
import { DevolverNotificaciones } from '../../../models/api/proveedores/proveedores/DevolverNotificaciones';
import { UpdateCita } from '../../../models/api/proveedores/UpdateCita';
import { MisConsultasService } from '../../api/datos-comunes/misConsultas.service';
import { ProveedoresService } from '../../api/proveedores/proveedores.service';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class ManageRequestSosService {

  /**
   * Show the error in console and launch it.
   * @param err - The error
   * @returns An observable with the error
   */
  public handleBaseErrorFromServer(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    const errorMsg: string = Utils.error.displayServerErrorInConsole(err);
    this.insertarErrorTabla(errorMsg).subscribe();
    if (err.status !== Numbers.number504) {
      this.statusModalServ.displayErrorMessage(err.error.mensaje);
    }
    return throwError(err);
  }

  /**
   * Inster Error in errores web table
   * @param errorText error to insert
   */
  public insertarErrorTabla(errorText: string): Observable<any> {
    return this.provServ.logError(errorText);
  }

  /**
   * A injection of the different services
   * @param misConsultasServ - a MisConsultasService object
   * @param provServ - a ProveedoresService object
   * @param statusModalServ - a StatusModalService object
   */
  constructor( private misConsultasServ: MisConsultasService, private provServ: ProveedoresService, private statusModalServ: StatusModalService
  ) {
    this.misConsultasServ = misConsultasServ;
    this.provServ = provServ;
    this.statusModalServ = statusModalServ;
  }

  // <<<<----- PROVEEDORES ----->>>>>
  //#region *** PROVEEDORES ***
  // (P1) Consulta los encargos de un proveedor
  /**
   * Get the orders from a supplier
   * @param idProveedor - Supllier id
   * @param idAplicacion - Application id to consult
   * @param tipoEncargo - Order type
   * @param estado - The state to consult.
   * @param documento - consultor's DNI
   * @returns the supplier's orders
   */
  provConsultarEncargos$(idProveedor: string, idAplicacion: string, tipoEncargo: string, estado: string, documento: string, desde?: string, hasta?: string): Observable<EncargosSal | HttpErrorResponse> {

    return this.provServ.proveedoresConsultarEncargos(idProveedor, idAplicacion, tipoEncargo, estado, documento, desde, hasta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P2) Busqueda de los encargos por filtros establecidos
  /**
   * Make a Search adding different filters
   * @param requestData - the data
   * @returns The searched order
   */
  provBuscarEncargosPorFiltro$(requestData: RequestData): Observable<BusquedaEncargos | HttpErrorResponse> {
    const { identificador, tipoCertificado, tipoEncargo, estado, nombre, apellido1, apellido2, numeroPoliza, numeroTelefono, fechaDesde, fechaHasta, orderID, caseID }: RequestData = requestData;

    return this.provServ
      .proveedoresBuscarEncargos(identificador, tipoCertificado, tipoEncargo, estado, nombre, apellido1, apellido2, numeroPoliza, numeroTelefono, fechaDesde, fechaHasta, orderID, caseID)
      .pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P2b) Busqueda de los encargos por filtros establecidos desde detalle
  /**
   * Make a Search adding different filters
   * @param requestData - the data
   * @returns The searched order
   */
  provBuscarEncargosDesdeDetalle$(requestData: RequestData): Observable<BusquedaEncargos | HttpErrorResponse> {
    const { identificador, tipoCertificado, tipoEncargo, estado, nombre, apellido1, apellido2, numeroPoliza, numeroTelefono, fechaDesde, fechaHasta, orderID, caseID }: RequestData = requestData;

    return this.provServ
      .proveedoresBuscarEncargos(identificador, tipoCertificado, tipoEncargo, estado, nombre, apellido1, apellido2, numeroPoliza, numeroTelefono, fechaDesde, fechaHasta, orderID, caseID);
  }

  // (P3) Consulta el detalle de un encargo
  /**
   * Get the order details
   * @param identificador - order id
   * @param idProveedor - supplier id
   * @returns The order details
   */
  provConsultarDetalleEncargo$(identificador: string, idProveedor: string): Observable<DetallesSalida | HttpErrorResponse> {
    return this.provServ.proveedoresConsultarDetalle(identificador, idProveedor, 'PP').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  // (P4) Recupera un listado de registro de referencias en formato PDF
  /**
   * Get a list of reference records in PDF format.
   * @param idioma - ES,CA,EN or FR
   * @param numeroCopias - the number of copies
   * @param referencia - reference to get
   * @param idProveedor - the supplier.
   * @returns the reference records list
   */
  provRecuperarListadoRegistroPDF$(idioma: string, numeroCopias: number, referencia: string, idProveedor?: string): Observable<ListadoRegistroSalida | HttpErrorResponse> {
    return this.provServ.proveedoresRecuperarListadoRegistro(idioma, numeroCopias, referencia, idProveedor).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P5) Recupera un listado de entrega de referencias en formato PDF
  /**
   * Get a list of delivery of references in PDF format.
   * @param idioma - ES,CA,EN or FR
   * @param referencia - The reference
   * @param idProveedor - The supplier id
   * @returns The reference delivery list
   */
  provRecuperarListadoEntregaPDF$(idioma: string, referencia: string, idProveedor?: string): Observable<ListadoRegistroEntregaSalida | HttpErrorResponse> {
    return this.provServ.proveedoresRecuperarListadoRegistroentrega(idioma, referencia, idProveedor).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P6) Devuelve un justificante de entrega en PDF
  /**
   * Get one delivery file in pdf format
   * @param nombreArchivo - the file name
   * @returns The delivery file
   */
  provDevolverJustificanteEntregaPDF$(nombreArchivo: string): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresRecuperarJustificanteEntrega(nombreArchivo).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P7) Devuelve un listado de justificantes de entrega
  /**
   * Get a list of delivery files
   * @param idProveedor - supplier id
   * @param fechaDesde - the start date
   * @param fechaHasta - the end date
   * @returns Delivery files list
   */
  provDevolverListadoJustificantesEntrega$(idProveedor: string, fechaDesde: string, fechaHasta: string): Observable<Array<JustificantesEntrega> | HttpErrorResponse> {
    return this.provServ.proveedoresListadoJustificanteEntrega(idProveedor, fechaDesde, fechaHasta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P8) Creación de un PDF con el listado de registro de referencias
  /**
   * Generate a pdf document with the references register
   * @param idioma - ES,CA,EN or FR
   * @param numeroCopias - number of copies
   * @param listadoRegistros - Register lists to generate
   * @param idProveedor - the supplier id
   * @returns A ListadoRegistroSalida Object
   */
  provCrearPDFListadoRegistro$(idioma: string, numeroCopias: number, listadoRegistros: Array<ListadoRegistrosEntrada>, idProveedor?: string): Observable<ListadoRegistroSalida | HttpErrorResponse> {
    return this.provServ.proveedoresGenerarListadoRegistro(idioma, numeroCopias, listadoRegistros, idProveedor).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P9) Creación de un PDF con el listado de entrega de referencias
  /**
   * Generate a Pdf with the reference delivery list
   * @param idioma - ES,CA,EN or FR
   * @param listadoEntrega - the list of register
   * @param idProveedor - The supplier id
   * @returns A ListadoRegistroSalida object
   */
  provCrearPDFListadoEntrega$(idioma: string, listadoEntrega: Array<ListaEncargosEntrada>, idProveedor?: string): Observable<ListadoRegistroSalida | HttpErrorResponse> {
    return this.provServ.proveedoresGenerarListadoRegistroentrega(idioma, listadoEntrega, idProveedor).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P10) Creación de un justificante de entrega
  /**
   * Create a supporting document
   * @param identificador - supporting document id
   * @returns The Avances
   */
  provCrearJustificanteEntrega$(identificador: string): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresGuardarJustificanteEntrega(identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P11) Creación de una incidencia
  /**
   * Generate an incidence
   * @param incidencias - The incidence data
   * @returns The Avances
   */
  provCrearIncidencia$(incidencias: Incidencias): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresCrearIncidencia(incidencias).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P12) Actualiza un estado pasando al siguiente
  /**
   * Updates the state.
   * @param listaReferencias References List
   * @param idioma ES,CA,EN or FR
   * @param idProveedor Supplier Id
   * @param estado State
   * @returns The AvancesPega
   */
  provActualizarEstadoPasandoSiguiente$(listaReferencias: Array<ListaReferencias>, idioma: string, idProveedor?: string, estado?: string): Observable<DevolverAvancesPega | HttpErrorResponse> {
    return this.provServ.proveedoresAvanzarEstado(listaReferencias, idioma, idProveedor, estado).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P13) Actualización de un contacto
  /**
   * Updates a contact
   * @param identificador - The id
   * @param personaContacto - The contact data
   * @param tipoEncargo - The order type.
   * @returns The avances
   */
  provActualizarContacto$(identificador: string, personaContacto: DatosContacto, tipoEncargo?: string): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresActualizarContacto(identificador, personaContacto, 'PP', tipoEncargo).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P14) Api para actualizar con el asesoramiento
  /**
   * APi to update.
   * @param identificador - Id
   * @param datos - The data to update
   * @returns The avances
   */
  provActualizarAsesoramiento$(identificador: string, datos: UpdateCita): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresActualizarCitas(identificador, datos).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P15) Api para reabrir un encargo
  /**
   * Reopen an order
   * @param identificador Id of the case to update
   * @returns The avances
   */
  provReabrirEncargo$(identificador: string): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresReabrirCasos(identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P16) Actualiza el certificado de un encargo
  /**
   * Updates a certificate.
   * @param identificador - The certificate id
   * @param datosActualizar - the data to update
   * @returns The avances
   */
  provActualizarCertificadoEncargo$(identificador: string, datosActualizar: EncargoActualizarEntrada): Observable<DevolverAvances | HttpErrorResponse> {
    return this.provServ.proveedoresActualizarEncargo(identificador, datosActualizar, 'PP').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P17) Creación de un PDF con el listado de sobres de un listado de entrega
  /**
   * Generates a PDF with a envelopes list of a delivery list
   * @param identificador - id file
   * @param listaSobres List
   * @param idProveedor - The supplier id
   * @returns the PDF document
   */
  provImprimirSobresListadoEntrega$(identificador: string, listaSobres: Array<{ [idEncargo: string]: string }>, idProveedor?: any): Observable<any | HttpErrorResponse> {
    return this.provServ.proveedoresImprimirSobresListadoEntrega(identificador, listaSobres, idProveedor).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  } //#endregion

  // (P18) Recupera un excel de certificaciones GD y FDC
  /**
   * Generates an excel document of GD and FDC certifications
   * @param idProveedor - supplier id
   * @param tipoAplicacion - application type
   * @param tipoEncargo - deliver type (code)
   * @param estado - States to find
   * @param fechaInicio - Start Date
   * @param fechaFin -End Date
   * @param numMaxRegistro - Register max number
   * @returns The excel doc
   */
  provListadoExcel$(
    idProveedor: string,
    tipoAplicacion: string,
    tipoEncargo: string,
    estado: string,
    fechaInicio?: string,
    fechaFin?: string,
    numMaxRegistro?: string
  ): Observable<any | HttpErrorResponse> {
    return this.provServ
      .proveedoresListadoExcel(idProveedor, tipoAplicacion, tipoEncargo, estado, fechaInicio, fechaFin, numMaxRegistro)
      .pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // (P16) Devuelve notificaciones
  /**
   *
   * @param aplicacion The aplication
   * @param canal the canal
   * @returns The notifications
   */
  provNotificaciones$(aplicacion: string, canal: string): Observable<DevolverNotificaciones | HttpErrorResponse> {
    return this.provServ.proveedoresNotificaciones(aplicacion, canal).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }

  // <<<<----- COMUNES ----->>>>>
  //#region *** MIS CONSULTAS ***
  // (Q1) Insertar una consulta
  /**
   * Insert a consult
   * @param consulta - the consult to be inserted
   * @returns the consult inserted
   */
  misConsultasInsertarConsulta$(consulta: InsertarConsulta): Observable<ResInsertarConsulta | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasInsertarConsulta(consulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  // (Q2) Devuelve una consulta determinada
  /**
   * Get a specific consult
   * @param idConsulta - id consult
   * @returns the consult
   */
  misConsultasRecuperarConsulta$(idConsulta: string): Observable<ResMisConsultas | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarConsulta(idConsulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  // (Q3) Actualiza una consulta determinada
  /**
   * Update a specific consult
   * @param idConsulta - id consult
   * @param consulta - the consult to be updated
   * @returns - the updated consult
   */
  misConsultasActualizarConsulta$(idConsulta: string, consulta: ActualizarConsulta): Observable<ResActualizarConsulta | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasActualizarConsulta(idConsulta, consulta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  // (Q4) Devuelve un listado de consultas de un caso
  /**
   * Get all the consultations order
   * @param identificador - id order
   * @returns - the consults
   */
  misConsultasRecuperarListadoConsulta$(identificador: string): Observable<ResListadoMisConsultas | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarListadoConsulta(identificador).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  // (Q5) Devuelve una consulta por asegurado
  /**
   * Get a consultation
   * @param idAsegurado - aseg id
   * @returns the consultation
   */
  misConsultasRecuperarConsultaAsegurado$(idAsegurado: string): Observable<ResConsultasAsegurado | HttpErrorResponse> {
    return this.misConsultasServ.misConsultasRecuperarConsultaAsegurado(idAsegurado).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
  }
  //#endregion

}
