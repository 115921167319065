import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { DevolverAvances } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvances';
import { UpdateCita } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/UpdateCita';
import { ListaReferencias } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListaReferencias';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { ManageRequestServiceUtilsExtended } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils-extended';
import { ResMaestrosCita } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/ResMaestrosCita';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusModalService } from '../status-modal/status-modal.service';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-change-active-finished-modal',
  templateUrl: './change-active-finished-modal.component.html',
  styleUrls: ['./change-active-finished-modal.component.scss']
})
export class ChangeActiveFinishedModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: any;
  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  private subscriptions: Subscription[] = [];
  private orderId: string;
  public resultOptions: Array<any>;
  public appointmentResultForm = new FormGroup({
    option: new FormControl(null, Validators.required),
    closeReason: new FormControl('')
  });
  public invalidForm: boolean;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param manageReqSOSUtilsExtended - a ManageRequestServiceUtilsExtended object
   * @param authServ - a AuthService object
   * @param filterService - a FilterService object
   * @param statusModal - a StatusModalService object
   */
  constructor(
    private manageReqSOS: ManageRequestSosService,
    private manageReqSOSUtilsExtended: ManageRequestServiceUtilsExtended,
    private authServ: AuthService,
    private filterService: FilterService,
    readonly statusModal: StatusModalService
  ) {
    this.manageReqSOS = manageReqSOS;
    this.manageReqSOSUtilsExtended = manageReqSOSUtilsExtended;
    this.authServ = authServ;
    this.filterService = filterService;
    this.statusModal = statusModal;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;

    this.subscriptions.push(
      this.manageReqSOSUtilsExtended
        .maestrosMostrarResultadosCita$()

        .subscribe((res: Array<ResMaestrosCita>) => {
          this.resultOptions = res;
        })
    );
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * check if the form is valid and update the order data
   */
  handleClick(): void {
    if (this.appointmentResultForm.valid && (this.appointmentResultForm.get('option').value === 'Other' && !this.appointmentResultForm.get('closeReason').value)) {
      this.invalidForm = true;
    } else if (this.appointmentResultForm.valid && !(this.appointmentResultForm.get('option').value === 'Other' && !this.appointmentResultForm.get('closeReason').value)) {
      this.invalidForm = false;

      this.subscriptions.push(
        this.updateOrderData$()
          .pipe(
            finalize(() => {
              this.manageSelfDisplay.emit(false);
            })
          )
          .subscribe((res) => {
            let languageId = '2';
            if (this.authServ.getUserParam('lang') === 'ES') {
              languageId = '1';
            }

            const refId: Array<ListaReferencias> = [
              {
                identificador: this.orderId
              }
            ];
            const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

            this.manageReqSOS.provActualizarEstadoPasandoSiguiente$(refId, languageId, USER_ID).subscribe(() => {
              this.filterService.clearSavedSelectedRows();
              this.successOperation.emit({
                success: true,
                text: 'ORDER-TO-FINISHED',
                translate: true
              });
              this.updateTableData.emit();
            }, (error) => {
              if (error.status === Numbers.number504) {
                this.statusModal.displayErrorMessage('TIMEOUT-ERROR', true);
              }
              this.filterService.clearSavedSelectedRows();
              this.updateTableData.emit();
            });
          })
      );
    } else if (!this.appointmentResultForm.valid) {
      this.invalidForm = true;
    } else {
      return;
    }
  }

  /**
   * Update the order data
   * @returns the results
   */
  private updateOrderData$(): Observable<DevolverAvances | HttpErrorResponse> {
    const idOrder: string = this.orderId;

    const bodyToSend: UpdateCita = {
      resultadoCita: this.appointmentResultForm.get('option').value
      // observacionCancelacionCita: this.appointmentResultForm.get('closeReason').value ? this.appointmentResultForm.get('closeReason').value : ''
    };
    if (bodyToSend.resultadoCita === 'Other') {
      bodyToSend.comentariosCita = this.appointmentResultForm.get('closeReason').value;
    }

    return this.manageReqSOS.provActualizarAsesoramiento$(idOrder, bodyToSend);
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
