import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { Observable } from 'rxjs';

import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';

import { UserService } from './providers/local/user/user.service';
import { AuthService } from './providers/local/auth/auth.service';
import { ApiModule } from './providers/api/api.module';
import { BootService } from './providers/local/boot/boot.service';
import { AppConfig } from './providers/local/boot/models/boot.model';

import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { HeaderInterceptor } from './interceptors/manage-headers.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';

import { AuthGuard } from './guards/auth-guard/auth.guard';
import { NavigatorsCheckGuard } from './guards/navigators-check-guard/navigators-check.guard';

import { ErrorsHandler } from './errors/errors.handle';

import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { Angulartics2Module } from 'angulartics2';

import { version } from '../../../../../package.json';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';

/**
 * @description get a TranslateHttpLoader object
 * @param http - httpClient object
 */
export const HttpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http);
};

/**
 * @description Use the translate service
 * @param service - a TranslateService object
 * @returns -
 */
export const setupTranslateFactory: (service: TranslateService) => () => Observable<any> = (service: TranslateService): () => Observable<any> => {
  return () => service.use('es');
};

/**
 * @param keycloak - a KeycloakService object
 * @param authService - a AuthService object
 * @param bootService - a BootService object
 * @returns -
 */
export const initializeKeycloak: (keycloak: KeycloakService, authService: AuthService, bootService: BootService) => () =>
  Promise<any> = (keycloak: KeycloakService, authService: AuthService, bootService: BootService): () => Promise<any> => {
  return async () => {
    let appConfig: AppConfig;

    await bootService.loadAndStoreAppConfig().then((res: AppConfig) => (appConfig = res));

    // const message = 'Environment: ' + appConfig.environment + ', version: ' + version;
    // console.log(`%c ${message}`, 'background: lightblue; color: black; font-weight: 700');

    let toLoad: any = null;
    let toRedictUri: string = appConfig.loginUrl;
    if (window.location.href.indexOf('registration') === -1) {
      toLoad = 'login-required';
    }

    if ( Boolean(sessionStorage.getItem('login'))) {
      toRedictUri = window.location.href;
    }

    return new Promise<void>((resolve) => {
      keycloak
        .init({
          config: {
            url: appConfig.configKeycloak.url,
            realm: appConfig.configKeycloak.realm,
            clientId: appConfig.configKeycloak.clientId
          },
          initOptions: {
            pkceMethod: 'S256',
            onLoad: toLoad,
            checkLoginIframe: false, // Needed for iOS
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            redirectUri: toRedictUri// Fix problem on refresh app during navigation
          },
          bearerPrefix: 'Bearer',
          enableBearerInterceptor: true,
          bearerExcludedUrls: ['/isos/datos-comunes', '/identidad-digital']
        })

        // When user has loged in, get CREDENTIALS and store it
        .then(async () => {
          const userData: any = await keycloak.loadUserProfile();

          if (appConfig.environment.includes('DEV') || appConfig.environment.includes('TST')) {
            console.groupCollapsed('%c USER DATA ', 'color: white; background: darkgreen');
            console.table({ userData });
            console.groupEnd();
          }

          authService.setUserData({
            userName: userData.firstName,
            userSurname: userData.lastName,
            userMail: userData.email,
            userIdRequestISOS: userData.attributes.identificationNumber[0],
            userIdRequestIdentDig: userData.attributes.LDAP_ENTRY_DN[0].match(new RegExp('gslUserId=' + '(.*)' + ',ou=providers'))[1],
            userType: userData.attributes.userType[0],
            userCompany: userData.attributes.company[0],
            lang: 'ES'
          });

          localStorage.setItem('COMPANY-ID', authService.getUserParam('userCompany'));
          sessionStorage.setItem('login', 'true');

          resolve();
        })
        .catch((err) => {
          if (window.location.href.includes('registration')) {
            resolve();
          }
        });

      const keycloakAuth: Keycloak.KeycloakInstance = keycloak.getKeycloakInstance();

      keycloakAuth.onTokenExpired = () => {
        if (keycloakAuth.refreshToken) {
          const TWO_HOURS_SECONDS = 7200;
          keycloakAuth.updateToken(TWO_HOURS_SECONDS);
        } else {
          keycloakAuth.login();
        }
      };
    });
  };
};

/**
 * @description - init the bootService
 * @param boot - a BootService object
 * @returns the bootService as promise
 */
export const setupBootstrapFactory: (boot: BootService) => () => Promise<any> = (boot: BootService): () => Promise<any> => {
  return () => {
    const promise: Promise<void> = boot.init();
    return promise;
  };
};

const NGF_ROOT_OPTIONS: NgForageOptions = {
  name: 'next-storage',
  driver: [Driver.INDEXED_DB, Driver.WEB_SQL, Driver.LOCAL_STORAGE]
};

const SERVICES: any[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: setupTranslateFactory,
    deps: [TranslateService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: setupBootstrapFactory,
    deps: [BootService],
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService, AuthService, BootService]
  },
  BootService,
  UserService,
  AuthService,
  ErrorsHandler,
  KeycloakService,
  {
    provide: ErrorHandler,
    useClass: ErrorsHandler
  },
  {
    provide: DEFAULT_CONFIG,
    useValue: NGF_ROOT_OPTIONS
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KeycloakBearerInterceptor,
    multi: true
  }
];

const INTERCEPTORS: any[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorsInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HeaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true
  }
];

const GUARDS: any[] = [AuthGuard, NavigatorsCheckGuard];

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    Angulartics2Module.forRoot(),
    ApiModule
  ],
  providers: [...INTERCEPTORS, ...SERVICES, ...GUARDS]
})
export class CoreModule {

  /**
   * Constructor function
   * @param parentModule - a CoreModule object
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
