import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Numbers } from '../../../utils/constants';

@Injectable()
export class NavigatorsCheckGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * constructor function
   * @param deviceService - a DeviceDetectorService object
   * @param router - a Router object
   */
  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    this.deviceService = deviceService;
    this.router = router;
  }

  /**
   * decide if a route can be activated
   * @returns if a route can be activated
   */
  canActivate(): Promise<boolean> | boolean {
    return this.checkDevice();
  }

  /**
   * decide if a child route can be activated
   * @returns if a child route can be activated
   */
  canActivateChild(): Promise<boolean> | boolean {
    return this.checkDevice();
  }

  /**
   * decidef a route can be loaded
   * @returns if a route can be loaded
   */
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkDevice();
  }

  /**
   * If the browser is IE and its version is under 11 the guard blocks the route and its children route
   * @returns true/false
   */
  checkDevice(): boolean | Promise<boolean> {
    const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();
    let result = false;
    const browserIntE = 'IE';
    if (deviceInfo.browser === browserIntE && Number(deviceInfo.browser_version) < Numbers.number11) {
      this.router.navigate(['browser-no-compatible']);
      result = false;
    } else {
      result = true;
    }
    return result;
  }
}
