import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';

import { Table, TableCol, TableDataSource, TableOrder, TableStatus } from './models/table';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnChanges {
  //#region PROPS
  @Input() inputTableStatus: TableStatus;
  @Input() inputTableConfig: Table;
  @Input() iconsState: string;
  @Input() tableType = false;

  @Output() updatePaginator = new EventEmitter<any>();
  @Output() rowsSelectedEvent: EventEmitter<any | any[]> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any | any[]> = new EventEmitter<any>();
  //#endregion

  //#region STATE
  public filteredDataSource: TableDataSource[];
  public filtersSelected: any[] = [];
  //#endregion

  /**
   * constructor function
   * @param filterService - a FilterService object
   */
  constructor(private filterService: FilterService) {
    this.filterService = filterService;
  }

  /**
   * onChange method
   */
  ngOnChanges(): void {
    this.filtersSelected = this.filterService.getColsSelected();
    this.filterService.clearDataSource();
    this.filterService.storeOriginalDataSource(this.inputTableConfig.dataSource);

    this.setSortButtonInColumn();

    // Set table data from INPUT
    const defaultTableStatus: TableStatus = {
      rowsToDisplay: Numbers.number20,
      firstElement: 0
    };

    this.inputTableStatus = this.inputTableStatus || defaultTableStatus;
  }

  /**
   * Establish the order
   */
  private setSortButtonInColumn(): void {
    if (this.inputTableConfig && this.inputTableConfig.cols) {
      this.inputTableConfig.cols.forEach((col) => (col.order = TableOrder.UP));
    }
  }

  /**
   * emit an event
   * @param rowData - the data
   * @param option - the option to do: detail, info...
   */
  onManageRow(rowData: TableDataSource, option: string, isDniRegistro?: boolean): void {
    this.iconClick.emit({ rowData, option, isDniRegistro });
  }

  /**
   * show/ hide filter
   * @param col - a table column
   */
  onManageFilterVisibility(col: TableCol): void {
    col.showFilter = !col.showFilter;
  }

  /**
   * Manage the selected filter
   * @param event - the data
   */
  manageFilterSelected(event: TableDataSource[]): void {
    this.inputTableConfig.dataSource = event;

    // Set filtersSelected in order to give style on col title selected
    this.filtersSelected = this.filterService.getColsSelected();
  }

  /**
   * Emit a event when a order is checked
   */
  onRowChecked(): void {
    this.rowsSelectedEvent.emit(this.inputTableStatus.rowsSelected);
  }

  /**
   * Update the table
   * @param data - the new data
   */
  onUpdateTable(data: TableStatus): void {
    this.inputTableStatus = data;
  }

  /**
   * Invert the current order in the selected column: UP / DOWN
   * @param col - The selected column
   */
  sortCol(col: TableCol): void {
    this.inputTableConfig.cols.filter((column) => column.field !== col.field).forEach((column) => (column.order = TableOrder.UP));

    // Invert current col order
    if ( col.order === TableOrder.UP) {
      col.order = TableOrder.DOWN;
    } else {
      col.order = TableOrder.UP;
    }

    this.shortData(this.inputTableConfig.dataSource, col, col.colType === 'date');
  }

  /**
   * Order a data array
   * @param arr - data to order
   * @param col - the col to order
   * @param isDate - data are date type or not
   */
  private shortData(arr: Array<any>, col: TableCol, isDate: boolean): void {
    arr.sort((a, b) => {
      const aConverted: string | number = this.transformCellValue(a[col.field], isDate);
      const bConverted: string | number = this.transformCellValue(b[col.field], isDate);

      let x: number;
      if ((aConverted > bConverted && col.order === TableOrder.UP) || ( aConverted < bConverted && col.order !== TableOrder.UP)) {
        x = 1;
      } else if ((aConverted > bConverted && col.order !== TableOrder.UP) || ( aConverted < bConverted && col.order === TableOrder.UP)) {
        x = -1;
      } else {
        x = 0;
      }
      return x;
    });
  }

  /**
   * Transform the recived data in string or number format
   * @param value - Value to convert
   * @param isDate - data are date type or not
   * @returns The transformed data
   */
  private transformCellValue(value: any, isDate: boolean): string | number {
    let result: string | number;

    if (isDate) {
      result = this.convertDateToTime(value);
    } else if (!isNaN(value)) {
      result = Math.abs(Number(value));
    } else {
      result = value;
    }
    return result;
  }

  /**
   * Transform a date in time
   * @param inputDate - Date to transform
   * @returns The date to transform
   */
  private convertDateToTime(inputDate: string): number {
    const dateToTransform: Date = new Date(this.changeDateFormat(inputDate));
    const dateValue: number = dateToTransform.getTime();
    return dateValue;
  }

  /**
   * Change date format
   * @param date - date to transform
   * @returns Transformed date
   */
  private changeDateFormat(date: string): string {
    const arr: string[] = date.split('-');
    const month: string[] = arr.splice(1, 1);

    arr.unshift(...month);
    return arr.join('-');
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
