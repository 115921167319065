import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { FooterComponent } from './components/footer/footer.component';
import { LayoutComponent } from './layout.component';
import { Error404Component } from './components/error404/error404.component';
import { NavigatorIncompatibleComponent } from './components/navigator-incompatible/navigator-incompatible.component';
import { NavigationMenuComponent } from './components/header/navigation-menu/navigation-menu.component';
import { LogoutModalComponent } from './components/header/navigation-menu/logout-modal/logout-modal.component';

@NgModule({
  // Declarate all the needed components
  // Import modules which will be used in this module
  // Export the header component to use in other modules
  declarations: [
    LayoutComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    Error404Component,
    NavigatorIncompatibleComponent,
    NavigationMenuComponent,
    HeaderComponent,
    LogoutModalComponent
  ],
  imports: [SharedModule],
  exports: [HeaderComponent]
})
export class LayoutModule {
}
