import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../providers/local/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderInterceptor implements HttpInterceptor {

  /**
   * Constructor function
   * @param authService - a AuthService object
   */
  constructor(private authService: AuthService) {
    this.authService = authService;
  }

  /**
   * Manage the headers and decide if add one.
   * @param req - the httpClient request
   * @param next - a HttpHandler object
   * @returns - Observable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let request: HttpRequest<any> = req;
    let returnRequest = false;

    if (req.url.includes('/datos-comunes') && !window.location.href.includes('registration')) {
      let xUser = '';
      if ( !this.authService.getUserParam('userSurname').includes('empresa')) {
        xUser = this.authService.getUserParam('userSurname');
      }
      const xUserHeader = this.authService.getUserParam('userName') + ' ' + xUser;
      request = req.clone({
        setHeaders: {
          'x-user': xUserHeader
        }
      });

      returnRequest = true;
    }
    let toReturn: Observable<any> = next.handle(req);
    if (returnRequest) {
      toReturn = next.handle(request);
    }
    return toReturn;
  }
}
