import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: [ './timer.component.scss' ]
})
export class TimerComponent implements OnInit {
  @Output() btnClick: EventEmitter<any> = new EventEmitter<any>();

  public time: Date = new Date();

  /**
   * OnInit method
   */
  ngOnInit(): void {
    setInterval(() => {
      this.time = new Date();
    }, Numbers.number30000);
  }
}
