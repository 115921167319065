import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: [ './notification-menu.component.scss' ]
})
export class NotificationMenuComponent {
  @Input() notificationList: Array<any>;
  displayMenu = false;

  /**
   * Show/Hide the notification menu
   */
  onToggleMenu(): void {
    this.displayMenu = !this.displayMenu;
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
