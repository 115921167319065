import { IdentidadDigitalConsulta } from '../../../core/models/api/identidad-digital/IdentidadDigitalConsulta';

/**
 * @description Simplify the user data
 * @param userData - digital identity
 * @returns the new object
 */
export const simplifyUserDataSL: (userData: IdentidadDigitalConsulta) => IdentidadDigitalConsulta =
(userData: IdentidadDigitalConsulta): IdentidadDigitalConsulta => {
  const newObject: IdentidadDigitalConsulta = {};
  for (const elem in userData) {
    if (elem === 'atributos') {
      processAttributes(userData[elem], newObject);
    } else {
      Object.assign(newObject, { [elem]: userData[elem] });
    }
  }
  return newObject;
};

/**
 * process the recived attributes
 * @param attributes - the attributes to process
 * @param targetObject - the target
 */
function processAttributes(attributes: any, targetObject: any): void {
  Object.entries(attributes).forEach((attribute) => {
    Object.assign(targetObject, { [attribute[0]]: attribute[1] });
  });
}

