export const environment = {
  production: false,
  loginUrl: 'https://portal-proveedores-front-isosportal-front-dev.app-dev.santalucia.net/',
  test: false,
  configKeycloak: {
    url: 'https://sso-sldev.santalucia.es/auth',
    realm: 'stl-isos-pvd',
    clientId: '06638605-c31c-4ac0-ab13-4c8fe95c1ba9'
  }
};
