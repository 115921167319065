import { Component, EventEmitter, Input, Output, HostListener } from '@angular/core';

@Component({
  selector: 'app-backdrop-ribbon-modal',
  templateUrl: './backdrop-ribbon-modal.component.html',
  styleUrls: ['./backdrop-ribbon-modal.component.scss']
})
export class BackdropRibbonModalComponent {
  @Input()
  literals: {
    HEADER?: string;
    BUTTON1?: string;
    BUTTON2?: string;
  } = {};
  @Input() display: boolean;

  @Output() hideModal: EventEmitter<any> = new EventEmitter();
  @Output() button1Clicked: EventEmitter<any> = new EventEmitter();
  @Output() button2Clicked: EventEmitter<any> = new EventEmitter();

  public draggable = false;
  public mask = true;

  /**
   * Emit an event to hide the modal.
   */
  hide(): void {
    this.hideModal.emit();
  }

  /**
   * Emit an event when button 1 is clicked
   */
  button1(): void {
    this.button1Clicked.emit();
  }

  /**
   * Emit an event when button 2 is clicked
   */
  button2(): void {
    this.button2Clicked.emit();
  }
}
