import { Component } from '@angular/core';

@Component({
  selector: 'app-elem-counter',
  templateUrl: './elem-counter.component.html',
  styleUrls: [ './elem-counter.component.scss' ]
})
// Elem Counter Component
export class ElemCounterComponent {
}
