import { Injectable } from '@angular/core';

import { TableDataSource } from 'projects/portal-proveedores-front/src/app/shared/components/no-category/table/models/table';

interface FilterSelected {
  [key: string]: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  //#region STATE
  private originalDataSource: TableDataSource[];
  private savedSelectedRows: TableDataSource[];
  private filterSelected: FilterSelected = {};
  private showInfoModal = true;
  //#endregion

  /**
   * Store the original data in filterService STATE
   * @param originalData Base table data no filtered
   */
  storeOriginalDataSource(originalData: TableDataSource[]): void {
    if (this.originalDataSource === undefined || this.originalDataSource === null) {
      this.originalDataSource = originalData;
    }
  }

  /**
   * Store filter selected (array) grouped by colSelected
   * @param colSelected Current COL active
   * @param dataSelected Data selected from COL
   */
  addFilter(colSelected: string, dataSelected: string[]): void {
    this.filterSelected[colSelected] = dataSelected;
  }

  /**
   * Add a single data element into the current array of colSelected
   * @param colSelected Current COL active
   * @param individualDataSelected Data element to add to the current data
   */
  addIndividualFilter(colSelected: string, individualDataSelected: string): void {
    if (this.filterSelected[colSelected]) {
      this.filterSelected[colSelected].push(individualDataSelected);
    }
  }

  /**
   * Erase a single data element from the current array of colSelected
   * @param colSelected Current COL active
   * @param individualDataSelected Data element to remove from the current data
   */
  removeIndividualFilter(colSelected: string, individualDataSelected: string): void {
    if (this.filterSelected[colSelected]) {
      this.filterSelected[colSelected] = this.filterSelected[colSelected].filter((elem) => elem !== individualDataSelected);
    }
  }

  /**
   * Delete register of colSelected from data stored in service
   * @param colToDelete Current COL active
   */
  deleteCol(colToDelete: string): void {
    delete this.filterSelected[colToDelete];
  }

  /**
   * Get current colSelected stored in service
   */
  getColsSelected(): string[] {
    return Object.keys(this.filterSelected);
  }

  /**
   * Get current showInfoModal stored in service
   */
  getShowInfoModal(): boolean {
    return this.showInfoModal;
  }

  /**
   * set showInfoModal
   */
  setShowInfoModal(value: boolean): void {
    this.showInfoModal = value;
  }

  /**
   * Returns an array of data elements selected from col
   * @param colSelected Selected COL
   */
  getDataSelectedFromCol(colSelected: string): string[] {
    let dataToReturn: string[] = [];
    if (this.filterSelected[colSelected]) {
      dataToReturn = this.filterSelected[colSelected];
    }
    return dataToReturn;
  }

  /**
   * Returns data filtered by active filter stored in service
   * @param dataToFilter TableData to filter
   */
  getDataFiltered(dataToFilter?: TableDataSource[]): TableDataSource[] {
    let dataFiltered: TableDataSource[] = this.originalDataSource;
    if (dataToFilter) {
      dataFiltered = dataToFilter;
    }

    if (this.getColsSelected().length) {
      this.getColsSelected().forEach((colSelected: string) => {
        dataFiltered = this.filterDataFromCol(colSelected, this.getDataSelectedFromCol(colSelected), dataFiltered);
      });
    } else {
      dataFiltered = this.originalDataSource;
    }

    return dataFiltered;
  }

  /**
   * Apply filters in a specific column
   * @param colSelected - the selected column
   * @param selectedFilters - the filters to apply
   * @param dataSource . the data
   * @returns . the filtered data
   */
  private filterDataFromCol(colSelected: string, selectedFilters: string[], dataSource: TableDataSource[]): TableDataSource[] {
    return dataSource.filter((dataRow: any) => {
      return selectedFilters.indexOf(dataRow[colSelected]) >= 0;
    });
  }

  /**
   * Clear DataSource and FilterSelected
   */
  clearData(): void {
    this.clearDataSource();
    this.clearFilterSelected();
  }

  /**
   * Clear DataSource
   */
  clearDataSource(): void {
    this.originalDataSource = null;
  }

  /**
   * Clear Saved Selected Rows
   */
  clearSavedSelectedRows(): void {
    this.savedSelectedRows = null;
  }

  /**
   * Save rows of data
   * @param rows the rows to save
   */
  saveSelectedRows(rows: TableDataSource[]): void {
    this.savedSelectedRows = rows;
  }

  /**
   * get the saved rows
   * @returns the saved rows
   */
  getSavedSelectedRows(): TableDataSource[] {
    return this.savedSelectedRows;
  }

  /**
   * Clear FilterSelected
   */
  clearFilterSelected(): void {
    this.filterSelected = {};
  }

  /**
   * From new DataSource, returns data filtered with current filter active
   * @param inputTableData Data from origin
   */
  setInitialDataWithCurrentFilter(inputTableData: TableDataSource[]): TableDataSource[] {
    this.clearDataSource();

    this.storeOriginalDataSource(inputTableData);

    return this.getDataFiltered(inputTableData);
  }
}
