import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusModalService } from '../../modals/status-modal/status-modal.service';
import { PersonalUserData, UserData, USER_DATA_DOCUMENT_TYPE_OPTIONS, USER_DATA_MAP_USER_DATA } from 'projects/portal-proveedores-front/src/app/core/models/local/userdata';
import { IdentidadDigitalConsulta } from 'projects/portal-proveedores-front/src/app/core/models/api/identidad-digital/IdentidadDigitalConsulta';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { IdentidadDigitalParcheo } from 'projects/portal-proveedores-front/src/app/core/models/api/identidad-digital/IdentidadDigitalParcheo';
import { IdentidadDigitalModificacion } from 'projects/portal-proveedores-front/src/app/core/models/api/identidad-digital/IdentidadDigitalModificacion';
import { Utils } from '../../../utils/utils';
import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  //#region PROPS
  @ViewChild('userMenuFocus', { static: true }) userMenuFocus: ElementRef;
  @Input() fullscreen = false;
  //#endregion

  //#region STATE
  private mapUserData: Record<string, string> = USER_DATA_MAP_USER_DATA;
  public personalDataList: { label: string; value: string }[] = USER_DATA_DOCUMENT_TYPE_OPTIONS;
  public documentOptions = ['NIF', 'DNI', 'CIF'];

  public userDisplayData: UserData;
  private userServerData: IdentidadDigitalConsulta;

  public displayMenu: boolean;
  public displayData = false;
  public displayPasw = false;

  public personalDataForm: FormGroup = new FormGroup({
    user: new FormControl({ value: '', disabled: true }, Validators.required),
    name: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    surname1: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    surname2: new FormControl('', [Validators.required, Validators.maxLength(Numbers.number50)]),
    email: new FormControl('', [Validators.required, Validators.pattern(Utils.variables.EMAIL_REGEX)]),
    docType: new FormControl('', Validators.required),
    id: new FormControl('')
  });

  // * Manage visualization of surname1 and surname2
  public documentType = 'NIF';

  public errorMenu = '';
  public errorpasw = '';

  /**
   * Constructor function
   * @param translate - a TranslateService object
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   * @param authServ - a AuthService object
   * @param statusModal - a StatusModalService object
   */
  constructor(
    private translate: TranslateService,
    private manageReqSOSUtils: ManageRequestServiceUtils,
    private authServ: AuthService,
    private statusModal: StatusModalService,
    private renderer: Renderer2
  ) {
    this.translate = translate;
    this.manageReqSOSUtils = manageReqSOSUtils;
    this.authServ = authServ;
    this.statusModal = statusModal;
    this.renderer = renderer;
  }

  /**
   * OnInit method.
   */
  ngOnInit(): void {
    this.checkLanguaje();
    this.translateDropdown();

    this.setInitalUserData();
  }

  /**
   * Set the initial data.
   */
  public setInitalUserData(): void {
    let valorApellido = '';
    if (!this.authServ.getUserParam('userSurname').includes('empresa')) {
      valorApellido = this.authServ.getUserParam('userSurname')[0];
    }
    const initialsName = (this.authServ.getUserParam('userName')[0] + ' ' + valorApellido) || '';
    this.userDisplayData = {
      name: this.authServ.getUserParam('userName') || 'No identificado',
      profile: this.authServ.getUserParam('userType') || '-',
      initials: initialsName,
      color: 'blue'
    };
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.statusModal.turnOffStatusDisplay();
  }

  /**
   * Get the digital identity and store it
   * @param identidad - identity to consult
   */
  public getAndStoreUserDataFromSL(identidad: string): void {
    this.manageReqSOSUtils.identDigSLConsultarIdentidadDigital$(identidad, 'P').subscribe(
      (userRes: IdentidadDigitalConsulta) => {
        // this.changeIdNumberValidatorAndCheck(userRes.atributos['isos.tipoDocumentoIdentidad']);

        this.documentType = userRes.atributos['isos.tipoDocumentoIdentidad'];

        let valorApellido = '';
        if (!userRes.primerApellido.includes('empresa')) {
          valorApellido = userRes.primerApellido[0];
        }
        const initialsName = (userRes.nombre[0] + ' ' + valorApellido) || '';
        this.userDisplayData = {
          name: userRes.nombre || 'No identificado',
          profile: userRes.atributos['isos.tipoUsuario'] || '-',
          initials: initialsName,
          color: 'blue'
        };

        this.userServerData = userRes;

        this.fillFormUserData(userRes);
      },
      (err: HttpErrorResponse) => {
        this.statusModal.displayErrorMessage('NO-USER-DATA', true);
        this.userDisplayData = {
          name: 'Username',
          profile: 'No identificado',
          initials: `U`,
          color: 'blue'
        };
      }
    );
  }

  /**
   *
   * @param userRes - the identity
   */
  private fillFormUserData(userRes: IdentidadDigitalConsulta): void {
    const transformedData: any = Utils.manageServerData.simplifyUserDataSL(userRes);

    for (const data in transformedData) {
      if (transformedData.hasOwnProperty(data) && this.personalDataForm.get(this.mapUserData[data])) {
        const field: AbstractControl = this.personalDataForm.get(this.mapUserData[data]);
        field.setValue(transformedData[data]);
      }
    }

    this.personalDataForm.get('user').setValue(transformedData['isos.usuario']);
  }

  /**
   * Display a menu and get the user data
   */
  public displayMenuAndGetUserData(): void {
    this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
  }

  /**
   * Reset the user password
   */
  public onChangePassLDAP(): void {
    const identidad: string = this.authServ.getUserParam('userIdRequestIdentDig');

    this.manageReqSOSUtils.credentResetearPassSL$(identidad, 'P').subscribe(
      (res: any) => {
        this.statusModal.displaySuccessMessage('RESET-PASS-REQUEST', true);
      },
      (err: HttpErrorResponse) => {
        this.statusModal.displayErrorMessage();
      }
    );
  }

  /**
   * Focus on an element or display/show the menu
   * @param event - A event clicked
   * @param focusId - the element to focus on
   */
  public onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.displayMenu = !this.displayMenu;
    }
  }

  /**
   * Toggle the personal data
   */
  public togglePersonalData(): void {
    if (this.displayData === false) {
      this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
    }
    if (this.displayPasw) {
      this.displayPasw = false;
    } else {
      this.displayPasw = this.displayPasw;
    }
    if (this.fullscreen) {
      this.displayData = !this.displayData;
    } else {
      this.displayData = !this.displayData;
    }
  }

  /**
   * Change document type to manage visualization of user info
   * @param event - The event
   */
  public onChangeDrop(event: { originalEvent: any; value: string }): void {
    this.documentType = event.value;

    // this.changeIdNumberValidatorAndCheck(event.value);

    Utils.screenReader.speak(`El tipo de documento seleccionado es ${event.value}`, this.renderer);
  }

  // private changeIdNumberValidatorAndCheck(documentType: string): void {
  //   this.documentType = documentType;
  //   const idNumberInput = this.personalDataForm.get('id');

  //   idNumberInput.enable();
  //   idNumberInput.setValidators([Validators.pattern(getValidatorFromType(documentType)), Validators.required]);

  //   idNumberInput.updateValueAndValidity();
  //   idNumberInput.markAsTouched();

  //   this.screenReader.speak(`El tipo de documento seleccionado es ${documentType}`);
  // }

  /**
   * display/Hide the Pasw
   */
  public togglePasw(): void {
    if (this.displayData) {
      this.displayData = false;
    } else {
      this.displayData = this.displayData;
    }
    if (this.fullscreen) {
      this.displayPasw = !this.displayPasw;
    } else {
      this.displayPasw = !this.displayPasw;
    }
  }

  /**
   * Focus in a element
   * @param elem - the element to focus
   */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }

  /**
   * Check the language
   */
  private checkLanguaje(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translateDropdown();
    });
  }

  /**
   * Translate the entries
   */
  private translateDropdown(): void {
    for (const [i, item] of this.documentOptions.entries()) {
      this.personalDataList[i].label = this.translate.instant(item);
    }
  }

  /**
   * Called when user data is updated
   */
  public onUpdateUserData(): void {
    if (this.personalDataForm.valid) {
      this.errorMenu = '';

      this.updateUserOnLDAP$(this.personalDataForm.value, this.userServerData as IdentidadDigitalModificacion);
    } else {
      Object.keys(this.personalDataForm.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.personalDataForm.get(key).errors;
        if (controlErrors !== null) {
          Object.keys(controlErrors).forEach((keyError) => {
            if (keyError === 'required') {
              this.errorMenu = 'HEADER.PROFILE.ERROR-REQUIRED';
            } else if (keyError === 'pattern') {
              this.errorMenu = 'HEADER.PROFILE.ERROR-PATTERN';
            }
          });
        }
      });
    }
  }

  /**
   * Update the user data
   * @param userForm - the personal user data
   * @param userServerData - the identity
   */
  private updateUserOnLDAP$(userForm: PersonalUserData, userServerData: IdentidadDigitalModificacion): void {
    // console.log('%c Actualizar usuario en LDAP', 'color: #07B6FF');

    const identidad: string = this.authServ.getUserParam('userIdRequestIdentDig');
    const datosParcheoIdentidadDigital: IdentidadDigitalParcheo = {
      tipoIdentidad: 'P', // Este parámetro es siempre 'A' para el portal proveedores
      nombre: userForm.name,
      primerApellido: userForm.surname1,
      segundoApellido: userForm.surname2,
      correo: userForm.email,
      atributos: {
        'isos.tipoDocumentoIdentidad': userServerData.atributos['isos.tipoDocumentoIdentidad'],
        'isos.empresa': userServerData.atributos['isos.empresa'],
        // 'isos.usuario': userServerData.atributos['isos.usuario'],
        'isos.usuario': userForm.id,
        'isos.tipoUsuario': userServerData.atributos['isos.tipoUsuario'],
        'isos.idUsuario': userServerData.atributos['isos.idUsuario'],
        'isos.cuentaActiva': userServerData.atributos['isos.cuentaActiva'],
        'isos.cambioPassword': userServerData.atributos['isos.cambioPassword']
      }
    };

    this.manageReqSOSUtils.identDigSLModificarAtributosParticularesIdentidadDigital$(identidad, datosParcheoIdentidadDigital).subscribe(
      (res: any) => {
        this.statusModal.displaySuccessMessage('USER-DATA-UPDATED', true);
        this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
      },
      (err: HttpErrorResponse) => {
        this.statusModal.displayErrorMessage('USER-DATA-UPDATE-FAIL', true);
        this.getAndStoreUserDataFromSL(this.authServ.getUserParam('userIdRequestIdentDig'));
      }
    );
  }
}
