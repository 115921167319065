import { AfterViewChecked, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appInputAria]'
})
export class InputAriaDirective implements AfterViewChecked, OnInit {
  @Input() inputAriaLabel: string;

  public currentElement: any;

  /**
   * constructor function
   * @param el - a ElementRef object
   * @param translate - a TranslateService object
   */
  constructor(private el: ElementRef, private translate: TranslateService) {
    this.el = el;
    this.translate = translate;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.currentElement = this.el.nativeElement;
  }

  /**
   * AfterViewChecked method
   */
  ngAfterViewChecked(): void {
    const inputId: any = [...this.currentElement.querySelectorAll('input')][0];
    let ariaLabel: string;

    this.translate.get(this.inputAriaLabel || 'NO-VALUE').subscribe((res) => (ariaLabel = res));
    inputId.setAttribute('aria-label', ariaLabel);
  }
}
