// Component imported from angular core
import { Component } from '@angular/core';

@Component({
  selector: 'app-data-filter',
  templateUrl: './data-filter.component.html',
  styleUrls: [ './data-filter.component.scss' ]
})

// DataFilter Component
export class DataFilterComponent {
}
