import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { Table, TableDataSource, TableStatus } from '../../no-category/table/models/table';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

interface CardDisplay {
  header?: boolean;
  footer?: boolean;
}

@Component({
  selector: 'app-card-data',
  templateUrl: './card-data.component.html',
  styleUrls: ['./card-data.component.scss']
})
export class CardDataComponent implements OnInit, OnChanges {
  @Input() inputTableStatus: TableStatus;
  @Input() tableConfig: Table;
  @Input() cardDisplay: CardDisplay;
  @Input() iconsState: string;
  @Input() detail: boolean;

  @Output() rowsSelectedEvent: EventEmitter<any | any[]> = new EventEmitter<any>();
  @Output() iconClick: EventEmitter<any | any[]> = new EventEmitter<any>();

  public rowsSelected: any[] = [];

  /**
   * constructor function
   * @param filterService - a FilterService object
   */
  constructor(private filterService: FilterService) {
    this.filterService = filterService;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    const defaultTableStatus: TableStatus = {
      rowsToDisplay: Numbers.number20,
      firstElement: 0
    };

    this.inputTableStatus = this.inputTableStatus || defaultTableStatus;
  }

  /**
   * OnChanges method
   */
  ngOnChanges(): void {
    this.filterService.storeOriginalDataSource(this.tableConfig.dataSource);
  }

  /**
   * Emit and event with the order selected
   */
  onRowSelect(): void {
    this.rowsSelectedEvent.emit(this.inputTableStatus.rowsSelected);
  }

  /**
   * Emit an event to manage a rowData
   * @param rowData - the data to manage
   * @param option - option to do: show, download, delete...
   */
  onManageRow(rowData: TableDataSource, option: string, isDniRegistro?: boolean): void {
    this.iconClick.emit({ rowData, option, isDniRegistro });
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
