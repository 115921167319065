import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DevolverAvances } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvances';
import { HttpErrorResponse } from '@angular/common/http';
import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';

@Component({
  selector: 'app-attach-file-modal',
  templateUrl: './attach-file-modal.component.html',
  styleUrls: ['./attach-file-modal.component.scss']
})
export class AttachFileModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  // Manage subscriptions
  private subscriptions: Subscription[] = [];

  private orderId: string;
  public fileStored: File;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   */
  constructor(private manageReqSOSUtils: ManageRequestServiceUtils) {
    this.manageReqSOSUtils = manageReqSOSUtils;
  }

  /**
   * OnInit method
   */
  ngOnInit() {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Store a file when upload it
   * @param event the file
   */
  public storeFile(event: File): void {
    this.fileStored = event;
  }

  /**
   * Emit an event that indicates the files was been attached and update the data view
   */
  public handleClick(): void {
    this.subscriptions.push(
      this.uploadService$()
        .pipe(
          finalize(() => {
            this.manageSelfDisplay.emit(false);
          })
        )
        .subscribe((res: any) => {
          this.successOperation.emit({ success: true, text: 'FILE-ATTACHED', translate: true });
          this.updateTableData.emit();
        })
    );
  }

  /**
   * Calls the service function to upload the attached file
   * @returns the results
   */
  private uploadService$(): Observable<DevolverAvances | HttpErrorResponse> {
    const identificador: any = this.orderId;
    const uploadFile: File = this.fileStored;
    const isProcesal = (this.selectedDetail && (this.selectedDetail.datosCaso.tipoFDC || '').toLowerCase() === 'procesal') || false;

    let procesal: string = null;
    if (isProcesal) {
      procesal = 'FDC-Procesal';
    }

    return this.manageReqSOSUtils.provAsegAdjuntarFichero$(identificador, uploadFile, procesal);
  }
}
