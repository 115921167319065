import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reversePipe'
})
export class ReversePipe implements PipeTransform {

  /**
   * Pipe method
   * @param value - the value to convert
   * @returns the converted value
   */
  transform<T>(value: T[]): T[] {
    return value.slice().reverse();
  }
}
