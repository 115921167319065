import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appAcceptsRegexCharacters]'
})
export class AcceptsRegexCharactersDirective {
  @Input() appAcceptsRegexCharacters = '^[a-zA-ZÀ-ÿ0-9_ ]*$';

  /**
   * Constructor function
   * @param el a ElementRef object
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }

  /**
   * Verify if the character matches the regular expression
   * @param event - the character
   * @returns if the character matches the regExp
   */
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): boolean {
    return new RegExp(this.appAcceptsRegexCharacters).test(event.key);
  }

  /**
   * Validate the fields
   * @param event - a paste event from the clipboard
   */
  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent): void {
    this.validateFields(event);
  }

  /**
   * Replace any character that is not a letter, a number, a whitespace, or an underscore with an empty string.
   * @param event - Clipboard event
   */
  validateFields(event: ClipboardEvent): void {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^a-zA-ZÀ-ÿ0-9_ ]/g, '');
      event.preventDefault();
    }, Numbers.number100);
  }
}
