import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ]
})
export class ButtonComponent {
  @Input() text: string;
  @Input() btnStyle: string;
  @Input() disabled?: boolean;
  @Input() shadow?: boolean;
  @Input() lightText?: boolean;
  @Output() clickEvent = new EventEmitter<any>();

  /**
   * add a class based on the params
   * @param btnStyle - styleButton: primary, secondary, info...
   * @param shadow  - button show shadow or not
   * @param disabled - button is disabled or not
   * @param lightText - button with light text or not
   * @returns the added classes
   */
  addBtnClass(btnStyle: string, shadow?: boolean, disabled?: boolean, lightText?: boolean): Array<string> {
    const classToAdd: Array<string> = [];

    if (shadow) {
      classToAdd.push('btn--shadow');
    }

    if (disabled) {
      classToAdd.push('btn--disabled');
    }

    if (lightText) {
      classToAdd.push('btn--light-text');
    }

    switch (btnStyle) {
      case 'secondary':
        classToAdd.push('btn--secondary');
        break;
      case 'info':
        classToAdd.push('btn--info');
        break;
      case 'warn':
        classToAdd.push('btn--warn');
        break;
      case 'clear':
        classToAdd.push('btn--clear');
        break;
      default:
        classToAdd.push('btn--primary');
        break;
    }

    return classToAdd;
  }

  /**
   * Emit an event when click
   */
  onClick(): void {
    this.clickEvent.emit();
  }
}
