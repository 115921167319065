import { Pipe, PipeTransform } from '@angular/core';
import { TableCol } from '../components/no-category/table/models/table';
import { Numbers } from '../../utils/constants';

/**
 * Pipe SORT-BY.
 * Handle @param value TableCol data
 * depending on @param display and sort it
 * to display with the selected position
 */
@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  /**
   * Pipe method
   * @param value - the value to convert
   * @param display - Where display the data: table, card...
   * @returns the converted value
   */
  transform(value: TableCol[], display: string): TableCol[] {
    const arr: TableCol[] = [...value];
    arr.map((item) => {
      if (item.columnOrderInCard) {
        item.columnOrderInCard = item.columnOrderInCard;
      } else {
        item.columnOrderInCard = Numbers.number100;
      }
    });

    arr.map((item) => {
      if (item.columnOrderInTable) {
        item.columnOrderInTable = item.columnOrderInTable;
      } else {
        item.columnOrderInTable = Numbers.number100;
      }
    });

    const compareOnCard = (a: TableCol, b: TableCol): number => {
      const itemA: number = a.columnOrderInCard;
      const itemB: number = b.columnOrderInCard;

      let comparison = 0;
      if (itemA > itemB) {
        comparison = 1;
      } else if (itemA < itemB) {
        comparison = -1;
      }

      return comparison;
    };

    const compareOnTable = (a: TableCol, b: TableCol): number => {
      const itemA: number = a.columnOrderInTable;
      const itemB: number = b.columnOrderInTable;

      let comparison = 0;
      if (itemA > itemB) {
        comparison = 1;
      } else if (itemA < itemB) {
        comparison = -1;
      }

      return comparison;
    };

    let tableCols: TableCol [];
    if (display === 'table') {
      tableCols =  [...arr.sort(compareOnTable)];
    } else if (display === 'card') {
      tableCols = [...arr.sort(compareOnCard)];
    } else {
      tableCols = [...arr];
    }
    return tableCols;
  }
}
