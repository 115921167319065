import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  /**
   * Constructor function
   * @param el - a ElementRef object
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }

  /**
   * AfterViewInit method. Focus on the element
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, Numbers.number300);
  }
}
