import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';

import { UserData } from '../models/userdata';
import { RibbonModal } from '../../../../certifications/certifications.component';
import { FilterService } from '../../../../../core/providers/local/filter/filter.service';

export interface MenuListData {
  icon: string;
  id: string;
  url?: string;
  hideMobile?: boolean;
  hideDesktop?: boolean;
}

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {
  @ViewChild('navigationFocus', { static: false }) navigationFocus: ElementRef;

  @Input() userData: UserData;
  @Input() menuListData: MenuListData[];
  @Input() menuStatus: boolean;
  @Output() changeStatus = new EventEmitter();

  public ribbonModal: RibbonModal = { display: false, type: null };
  public modalFunction: any;
  public currentURL: string;

  /**
   * Constructor function
   * @param router - a Router object
   * @param authService - a AuthService object
   * @param filter$- a FilterService object
   */
  constructor(private router: Router, private authService: AuthService, private filter$: FilterService) {
    this.router = router;
    this.authService = authService;
    this.filter$ = filter$;
  }

  /**
   * OnInit method.
   */
  ngOnInit(): void {
    this.handleCurrentUrlState();
  }

  /**
   * Update the property currentURl with the current URL
   */
  private handleCurrentUrlState(): void {
    this.currentURL = window.location.href;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = window.location.href;
      }
    });
  }

  /**
   * Navigate the indicated url
   * @param url - the url
   */
  onNavigate(url: string): void {
    this.changeStatus.emit();
    this.router.navigate([url]);

    if (sessionStorage.getItem('FORM_RESULT')) {
      sessionStorage.removeItem('FORM_RESULT');
    } else {
      return;
    }
  }

  /**
   * Hide menu
   */
  onHideMenu(): void {
    this.changeStatus.emit();
  }

  /**
   * open modal logout
   */
  onOpenPreventLogoutModal(): void {
    this.ribbonModal.display = true;
    this.ribbonModal.type = 'MODAL.LOGOUT';
  }

  /**
   * Logout and clear filters
   */
  onLogout(): void {
    this.authService.logout();
    this.filter$.clearData();
  }

  /**
   * Focus on an element or display/show the menu
   * @param event - A event clicked
   * @param focusId - the element to focus on
   */
  onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.onHideMenu();
    }
  }

  /**
   * Set the modal function
   * @param foo - the new modal value
   */
  storageModalFunction(foo: any): void {
    this.modalFunction = foo;
  }

  /**
   * calls modalFunction function
   */
  onClickModalBtn(): void {
    this.modalFunction();
  }

  /**
   * Hide register modal.
   */
  hideRegisterModal(): void {
    this.ribbonModal.display = false;
  }

  /**
   * Focus the page on a specific element
   * @param elem - the element to focus
   */
  private focusElement(elem: ElementRef): void {
    if (elem) {
      elem.nativeElement.focus();
    }
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
