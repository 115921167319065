import { Component } from '@angular/core';

@Component({
  selector: 'app-data-viewer-dropdown',
  templateUrl: './data-viewer-dropdown.component.html',
  styleUrls: [ './data-viewer-dropdown.component.scss' ]
})
// DataViewerDropdown component
export class DataViewerDropdownComponent {


}
