import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { StatusModalService } from '../../modals/status-modal/status-modal.service';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
  @Output() uploadFile = new EventEmitter<any>();

  public fileSelectedName: string;

  /**
   * constructor function
   * @param statusModalServ - a StatusModalService object
   */
  constructor(private statusModalServ: StatusModalService) {
    this.statusModalServ = statusModalServ;
  }

  /**
   * Drag a file hover the input
   * @param evt - a drag event
   */
  @HostListener('dragover', ['$event'])
  onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  /**
   * Drop a file in the input
   * @param event drop eveent
   */
  @HostListener('drop', ['$event'])
  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.handleFileProperties(event.dataTransfer.files[0]);
  }

  /**
   * Select a file to upload
   * @param event -the select event
   */
  onFileSelected(event: any): void {
    this.handleFileProperties(event.target.files[0]);
  }

  /**
   * Check if the the file is admissible to be uploaded
   * @param file - The file to upload
   */
  private handleFileProperties(file: File): void {
    const { name, size, type }: { name: string, size: number, type: string } = file;
    let displayErrorMessageParam = 'AVOID-FILE-TYPE';
    let updateFileParam: File;

    if (size > Numbers.number20000000) { displayErrorMessageParam = 'FILE-SIZE'; }

    if (this.isFileAcceptedByName(name) || this.isFileAcceptedByType(type)) { updateFileParam = file; }

    if (!updateFileParam) { this.statusModalServ.displayErrorMessage(displayErrorMessageParam, true); }

    this.updateFile(updateFileParam);

  }

  /**
   * emit an event to upload the file and select its name
   * @param file - the file
   */
  private updateFile(file?: File): void {
    this.uploadFile.emit(file);

    this.fileSelectedName = null;
    if (file) {
      this.fileSelectedName = file.name;
    }
  }

  /**
   * Determine if the file is accepted by document type
   * @param type - file type. ong, jpg, pdf..
   * @returns if the file is valid
   */
  private isFileAcceptedByType(type: string): boolean {
    const filesAcepted = ['application/pdf', 'image/jpeg', 'image/png'];
    return filesAcepted.includes(type);
  }

  /**
   * Determine if the file is accepted by its name
   * @param name - file name
   * @returns if the file is valid
   */
  private isFileAcceptedByName(name: string): boolean {
    const filesAcepted = ['pdf', 'doc', 'docx', 'png', 'jpeg', 'jpg', 'xlsx', 'xls', 'raw', 'heic'];

    const fileExtensionArray: string[] = name.split('.');

    const fileExtension: string = fileExtensionArray[fileExtensionArray.length - 1];

    return filesAcepted.includes(fileExtension.toLowerCase());
  }
}
