import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './modules/layout/layout.component';
import { Error404Component } from './modules/layout/components/error404/error404.component';
import { NavigatorsCheckGuard } from './core/guards/navigators-check-guard/navigators-check.guard';
import { NavigatorIncompatibleComponent } from './modules/layout/components/navigator-incompatible/navigator-incompatible.component';
import { KeycloakGuard } from './core/guards/keycloak-guard/keycloak.guard';

/**
 * All the routes and its children routes which must be loaded
 * There are guards included.
 * There are cases in which, lazy Loading is implemented.
 */
const ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'certifications'
      },
      {
        path: 'certifications',
        canLoad: [NavigatorsCheckGuard],
        // Lazy loading
        loadChildren: () => import('./modules/certifications/certifications.module').then((m) => m.CertificationsModule),
        data: {
          header: 'HEADER.TITLE.CERTIFICATIONS'
        }
      },
      {
        path: 'fdc',
        canLoad: [NavigatorsCheckGuard],
        // Lazy loading
        loadChildren: () => import('./modules/certifications/certifications.module').then((m) => m.CertificationsModule),
        data: {
          header: 'HEADER.TITLE.FDC'
        }
      },

      {
        path: 'order-finder',
        canLoad: [NavigatorsCheckGuard],
        // Lazy loading
        loadChildren: () => import('./modules/order-finder/order-finder.module').then((m) => m.OrderFinderModule),
        data: {
          header: 'HEADER.TITLE.ORDER-FINDER'
        }
      },
      {
        path: 'delivery-proof',
        canLoad: [NavigatorsCheckGuard],
        // Lazy loading
        loadChildren: () => import('./modules/delivery-proof/delivery-proof.module').then((m) => m.DeliveryProofModule),
        data: {
          header: 'HEADER.TITLE.DELIVERY-PROOF'
        }
      },
      {
        path: 'users',
        canLoad: [NavigatorsCheckGuard],
        canActivate: [KeycloakGuard],
        // Lazy loading
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
        data: {
          header: 'HEADER.TITLE.USERS'
        }
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'registration',
    canLoad: [NavigatorsCheckGuard],
    // Lazy loading
    loadChildren: () => import('./modules/registration/registration.module').then((m) => m.RegistrationModule)
  },
  { path: 'browser-no-compatible', component: NavigatorIncompatibleComponent },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
