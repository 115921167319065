import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ListadoRegistroSalida } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListadoRegistroSalida';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { ListadoRegistrosEntrada } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListadoRegistrosEntrada';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Utils } from '../../../utils/utils';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-register-list-modal',
  templateUrl: './register-list-modal.component.html',
  styleUrls: ['./register-list-modal.component.scss']
})
export class RegisterListModalComponent implements OnInit, OnDestroy, OnChanges {
  //#region PROPS
  @Input() selectedRows: Array<any>;
  @Input() modalOptions?: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  // Manage subscriptions
  private subscriptions: Subscription[] = [];
  public availableOptions: { label: number; value: number }[] = [
    { label: Numbers.number1, value: Numbers.number1 },
    { label: Numbers.number2, value: Numbers.number2 },
    { label: Numbers.number3, value: Numbers.number3 }
  ];
  public selectedNumOfCopies = 3;
  public isDniRegistro = false;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param authServ - a AuthService object
   * @param filterServ - a FilterService object
   * @param renderer - Renderer2 object
   */
  constructor(
    private manageReqSOS: ManageRequestSosService,
    private authServ: AuthService,
    private filterServ: FilterService,
    private renderer: Renderer2
  ) {
    this.manageReqSOS = manageReqSOS;
    this.authServ = authServ;
    this.filterServ = filterServ;
    this.renderer = renderer;
  }

  /**
   * OnChanges method
   * @param changes changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.isDniRegistro = changes.modalOptions.currentValue.isDniRegistro;
    if (this.isDniRegistro) {
      this.selectedNumOfCopies = 1;
    } else {
      this.selectedNumOfCopies = 3;
    }
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.isDniRegistro = this.modalOptions.isDniRegistro;
    if (this.isDniRegistro) {
      this.selectedNumOfCopies = 1;
    }
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Create or Recover a register list
   * @returns void
   */
  handleClick(): void {
    // Crear listado de registro
    if (this.modalOptions.showOptions) {
      this.subscriptions.push(
        this.createRegisterList$(this.selectedRows)
          .pipe(
            finalize(() => {
              this.closeModalComponent();

              this.updateTableDataWithDelay();
              this.filterServ.clearSavedSelectedRows();
            })
          )
          .subscribe((res: ListadoRegistroSalida) => {
            this.successOperation.emit({ success: true, text: 'REGISTER-LIST-GENERATED', translate: true, variableText: `: ${res.numeroListado}` });
            Utils.managePdfUtils.downloadPDF(res.ficheroPDFBase64, res.numeroListado, this.renderer);
          })
      );
      // Recuperar listado de registro
    } else if (!this.modalOptions.showOptions && this.selectedRows[0].numListadoRegistro) {
      let refId = '';
      if (this.isDniRegistro) {
        refId = this.selectedRows[0].numListadoPeticion;
      } else {
        refId = this.selectedRows[0].numListadoRegistro;
      }
      this.subscriptions.push(
        this.recoverRegisterList$(refId)
          .pipe(
            finalize(() => {
              this.closeModalComponent();
              this.filterServ.clearSavedSelectedRows();
            })
          )
          .subscribe((res: ListadoRegistroSalida) => {
            this.successOperation.emit({ success: true, text: `REGISTER-LIST-SUCCESS`, translate: true });
            Utils.managePdfUtils.downloadPDF(res.ficheroPDFBase64, refId, this.renderer);
          })
      );
    } else {
      return;
    }
  }

  /**
   * emnit a event to close the modal
   */
  private closeModalComponent(): void {
    this.manageSelfDisplay.emit(false);
  }

  /**
   * Update the table data after 2 seconds
   */
  private updateTableDataWithDelay(): void {
    setTimeout(() => {
      this.updateTableData.emit();
    }, Numbers.number2000);
  }

  /**
   * Create a register list
   * @param selectedRows - selected orders to create the register list
   * @returns the register list
   */
  private createRegisterList$(selectedRows: Array<any>): Observable<ListadoRegistroSalida | HttpErrorResponse> {
    const idioma: string = this.authServ.getUserParam('lang');
    const numeroCopias: number = this.selectedNumOfCopies;
    const listadoRegistros: Array<ListadoRegistrosEntrada> = selectedRows.map((elem) => ({
      idEncargo: elem.encargoID,
      localidad: elem.localidad,
      provincia: elem.provincia,
      idCertificado: elem.tipoCertificado
    }));
    const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

    return this.manageReqSOS.provCrearPDFListadoRegistro$(idioma, numeroCopias, listadoRegistros, USER_ID);
  }

  /**
   * Recover a register list
   * @param refId the id ref
   * @returns The register list
   */
  private recoverRegisterList$(refId: string): Observable<ListadoRegistroSalida | HttpErrorResponse> {
    const idioma: string = this.authServ.getUserParam('lang');
    const numeroCopias: number = this.selectedNumOfCopies;
    const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

    return this.manageReqSOS.provRecuperarListadoRegistroPDF$(idioma, numeroCopias, refId, USER_ID);
  }
}
