/**
 * iSOS Proveedores
 * La API de proveedore iSOS proporciona a los desarrolladores un conjunto de operaciones para la gestión de proveedores
 *
 * OpenAPI spec version: 1.1
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BusquedaEncargos } from '../../../models/api/proveedores/BusquedaEncargos';
import { DatosContacto } from '../../../models/api/proveedores/DatosContacto';
import { DetallesSalida } from '../../../models/api/proveedores/DetallesSalida';
import { DevolverAvances } from '../../../models/api/proveedores/DevolverAvances';
import { DevolverAvancesPega } from '../../../models/api/proveedores/DevolverAvancesPega';
import { EncargoActualizarEntrada } from '../../../models/api/proveedores/EncargoActualizarEntrada';
import { EncargosSal } from '../../../models/api/proveedores/EncargosSal';
import { Incidencias } from '../../../models/api/proveedores/Incidencias';
import { JustificantesEntrega } from '../../../models/api/proveedores/JustificantesEntrega';
import { ListaEncargosEntrada } from '../../../models/api/proveedores/ListaEncargosEntrada';
import { ListaReferencias } from '../../../models/api/proveedores/ListaReferencias';
import { ListadoRegistroEntregaSalida } from '../../../models/api/proveedores/ListadoRegistroEntregaSalida';
import { ListadoRegistroSalida } from '../../../models/api/proveedores/ListadoRegistroSalida';
import { ListadoRegistrosEntrada } from '../../../models/api/proveedores/ListadoRegistrosEntrada';
import { UpdateCita } from '../../../models/api/proveedores/UpdateCita';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { DevolverNotificaciones } from '../../../models/api/proveedores/proveedores/DevolverNotificaciones';


@Injectable()
export class ProveedoresService {

    protected basePath = '/isos/proveedores/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath + '';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param - consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Api para actualizar con el asesoramiento
     *
     * @param - identificador Identificador a actualizar
     * @param - datos Datos a actualizar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresActualizarCitas(identificador: string, datos: UpdateCita, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public proveedoresActualizarCitas(identificador: string, datos: UpdateCita, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresActualizarCitas(identificador: string, datos: UpdateCita, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresActualizarCitas(identificador: string, datos: UpdateCita, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling proveedoresActualizarCitas.');
        }

        if (datos === null || datos === undefined) {
            throw new Error('Required parameter datos was null or undefined when calling proveedoresActualizarCitas.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DevolverAvances>(`${this.basePath}/citas/${encodeURIComponent(String(identificador))}`,
            datos,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Actualización de un contacto
     *
     * @param - identificador Identificador de un contacto a actualizar
     * @param - personaContacto Datos a modificar del contacto.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresActualizarContacto(
        identificador: string,
        personaContacto: DatosContacto,
        canal?: 'PP' | 'PA',
        tipoEncargo?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<DevolverAvances>;
    public proveedoresActualizarContacto(
        identificador: string,
        personaContacto: DatosContacto,
        canal?: 'PP' | 'PA',
        tipoEncargo?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresActualizarContacto(
        identificador: string,
        personaContacto: DatosContacto,
        canal?: 'PP' | 'PA',
        tipoEncargo?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresActualizarContacto(
        identificador: string,
        personaContacto: DatosContacto,
        canal?: 'PP' | 'PA',
        tipoEncargo?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (identificador === null || identificador === undefined) {
          throw new Error('Required parameter identificador was null or undefined when calling proveedoresActualizarContacto.');
        }

        if (personaContacto === null || personaContacto === undefined) {
          throw new Error('Required parameter personaContacto was null or undefined when calling proveedoresActualizarContacto.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

        if (canal !== undefined && canal !== null) {
          queryParameters = queryParameters.set('canal', canal as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
          queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
          const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
          headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
          headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DevolverAvances>(`${this.basePath}/contactos/${encodeURIComponent(String(identificador))}`, personaContacto, {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers,
          observe,
          reportProgress
        });
    }

    /**
     * Actualiza el certificado de un encargo.
     *
     * @param - identificador Identificador a crear.
     * @param - datosActualizar Consentimientos a modificar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresActualizarEncargo(identificador: string, datosActualizar: EncargoActualizarEntrada, canal?: 'PP' | 'PA', observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public proveedoresActualizarEncargo(
       identificador: string,
       datosActualizar: EncargoActualizarEntrada,
       canal?: 'PP' | 'PA',
       observe?: 'response',
       reportProgress?: boolean
     ): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresActualizarEncargo(
       identificador: string,
       datosActualizar: EncargoActualizarEntrada,
       canal?: 'PP' | 'PA',
       observe?: 'events',
       reportProgress?: boolean
    ): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresActualizarEncargo(identificador: string, datosActualizar: EncargoActualizarEntrada, canal?: 'PP' | 'PA', observe: any = 'body', reportProgress: boolean = false): Observable<any> {
       if (identificador === null || identificador === undefined) {
         throw new Error('Required parameter identificador was null or undefined when calling proveedoresActualizarEncargo.');
       }

       if (datosActualizar === null || datosActualizar === undefined) {
         throw new Error('Required parameter datosActualizar was null or undefined when calling proveedoresActualizarEncargo.');
       }

       let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
       if (canal !== undefined && canal !== null) {
         queryParameters = queryParameters.set('canal', canal as any);
       }

       let headers = this.defaultHeaders;

       // authentication (oauth) required
       if (this.configuration.accessToken) {
         const accessToken = typeof this.configuration.accessToken === 'function' ? this.configuration.accessToken() : this.configuration.accessToken;
         headers = headers.set('Authorization', 'Bearer ' + accessToken);
       }

       // to determine the Accept header
       const httpHeaderAccepts: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml'];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected !== undefined) {
         headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
       const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
       if (httpContentTypeSelected !== undefined) {
         headers = headers.set('Content-Type', httpContentTypeSelected);
       }

       return this.httpClient.put<DevolverAvances>(`${this.basePath}/encargo/${encodeURIComponent(String(identificador))}`, datosActualizar, {
         params: queryParameters,
         withCredentials: this.configuration.withCredentials,
         headers,
         observe,
         reportProgress
       });
    }
    /**
     * Actualiza un estado pasando al siguiente.
     *
     * @param - listaReferencias Listado de referencias a avanzar
     * @param - idioma ES,CA,EN o FR
     * @param - idProveedor identificador del proveedor(Opcional)
     * @param - estado Estado a futuro al que se quiere avanzar(Opcional)
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresAvanzarEstado(listaReferencias: Array<ListaReferencias>, idioma: string, idProveedor?: string, estado?: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvancesPega>;
    public proveedoresAvanzarEstado(listaReferencias: Array<ListaReferencias>, idioma: string, idProveedor?: string, estado?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvancesPega>>;
    public proveedoresAvanzarEstado(listaReferencias: Array<ListaReferencias>, idioma: string, idProveedor?: string, estado?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvancesPega>>;
    public proveedoresAvanzarEstado(listaReferencias: Array<ListaReferencias>, idioma: string, idProveedor?: string, estado?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (listaReferencias === null || listaReferencias === undefined) {
            throw new Error('Required parameter listaReferencias was null or undefined when calling proveedoresAvanzarEstado.');
        }

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling proveedoresAvanzarEstado.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }
        if (estado !== undefined && estado !== null) {
            queryParameters = queryParameters.set('estado', estado as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DevolverAvancesPega>(`${this.basePath}/estados`,
            listaReferencias,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Busqueda de los encargos por filtros establecidos.
     *
     * @param - identificador Indicar el proveedor a consultar
     * @param - tipoCertificado Tipo del certificado
     * @param - tipoEncargo Indicar el tipo de encargo a consultar
     * @param - estado Indicar el estado a consultar.
     * @param - nombre Nombre a buscar
     * @param - apellido1 Apellido1 a buscar
     * @param - apellido2 Apellido2 a buscar
     * @param - numeroPoliza Número de póliza
     * @param - numeroTelefono Número de teléfono
     * @param - fechaDesde Fecha de inicio de la busqueda
     * @param - fechaHasta Fecha final de l a busqueda
     * @param - orderID Orden establecido
     * @param - caseID Numero de GD
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresBuscarEncargos(
        identificador: string,
        tipoCertificado?: string,
        tipoEncargo?: string,
        estado?: string,
        nombre?: string,
        apellido1?: string,
        apellido2?: string,
        numeroPoliza?: string,
        numeroTelefono?: string,
        fechaDesde?: string,
        fechaHasta?: string,
        orderID?: string,
        caseID?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<BusquedaEncargos>;
    public proveedoresBuscarEncargos(
        identificador: string,
        tipoCertificado?: string,
        tipoEncargo?: string,
        estado?: string,
        nombre?: string,
        apellido1?: string,
        apellido2?: string,
        numeroPoliza?: string,
        numeroTelefono?: string,
        fechaDesde?: string,
        fechaHasta?: string,
        orderID?: string,
        caseID?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<BusquedaEncargos>>;
    public proveedoresBuscarEncargos(
        identificador: string,
        tipoCertificado?: string,
        tipoEncargo?: string,
        estado?: string,
        nombre?: string,
        apellido1?: string,
        apellido2?: string,
        numeroPoliza?: string,
        numeroTelefono?: string,
        fechaDesde?: string,
        fechaHasta?: string,
        orderID?: string,
        caseID?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<BusquedaEncargos>>;
    public proveedoresBuscarEncargos(
        identificador: string,
        tipoCertificado?: string,
        tipoEncargo?: string,
        estado?: string,
        nombre?: string,
        apellido1?: string,
        apellido2?: string,
        numeroPoliza?: string,
        numeroTelefono?: string,
        fechaDesde?: string,
        fechaHasta?: string,
        orderID?: string,
        caseID?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling proveedoresBuscarEncargos.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tipoCertificado !== undefined && tipoCertificado !== null) {
            queryParameters = queryParameters.set('tipoCertificado', tipoCertificado as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (estado !== undefined && estado !== null) {
            queryParameters = queryParameters.set('estado', estado as any);
        }
        if (nombre !== undefined && nombre !== null) {
            queryParameters = queryParameters.set('nombre', nombre as any);
        }
        if (apellido1 !== undefined && apellido1 !== null) {
            queryParameters = queryParameters.set('apellido1', apellido1 as any);
        }
        if (apellido2 !== undefined && apellido2 !== null) {
            queryParameters = queryParameters.set('apellido2', apellido2 as any);
        }
        if (numeroPoliza !== undefined && numeroPoliza !== null) {
            queryParameters = queryParameters.set('numeroPoliza', numeroPoliza as any);
        }
        if (numeroTelefono !== undefined && numeroTelefono !== null) {
            queryParameters = queryParameters.set('numeroTelefono', numeroTelefono as any);
        }
        if (fechaDesde !== undefined && fechaDesde !== null) {
            queryParameters = queryParameters.set('fechaDesde', fechaDesde as any);
        }
        if (fechaHasta !== undefined && fechaHasta !== null) {
            queryParameters = queryParameters.set('fechaHasta', fechaHasta as any);
        }
        if (orderID !== undefined && orderID !== null) {
            queryParameters = queryParameters.set('orderID', orderID as any);
        }
        if (caseID !== undefined && caseID !== null) {
            queryParameters = queryParameters.set('caseID', caseID as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BusquedaEncargos>(`${this.basePath}/encargos/${encodeURIComponent(String(identificador))}/certificado`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consulta el detalle de un encargo.
     *
     * @param - identificador Identificador del Encargo
     * @param - idProveedor Identificador del proveedor
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresConsultarDetalle(identificador: string, idProveedor: string, canal?: 'PP' | 'PA', observe?: 'body', reportProgress?: boolean): Observable<DetallesSalida>;
    public proveedoresConsultarDetalle(identificador: string, idProveedor: string, canal?: 'PP' | 'PA', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DetallesSalida>>;
    public proveedoresConsultarDetalle(identificador: string, idProveedor: string, canal?: 'PP' | 'PA', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DetallesSalida>>;
    public proveedoresConsultarDetalle(identificador: string, idProveedor: string, canal?: 'PP' | 'PA', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling proveedoresConsultarDetalle.');
        }

        if (idProveedor === null || idProveedor === undefined) {
            throw new Error('Required parameter idProveedor was null or undefined when calling proveedoresConsultarDetalle.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }
        if (canal !== undefined && canal !== null) {
            queryParameters = queryParameters.set('canal', canal as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DetallesSalida>(`${this.basePath}/encargos/${encodeURIComponent(String(identificador))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consulta los encargos de un proveedor.
     *
     * @param - idProveedor Indicar el proveedor a consultar
     * @param - idAplicacion Indicar la aplicación a consultar
     * @param - tipoEncargo Indicar el tipo de encargo a consultar
     * @param - estado Indicar el estado a consultar.
     * @param - documento Indicar el DNI de quien hace la consulta.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresConsultarEncargos(
        idProveedor: string, idAplicacion: string, tipoEncargo: string, estado: string, documento: string, desde?: string, hasta?: string, observe?: 'body', reportProgress?: boolean): Observable<EncargosSal>;
    public proveedoresConsultarEncargos(
        idProveedor: string, idAplicacion: string, tipoEncargo: string, estado: string, documento: string, desde?: string, hasta?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EncargosSal>>;
    public proveedoresConsultarEncargos(
        idProveedor: string, idAplicacion: string, tipoEncargo: string, estado: string, documento: string, desde?: string, hasta?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EncargosSal>>;
    public proveedoresConsultarEncargos(
        idProveedor: string, idAplicacion: string, tipoEncargo: string, estado: string, documento: string, desde?: string, hasta?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProveedor === null || idProveedor === undefined) {
            throw new Error('Required parameter idProveedor was null or undefined when calling proveedoresConsultarEncargos.');
        }

        if (idAplicacion === null || idAplicacion === undefined) {
            throw new Error('Required parameter idAplicacion was null or undefined when calling proveedoresConsultarEncargos.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling proveedoresConsultarEncargos.');
        }

        if (estado === null || estado === undefined) {
            throw new Error('Required parameter estado was null or undefined when calling proveedoresConsultarEncargos.');
        }

        if (documento === null || documento === undefined) {
            throw new Error('Required parameter documento was null or undefined when calling proveedoresConsultarEncargos.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }
        if (idAplicacion !== undefined && idAplicacion !== null) {
            queryParameters = queryParameters.set('idAplicacion', idAplicacion as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (estado !== undefined && estado !== null) {
            queryParameters = queryParameters.set('estado', estado as any);
        }
        if (documento !== undefined && documento !== null) {
            queryParameters = queryParameters.set('documento', documento as any);
        }
        if ( estado === 'FINALIZADO') {
            queryParameters = queryParameters.set('fechaInicio', desde as any);
            queryParameters = queryParameters.set('fechaFin', hasta as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EncargosSal>(`${this.basePath}/encargos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Creación de una incidencia
     *
     * @param - incidencias Incidencia completa con adjuntos
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresCrearIncidencia(incidencias: Incidencias, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public proveedoresCrearIncidencia(incidencias: Incidencias, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresCrearIncidencia(incidencias: Incidencias, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresCrearIncidencia(incidencias: Incidencias, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (incidencias === null || incidencias === undefined) {
            throw new Error('Required parameter incidencias was null or undefined when calling proveedoresCrearIncidencia.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DevolverAvances>(`${this.basePath}/incidencias`,
            incidencias,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Creación de un pdf con el listado de registro de referencias.
     *
     * @param - idioma ES,CA,EN o FR
     * @param - numeroCopias Numero de copias que se realizan
     * @param - listadoRegistros Listado con los registros a generar.
     * @param - idProveedor identificador del proveedor(Opcional)
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresGenerarListadoRegistro(
        idioma: string,
        numeroCopias: number,
        listadoRegistros: Array<ListadoRegistrosEntrada>,
        idProveedor?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<ListadoRegistroSalida>;
    public proveedoresGenerarListadoRegistro(
        idioma: string,
        numeroCopias: number,
        listadoRegistros: Array<ListadoRegistrosEntrada>,
        idProveedor?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<ListadoRegistroSalida>>;
    public proveedoresGenerarListadoRegistro(
        idioma: string,
        numeroCopias: number,
        listadoRegistros: Array<ListadoRegistrosEntrada>,
        idProveedor?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<ListadoRegistroSalida>>;
    public proveedoresGenerarListadoRegistro(
        idioma: string,
        numeroCopias: number,
        listadoRegistros: Array<ListadoRegistrosEntrada>,
        idProveedor?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling proveedoresGenerarListadoRegistro.');
        }

        if (numeroCopias === null || numeroCopias === undefined) {
            throw new Error('Required parameter numeroCopias was null or undefined when calling proveedoresGenerarListadoRegistro.');
        }

        if (listadoRegistros === null || listadoRegistros === undefined) {
            throw new Error('Required parameter listadoRegistros was null or undefined when calling proveedoresGenerarListadoRegistro.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }
        if (numeroCopias !== undefined && numeroCopias !== null) {
            queryParameters = queryParameters.set('numeroCopias', numeroCopias as any);
        }
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListadoRegistroSalida>(`${this.basePath}/listados-registro`,
            listadoRegistros,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Creación de un pdf con el listado de entrega de referencias.
     *
     * @param - idioma ES,CA,EN o FR
     * @param - listadoEntrega Listado con los registros a generar.
     * @param - idProveedor identificador del proveedor(Opcional)
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresGenerarListadoRegistroentrega(idioma: string, listadoEntrega: Array<ListaEncargosEntrada>, idProveedor?: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoRegistroSalida>;
    public proveedoresGenerarListadoRegistroentrega(idioma: string, listadoEntrega: Array<ListaEncargosEntrada>, idProveedor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoRegistroSalida>>;
    public proveedoresGenerarListadoRegistroentrega(idioma: string, listadoEntrega: Array<ListaEncargosEntrada>, idProveedor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoRegistroSalida>>;
    public proveedoresGenerarListadoRegistroentrega(idioma: string, listadoEntrega: Array<ListaEncargosEntrada>, idProveedor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling proveedoresGenerarListadoRegistroentrega.');
        }

        if (listadoEntrega === null || listadoEntrega === undefined) {
            throw new Error('Required parameter listadoEntrega was null or undefined when calling proveedoresGenerarListadoRegistroentrega.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListadoRegistroSalida>(`${this.basePath}/listados-entrega`,
            listadoEntrega,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Creación de un justifcante de entrega
     *
     * @param - identificador Identificador del justificante a guardar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresGuardarJustificanteEntrega(identificador: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public proveedoresGuardarJustificanteEntrega(identificador: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresGuardarJustificanteEntrega(identificador: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresGuardarJustificanteEntrega(identificador: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling proveedoresGuardarJustificanteEntrega.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<DevolverAvances>(`${this.basePath}/justificantes-entrega/${encodeURIComponent(String(identificador))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Imprimir un sobre para un listado de enbtrega
     *
     * @param - identificador Nombre de la referencia del listado a imprimir
     * @param - listaSobres Listado de las refernecias a imprimir los sobres.
     * @param - idProveedor identificador del proveedor(Opcional)
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresImprimirSobresListadoEntrega(identificador: string, listaSobres: Array<any>, idProveedor?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public proveedoresImprimirSobresListadoEntrega(identificador: string, listaSobres: Array<any>, idProveedor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public proveedoresImprimirSobresListadoEntrega(identificador: string, listaSobres: Array<any>, idProveedor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public proveedoresImprimirSobresListadoEntrega(identificador: string, listaSobres: Array<any>, idProveedor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling proveedoresImprimirSobresListadoEntrega.');
        }

        if (listaSobres === null || listaSobres === undefined) {
            throw new Error('Required parameter listaSobres was null or undefined when calling proveedoresImprimirSobresListadoEntrega.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/listados-entrega/${encodeURIComponent(String(identificador))}/sobres`,
            listaSobres,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupera un excel de certificaciones GD y FDC.
     *
     * @param - idProveedor Identificador del proveedor
     * @param - tipoAplicacion Código Aplicación
     * @param - tipoEncargo Código Tipo Encargo
     * @param - estados Estados a buscar
     * @param - fechaInicio Búsqueda en la fecha de creación (desde)
     * @param - fechaFin Búsqueda en la fecha de creación (hasta)
     * @param - numMaxRegristo Número máximo de registros
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresListadoExcel(
        idProveedor: string,
        tipoAplicacion: string,
        tipoEncargo: string,
        estados: string,
        fechaInicio?: string,
        fechaFin?: string,
        numMaxRegristo?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<any>;
    public proveedoresListadoExcel(
        idProveedor: string,
        tipoAplicacion: string,
        tipoEncargo: string,
        estados: string,
        fechaInicio?: string,
        fechaFin?: string,
        numMaxRegristo?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public proveedoresListadoExcel(
        idProveedor: string,
        tipoAplicacion: string,
        tipoEncargo: string,
        estados: string,
        fechaInicio?: string,
        fechaFin?: string,
        numMaxRegristo?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public proveedoresListadoExcel(
        idProveedor: string,
        tipoAplicacion: string,
        tipoEncargo: string,
        estados: string,
        fechaInicio?: string,
        fechaFin?: string,
        numMaxRegristo?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {

        if (idProveedor === null || idProveedor === undefined) {
            throw new Error('Required parameter idProveedor was null or undefined when calling proveedoresListadoExcel.');
        }

        if (tipoAplicacion === null || tipoAplicacion === undefined) {
            throw new Error('Required parameter tipoAplicacion was null or undefined when calling proveedoresListadoExcel.');
        }

        if (tipoEncargo === null || tipoEncargo === undefined) {
            throw new Error('Required parameter tipoEncargo was null or undefined when calling proveedoresListadoExcel.');
        }

        if (estados === null || estados === undefined) {
            throw new Error('Required parameter estados was null or undefined when calling proveedoresListadoExcel.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }
        if (tipoAplicacion !== undefined && tipoAplicacion !== null) {
            queryParameters = queryParameters.set('tipoAplicacion', tipoAplicacion as any);
        }
        if (tipoEncargo !== undefined && tipoEncargo !== null) {
            queryParameters = queryParameters.set('tipoEncargo', tipoEncargo as any);
        }
        if (estados !== undefined && estados !== null) {
            queryParameters = queryParameters.set('estados', estados as any);
        }
        if (fechaInicio !== undefined && fechaInicio !== null) {
            queryParameters = queryParameters.set('fechaInicio', fechaInicio as any);
        }
        if (fechaFin !== undefined && fechaFin !== null) {
            queryParameters = queryParameters.set('fechaFin', fechaFin as any);
        }
        if (numMaxRegristo !== undefined && numMaxRegristo !== null) {
            queryParameters = queryParameters.set('numMaxRegristo', numMaxRegristo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/certificaciones`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Devuelve un listado de justificantes de entrega.
     *
     * @param - idProveedor Identificador del proveedor
     * @param - fechaDesde Fecha inicio
     * @param - fechaHasta Fecha fin
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresListadoJustificanteEntrega(idProveedor: string, fechaDesde: string, fechaHasta: string, observe?: 'body', reportProgress?: boolean): Observable<Array<JustificantesEntrega>>;
    public proveedoresListadoJustificanteEntrega(idProveedor: string, fechaDesde: string, fechaHasta: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<JustificantesEntrega>>>;
    public proveedoresListadoJustificanteEntrega(idProveedor: string, fechaDesde: string, fechaHasta: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<JustificantesEntrega>>>;
    public proveedoresListadoJustificanteEntrega(idProveedor: string, fechaDesde: string, fechaHasta: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idProveedor === null || idProveedor === undefined) {
            throw new Error('Required parameter idProveedor was null or undefined when calling proveedoresListadoJustificanteEntrega.');
        }

        if (fechaDesde === null || fechaDesde === undefined) {
            throw new Error('Required parameter fechaDesde was null or undefined when calling proveedoresListadoJustificanteEntrega.');
        }

        if (fechaHasta === null || fechaHasta === undefined) {
            throw new Error('Required parameter fechaHasta was null or undefined when calling proveedoresListadoJustificanteEntrega.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }
        if (fechaDesde !== undefined && fechaDesde !== null) {
            queryParameters = queryParameters.set('fechaDesde', fechaDesde as any);
        }
        if (fechaHasta !== undefined && fechaHasta !== null) {
            queryParameters = queryParameters.set('fechaHasta', fechaHasta as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<JustificantesEntrega>>(`${this.basePath}/justificantes-entrega`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Api para reabrir un encargo
     *
     * @param - identificador Identificador de un caso a actualizar
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresReabrirCasos(identificador: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public proveedoresReabrirCasos(identificador: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresReabrirCasos(identificador: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresReabrirCasos(identificador: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificador === null || identificador === undefined) {
            throw new Error('Required parameter identificador was null or undefined when calling proveedoresReabrirCasos.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<DevolverAvances>(`${this.basePath}/reabrir-casos/${encodeURIComponent(String(identificador))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Devuelve un justifcante de entrega en pdf
     *
     * @param - nombreArchivo Nombre del archivo a devolver.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresRecuperarJustificanteEntrega(nombreArchivo: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverAvances>;
    public proveedoresRecuperarJustificanteEntrega(nombreArchivo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverAvances>>;
    public proveedoresRecuperarJustificanteEntrega(nombreArchivo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverAvances>>;
    public proveedoresRecuperarJustificanteEntrega(nombreArchivo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (nombreArchivo === null || nombreArchivo === undefined) {
            throw new Error('Required parameter nombreArchivo was null or undefined when calling proveedoresRecuperarJustificanteEntrega.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nombreArchivo !== undefined && nombreArchivo !== null) {
            queryParameters = queryParameters.set('nombreArchivo', nombreArchivo as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DevolverAvances>(`${this.basePath}/justificantes-entrega-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupera un listado de registro de referencias en formato PDF.
     *
     * @param - idioma ES,CA,EN o FR
     * @param - numeroCopias Número de copias
     * @param - referencia Referencia a recuperar.
     * @param - idProveedor identificador del proveedor(Opcional)
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresRecuperarListadoRegistro(idioma: string, numeroCopias: number, referencia: string, idProveedor?: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoRegistroSalida>;
    public proveedoresRecuperarListadoRegistro(idioma: string, numeroCopias: number, referencia: string, idProveedor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoRegistroSalida>>;
    public proveedoresRecuperarListadoRegistro(idioma: string, numeroCopias: number, referencia: string, idProveedor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoRegistroSalida>>;
    public proveedoresRecuperarListadoRegistro(idioma: string, numeroCopias: number, referencia: string, idProveedor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling proveedoresRecuperarListadoRegistro.');
        }

        if (numeroCopias === null || numeroCopias === undefined) {
            throw new Error('Required parameter numeroCopias was null or undefined when calling proveedoresRecuperarListadoRegistro.');
        }

        if (referencia === null || referencia === undefined) {
            throw new Error('Required parameter referencia was null or undefined when calling proveedoresRecuperarListadoRegistro.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }
        if (numeroCopias !== undefined && numeroCopias !== null) {
            queryParameters = queryParameters.set('numeroCopias', numeroCopias as any);
        }
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadoRegistroSalida>(`${this.basePath}/listados-registro/${encodeURIComponent(String(referencia))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupera un listado de entrega de referencias en formato PDF.
     * 200 - Devuelve: Pdf en base64.    401 - invalid_grant    404 - Referencia no encontrada.    500 - Error técnico.
     * @param - idioma ES,CA,EN o FR.
     * @param - referencia Referencia a recuperar.
     * @param - idProveedor identificador del proveedor(Opcional)
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public proveedoresRecuperarListadoRegistroentrega(idioma: string, referencia: string, idProveedor?: string, observe?: 'body', reportProgress?: boolean): Observable<ListadoRegistroEntregaSalida>;
    public proveedoresRecuperarListadoRegistroentrega(idioma: string, referencia: string, idProveedor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListadoRegistroEntregaSalida>>;
    public proveedoresRecuperarListadoRegistroentrega(idioma: string, referencia: string, idProveedor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListadoRegistroEntregaSalida>>;
    public proveedoresRecuperarListadoRegistroentrega(idioma: string, referencia: string, idProveedor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling proveedoresRecuperarListadoRegistroentrega.');
        }

        if (referencia === null || referencia === undefined) {
            throw new Error('Required parameter referencia was null or undefined when calling proveedoresRecuperarListadoRegistroentrega.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idioma !== undefined && idioma !== null) {
            queryParameters = queryParameters.set('idioma', idioma as any);
        }
        if (idProveedor !== undefined && idProveedor !== null) {
            queryParameters = queryParameters.set('idProveedor', idProveedor as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListadoRegistroEntregaSalida>(`${this.basePath}/listados-entrega/${encodeURIComponent(String(referencia))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupera las notificaciones
     *
     * @param - aplicacion
     * @param - canal
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
     public proveedoresNotificaciones(aplicacion: string, canal: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverNotificaciones>;
     public proveedoresNotificaciones(aplicacion: string, canal: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverNotificaciones>>;
     public proveedoresNotificaciones(aplicacion: string, canal: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverNotificaciones>>;
     public proveedoresNotificaciones(aplicacion: string, canal: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (aplicacion === null || aplicacion === undefined) {
             throw new Error('Required parameter aplicacion was null or undefined when calling proveedoresNotificaciones.');
         }

         if (canal === null || canal === undefined) {
             throw new Error('Required parameter canal was null or undefined when calling proveedoresNotificaciones.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (canal !== undefined && canal !== null) {
             queryParameters = queryParameters.set('canal', canal as any);
         }

         let headers = this.defaultHeaders;

         // authentication (oauth) required
         if (this.configuration.accessToken) {
             const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;
             headers = headers.set('Authorization', 'Bearer ' + accessToken);
         }

         // to determine the Accept header
         const httpHeaderAccepts: string[] = [
             'application/json',
             'text/json',
             'application/xml',
             'text/xml'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected !== undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
         ];

         return this.httpClient.get<DevolverNotificaciones>(`${this.basePath}/notificaciones/${encodeURIComponent(String(aplicacion))}`,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers,
                 observe,
                 reportProgress
             }
         );
     }

     logError(message: string): Observable<any> {

        const accessToken = typeof this.configuration.accessToken === 'function'
                 ? this.configuration.accessToken()
                 : this.configuration.accessToken;

        const httpOpt = {
            headers: new HttpHeaders()
            .append('Content-Type', 'application/json; charset=utf-8')
            .append('Accept', 'application/json')
            .set('Authorization', `Bearer ${accessToken}`)
        };

        const bodyData: { tipoPortal: string; descripcion: string; } = {
            tipoPortal: 'P',
            descripcion: message
        };

        return this.httpClient.post<any>(`${this.basePath}/errores`, bodyData, httpOpt);
    }

}
