import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCaptureIntro]'
})
export class CaptureIntroDirective {

  /**
   * Constructor function
   * @param el - a ElementRef object
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }

  /**
   * Valuate if the pressed key is Enter and make some actions
   * @param evt - The keuboard event
   */
  @HostListener('window:keyup', ['$event']) launchAction(evt: KeyboardEvent): void {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      const btn: HTMLButtonElement = this.el.nativeElement.querySelector('button');
      btn.click();
    }
  }
}
