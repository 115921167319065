import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UpdateCita } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/UpdateCita';

import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { DevolverAvances } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvances';
import { HttpErrorResponse } from '@angular/common/http';
import { ListaReferencias } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListaReferencias';
import { DevolverAvancesPega } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvancesPega';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { Utils } from '../../../utils/utils';
import { StatusModalService } from '../status-modal/status-modal.service';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { DetallesSalida } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DetallesSalida';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';


enum OrderState {
  NEW = 'NUEVO',
  ACTIVE = 'TRAMITACION',
  ACCEPTED = 'ACEPTADO'
}

@Component({
  selector: 'app-update-date-modal',
  templateUrl: './update-date-modal.component.html',
  styleUrls: ['./update-date-modal.component.scss']
})
export class UpdateDateModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: DetallesSalida;
  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  private subscriptions: Subscription[] = [];
  private orderId: string;
  public wrongDateSelected = false;
  // CALENDAR CONFIG
  public es: any = {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Borrar'
  };
  public calendarForm: FormGroup = new FormGroup({
    date: new FormControl()
  });
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param authServ - a AuthService object
   * @param filterService - a FilterService object
   * @param statusModal - a StatusModalService object
   */
  constructor(
    private manageReqSOS: ManageRequestSosService,
    private authServ: AuthService,
    private filterService: FilterService,
    readonly statusModal: StatusModalService
  ) {
    this.manageReqSOS = manageReqSOS;
    this.authServ = authServ;
    this.filterService = filterService;
    this.statusModal = statusModal;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * check if the data is right and update the date
   */
  handleClick(): void {
    const convertedDate: string = Utils.date.convertDate(this.calendarForm.get('date').value);
    this.checkIfFutureDate();

    if (!this.wrongDateSelected) {
      this.updateDate(convertedDate);
    }
  }

  /**
   * Update a date.
   * @param date - the new date
   */
  private updateDate(date: string): void {
    const { estado }: { estado?: string } = this.selectedDetail;

    this.subscriptions.push(
      this.updateOrderDate(this.orderId, date)
        .pipe(
          finalize(() => {
            this.manageSelfDisplay.emit(false);
          })
        )
        .subscribe((res: DevolverAvances) => {
          if (estado === OrderState.ACTIVE || estado === OrderState.ACCEPTED) {
            this.advanceState([{ identificador: this.orderId }], this.authServ.getUserParam('lang'));
            return;
          }

          this.successOperation.emit({ success: true, text: 'DATE-UPDATED', translate: true });
          this.updateTableData.emit();
        })
    );
  }

  /**
   * Change a references list to the next state
   * @param listaReferencias - references list
   * @param idioma - the language
   * @param estado - the next state
   */
  private advanceState(listaReferencias: Array<ListaReferencias>, idioma: string, estado?: string): void {
    const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

    this.subscriptions.push(
      this.manageReqSOS.provActualizarEstadoPasandoSiguiente$(listaReferencias, idioma, USER_ID, estado).subscribe((res: DevolverAvancesPega) => {
        this.filterService.clearSavedSelectedRows();
        this.successOperation.emit({ success: true, text: 'DATE-UPDATED', translate: true });
        this.updateTableData.emit();
      }, (error) => {
        if (error.status === Numbers.number504) {
          this.statusModal.displayErrorMessage('TIMEOUT-ERROR', true);
        }
        this.filterService.clearSavedSelectedRows();
        this.updateTableData.emit();
      })
    );
  }

  /**
   * Set the wrongDateSelected property as false.
   */
  private checkIfFutureDate(): void {
    this.wrongDateSelected = false;
  }

  /**
   * Update the order date
   * @param id - id order
   * @param date - the new date
   * @returns - the results
   */
  private updateOrderDate(id: string, date: string): Observable<DevolverAvances | HttpErrorResponse> {
    const { estado }: { estado?: string } = this.selectedDetail;
    let params: UpdateCita;

    if (estado === OrderState.NEW) {
      params = {
        fechaCitaDespacho: date
      };
    }

    if (estado === OrderState.ACTIVE) {
      params = {
        fechaProximaCita: date,
        resultadoCita: 'Will Think About It'
      };
    }

    if (estado === OrderState.ACCEPTED) {
      params = {
        resultadoAsesoramiento: 'In Progress',
        fechaCitaDespacho: date
      };
    }

    return this.manageReqSOS.provActualizarAsesoramiento$(id, params);
  }
}
