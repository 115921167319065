import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ]
})
export class LayoutComponent {
  public userData: Record<string, string> = {
    name: 'Antonio Torres',
    profile: 'Proveedor',
    initials: 'AT',
    color: ''
  };
  public headerTitle: string;

  /**
   * Constructor function
   * @param router - a Router object
   * @param route - a ActivatedRoute object
   */
  constructor( private router: Router, private route: ActivatedRoute) {
    this.route = route;
    this.router = router;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(r1 => {
        while (r1.firstChild) {
          r1 = r1.firstChild;
        }
        return r1;
      }),
      filter(r2 => r2.outlet === 'primary'),
      mergeMap(r3 => r3.data)
    ).subscribe(data => {
      this.headerTitle = data.header;
    });
  }
}
