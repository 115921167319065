/**
 * @description Extract only the currend day, month and year from server date
 * @param date Date from server, used to be 'DD-MM-YYYY HH:MM:SS'
 * @returns Formated date with DD-MM-YYYY
 */
export const extractCurrentDateWithoutHours: (date: string) => string = (date: string): string => {
  const dateServerRegExp = new RegExp(/((\d{2})[-](\d{2})[-](\d{4})(\s)(\d{2})[:](\d{2})[:](\d{2})*)/gi);
  const ddmmyyyyRegexp = new RegExp(/(\d{2})[-](\d{2})[-](\d{4})*/);

  const validDate: string = date.replace(/[/]/g, '-');

  const isValidDate: boolean = dateServerRegExp.test(validDate);

  let currentDate: string = validDate.match(ddmmyyyyRegexp)[0];
  if (!isValidDate ) {
    currentDate = date;
  }
  return currentDate;
};

/**
 * @description Get the DATE and return a string with format 'YYYY-MM-DDTHH:MM'
 * @param date - The date. Example: '2020-06-03T20:32'
 * @returns The converted date
 */
export const convertDate: (date: Date) => string = (date: Date): string => {
  let day: string = date.getDate().toString();
  if ( date.getDate().toString().length === 1 ) {
    day = '0' + date.getDate().toString();
  }
  let month: string = (date.getMonth() + 1).toString();
  if ( (date.getMonth() + 1).toString().length === 1 ) {
    month = '0' + (date.getMonth() + 1).toString();
  }
  const year: number = date.getFullYear();
  let hour: string = date.getHours().toString();
  if ( date.getHours().toString().length === 1 ) {
    hour = '0' + date.getHours().toString();
  }
  let minutes: string = date.getMinutes().toString();
  if ( date.getMinutes().toString().length === 1 ) {
    minutes = '0' + date.getMinutes().toString();
  }

  const convertedDate = year + '-' + month + '-' + day + 'T' + hour + ':' + minutes;
  return convertedDate;
};
