import { Component } from '@angular/core';

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: [ './page-selector.component.scss' ]
})
// Page Selector Component
export class PageSelectorComponent {
}
