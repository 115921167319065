import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expirationPipe'
})
export class ExpirationPipe implements PipeTransform {

  /**
   * Pipe method
   * @param value - the value to convert
   * @returns the converted value
   */
  transform(value: any): string {

    let valueToSend = '-';

    if ( value && value >= 0 ) {
      valueToSend = `${value} días`;
    } else if ( value && value <= 0) {
      valueToSend = `Hace ${Math.abs(value)} días que venció`;
    }
    return valueToSend;
  }
}
