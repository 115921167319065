import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-control-icons',
  templateUrl: './control-icons.component.html',
  styleUrls: ['./control-icons.component.scss']
})
export class ControlIconsComponent {
  @Input() iconsState: string;
  @Output() clickEvent = new EventEmitter<any>();

  /**
   * Emit an event when click
   * @param option - the option: show, delete, edit...
   */
  onClick(option: string): void {
    this.clickEvent.emit(option);
  }
}
