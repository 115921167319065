import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDefaultInput]'
})
export class PreventDefaultInputDirective {

  /**
   * Constructor function
   */
  constructor() {
  }

  /**
   * valuate if the pressed key is 'e'
   * @param evt - KeyboardEvent
   */
  @HostListener('keydown', [ '$event' ]) onKeyDown(evt: KeyboardEvent): void {
    if (evt.key === 'e' || evt.key === 'E') {
      evt.preventDefault();
    }
  }
}
