import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UserData } from './models/userdata';
import { MenuListData } from './navigation-menu/navigation-menu.component';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('languageFocus', { static: true }) languageFocus: ElementRef;

  @Input() inputTitle: string;
  @Input() userData: UserData;

  //#region (GLOBAL VAR)
  public displayMenu = false;
  public displayLanguageSelector = false;
  public languageSelected = 'ES';
  public menuList: MenuListData[] = [
    {
      icon: 'icon-document',
      id: 'MENU.OPTIONS.CERTIFICATIONS',
      url: '/certifications'
    },
    {
      icon: 'icon-warning',
      id: 'MENU.OPTIONS.FDC',
      url: '/fdc'
    },
    {
      icon: 'icon-search',
      id: 'MENU.OPTIONS.ORDER-FINDER',
      url: '/order-finder'
    },
    {
      icon: 'icon-archive',
      id: 'MENU.OPTIONS.DELIVERY-PROOF',
      url: '/delivery-proof'
    }
  ];

  public displayArrow: boolean;
  //#endregion

  /**
   * constructor function
   * @param location - a Location object
   * @param router - a Router object
   * @param authServ - a AuthService object
   */
  constructor(private location: Location, private router: Router, private authServ: AuthService) {
    this.location = location;
    this.router = router;
    this.authServ = authServ;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.displayArrow = window.location.pathname.includes('detail');
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((param: NavigationStart) => {
      this.displayArrow = param.url.includes('detail');
    });

    if (this.authServ.getUserParam('userType') === 'Administrador') {
      this.menuList.push({
        icon: 'icon-user-group',
        id: 'MENU.OPTIONS.USERS',
        url: '/users'
      });
    }
  }

  /**
   * Show/Hide menu
   */
  onToggleMenu(): void {
    this.displayMenu = !this.displayMenu;
  }

  /**
   * Show/ Hide language selector
   */
  onToggleLanguageSelector(): void {
    if (this.displayLanguageSelector) {
      this.focusElement(this.languageFocus);
    }
    this.displayLanguageSelector = !this.displayLanguageSelector;
  }

  /**
   * Focus on a specific element
   * @param elem - the element reference
   */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }

  /**
   * Save on session storage
   * @param key - the key
   * @param value - the key value
   * @returns void
   */
  private saveOnSessionStorage(key: string, value: any): void {
    return sessionStorage.setItem(key, value);
  }

  /**
   * Restart the tab navigation
   * @param event - the keyboard event
   * @param focusId - the id reference where focus
   */
  onRestartTabNavigation(event: KeyboardEvent, focusId: string): void {
    if (event.key === 'Tab') {
      this.focusElement(this[focusId]);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.onToggleLanguageSelector();
    }
  }

  /**
   * Navigate to the previous page
   */
  onNavigateBack(): void {
    this.location.back();
  }

  // onNavigateToMainContent() {
  //   const target: any = document.getElementById('main');

  //   if (target) {
  //     target.focus();
  //   } else {
  //     return;
  //   }
  // }

  // onChangeLanguage(language: string): void {
  //   this.languageSelected = language.toUpperCase();
  //   this.translate.use(language);
  //   this.onToggleLanguageSelector();
  //   this.saveOnSessionStorage('LANG', this.languageSelected);
  // }

  /**
   * Navigate a url
   * @param url - the url
   */
  public onNavigateTo(url: string): void {
    this.router.navigate([url]);
  }
}
