import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ListadoRegistroSalida } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListadoRegistroSalida';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { ListaEncargosEntrada } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListaEncargosEntrada';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';

import { Utils } from '../../../utils/utils';
import { ListadoRegistroEntregaSalida } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListadoRegistroEntregaSalida';

@Component({
  selector: 'app-deliver-list-modal',
  templateUrl: './deliver-list-modal.component.html',
  styleUrls: ['./deliver-list-modal.component.scss']
})
export class DeliverListModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedRows: Array<any>;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  @Output() enablePrintEnvelopeModal = new EventEmitter<{ numeroListado: string; listaSobres: Array<{ idEncargo: string }> }>();
  //#endregion

  //#region PROPS
  private subscriptions: Subscription[] = [];
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param authServ - a AuthService object
   * @param renderer - Renderer2 object
   */
  constructor(private manageReqSOS: ManageRequestSosService, private authServ: AuthService,
              private renderer: Renderer2) {
    this.manageReqSOS = manageReqSOS;
    this.authServ = authServ;
    this.renderer = renderer;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all acrive subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Create the deliver list
   */
  handleClick(): void {
    // Crear listado de entrega
    this.subscriptions.push(
      this.createDeliverList$()
        .pipe(
          finalize(() => {
            this.updateTableData.emit();
          })
        )
        .subscribe((res: ListadoRegistroEntregaSalida) => {
          const { ficheroPDFBase64, numeroListado, puedeGenerarSobres, listaSobres }: {
            ficheroPDFBase64?: string;
            numeroListado?: string;
            puedeGenerarSobres?: boolean;
            listaSobres?: Array<any>;
          } = res;

          Utils.managePdfUtils.downloadPDF(ficheroPDFBase64, numeroListado, this.renderer);
          this.successOperation.emit({ success: true, text: 'DELIVERY-LIST-GENERATED', translate: true, variableText: `: ${numeroListado}` });

          if (puedeGenerarSobres) {
            this.enablePrintEnvelopeModal.emit({ numeroListado, listaSobres });
            return;
          }

          this.closeModalComponent();
        })
    );
  }

  /**
   * launch an event to close the modal window.
   */
  private closeModalComponent(): void {
    this.manageSelfDisplay.emit(false);
  }

  /**
   * Calculate the id user and create a deliver list
   * @returns The deliver list
   */
  private createDeliverList$(): Observable<ListadoRegistroSalida | HttpErrorResponse> {
    const idioma: string = this.authServ.getUserParam('lang');
    const listadoEntrega: Array<ListaEncargosEntrada> = this.selectedRows.map((elem) => ({
      idEncargo: elem.encargoID,
      lugarDeEntrega: elem.lugarEntrega
    }));
    const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');

    return this.manageReqSOS.provCrearPDFListadoEntrega$(idioma, listadoEntrega, USER_ID);
  }
}
