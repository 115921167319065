import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  /**
   * Constructor
   */
  constructor() {}

  /**
   * Valuate if the pressed key is 'e', '.' or ','
   * @param evt - keyboard event
   */
  @HostListener('keydown', ['$event']) onKeyDown(evt: KeyboardEvent): void {
    if (evt.code.includes('KeyE') || evt.key.includes('.') || evt.key.includes(',')) {
      evt.preventDefault();
    }
  }
}
