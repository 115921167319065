import { Component } from '@angular/core';

@Component({
  selector: 'app-navigator-incompatible',
  templateUrl: './navigator-incompatible.component.html',
  styleUrls: [ './navigator-incompatible.component.scss' ]
})
// Show this component when the page is laoded in a incompatible navigator
export class NavigatorIncompatibleComponent {
}
