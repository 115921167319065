import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: [ './help-modal.component.scss' ]
})
export class HelpModalComponent {
  @Input() literals: { HEADER?: string, BODY?: string } = {};
  @Input() display = false;
  @Input() x: number;
  @Input() y: number;
  @Output() hideModal: EventEmitter<any> = new EventEmitter();
  @Output() showModal: EventEmitter<any> = new EventEmitter();
  draggable = false;


  /**
   * Hide the modal
   */
  public hide(): void {
    this.hideModal.emit();
  }

  /**
   * Show the modal
   */
  public show(): void {
    this.showModal.emit();
  }
}
