import { HttpErrorResponse } from '@angular/common/http';
import { Numbers } from '../../../utils/constants';

enum Colors {
  Red = 'FF0707',
  Yellow = 'FFFE07'
}

enum Severity {
  HIGH = 'FF0707',
  ADVICE = 'ffb521',
  INFO = '2160ff'
}

/**
 * Display error in console depending on code status
 *
 * @param error Error from server (HttpErrorResponse)
 * @param config Custom config
 */
export const displayServerErrorInConsole: (error: HttpErrorResponse) => string = (error: HttpErrorResponse): string => {
  // Get code status error (400,401,500...)
  const statusError = error.status || null;
  const message = error.error.mensaje || error.message || null;

  const configConsoleLog: any = (errorCode: string | number = 'Error desconocido', errorMessage?: string, color: string = Colors.Red): void => {
    errorCode = errorCode || null;
    errorMessage = errorMessage || null;
    color = color || null;
    // const messageToShow = 'ERROR ' + errorCode + ': ' + errorMessage;
    // const colorToShow = `color: #${color}`;
    // console.log(`%c ${messageToShow}`, colorToShow);
  };

  configConsoleLog(statusError, message, Colors.Yellow);
  return setCriticy(error);
};

/**
 * @description Display error in console depending on code status
 *
 * @param error - Error from server (HttpErrorResponse)
 * @param severity - Severity
 */
export const displayClientErrorInConsole: (error: string, severity?: 'HIGH' | 'ADVICE' | 'INFO') => void =
(error: string, severity: 'HIGH' | 'ADVICE' | 'INFO' = 'HIGH'): void => {

  let style = `background: #${Severity[severity]}; color: white`;
  if (severity === 'ADVICE') {
    style = `background: #${Severity[severity]}; color: black`;
  }

  // Mantener esto para hacer uso de la variable recibida error mientras siga comentado el console.log
  error = error + '.';
  // console.log(`%c ERROR: ${error} `, style);
};

export const setCriticy: (error: HttpErrorResponse) => string = (error: HttpErrorResponse): string => {

  let criticy: string;
  if (error.status === Numbers.number500 || error.status === Numbers.number503 || error.status === Numbers.number400) {
      criticy = 'alta';
  } else if (error.status === Numbers.number504 || error.status === Numbers.number403 ||
      error.status === Numbers.number401 || error.status === Numbers.number502) {
      criticy = 'media';
  } else {
      criticy = 'baja';
  }
  let errorText: string;
  // const nameApi: string = error.url.replace(environment.urlBase, '');
  let mensajeErr: string;
  if (error.error.mensaje === null) {
      mensajeErr = '';
  } else {
      mensajeErr = '- ' + error.error.mensaje;
  }
  errorText = 'Portal Proveedores; Criticidad: ' + criticy + '; Error: ' + error.status + mensajeErr + '; Api: ' + error.url;

  return errorText;
};
