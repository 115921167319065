import { Injectable } from '@angular/core';

@Injectable()
export class UserService {

  private TEST2: string;

  /**
   * get 'test' word
   * @returns 'test'
   */
  public test(): string {
    return 'test';
  }

  /**
   * set the TEST2 propery with the new value
   * @param value - the new value
   */
  set test2Value(value: string) {
    this.TEST2 = value;
  }

  /**
   * Get the test2 property value
   */
  get test2(): string {
    return this.TEST2;
  }
}
