import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-backdrop-nav',
  templateUrl: './backdrop-nav.component.html',
  styleUrls: [ './backdrop-nav.component.scss' ]
})
export class BackdropNavComponent {
  @Output() clickEvent = new EventEmitter<any>();
  @Output() keyDownEvent = new EventEmitter<any>();

  /**
   * emit an event when click
   */
  onClick(): void {
    this.clickEvent.emit();
  }

  /**
   * Emit an event when press a key
   * @param event - the keyboardEvent
   */
  onKeyDown(event: KeyboardEvent): void {
    this.keyDownEvent.emit(event);
  }
}
