import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen, take } from 'rxjs/operators';
import { AuthService } from '../providers/local/auth/auth.service';
import { Numbers } from '../../utils/constants';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  /**
   * constructor function
   * @param authServ - a AuthService object
   */
  constructor(private authServ: AuthService) {
    this.authServ = authServ;
  }

  /**
   * Attempt to resend the request up to a maximum of two times if an error occurs.
   * @param req - the request
   * @param next - HttpHandler object
   * @returns HttpEvent events observable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen((error) => {
        return error.pipe(mergeMap(this.handleErrors), delay(Numbers.number1000), take(Numbers.number2));
      })
    );
  }

  /**
   * cath the error
   * @param elem - a HttpErrorResponse object
   * @returns a HttpErrorResponse observable
   */
  private handleErrors(elem: HttpErrorResponse): Observable<HttpErrorResponse> {
    const errorsToControl: number[] = [Numbers.number403, Numbers.number502, Numbers.number503];

    const statusNumber: number = elem.status;

    const isAuthError = statusNumber === Numbers.number403;

    if (isAuthError) {
      this.refreshToken();
    }

    let handleError$: Observable<HttpErrorResponse>;
    if (errorsToControl.includes(statusNumber)) {
      handleError$ = of(elem);
    } else {
      handleError$ = throwError(elem);
    }
    return handleError$;
  }

  /**
   * Refresh the token
   */
  private refreshToken(): void {
    const keycloakAuth: Keycloak.KeycloakInstance = this.authServ.keycloakInstance();

    if (keycloakAuth.refreshToken) {
      const twoHoursSeconds = 7200;
      keycloakAuth.updateToken(twoHoursSeconds);
    } else {
      keycloakAuth.login();
    }
  }
}
