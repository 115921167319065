import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../shared/components/loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests: HttpRequest<any>[] = [];

  /**
   * constructor function
   * @param loaderService - a LoaderService object
   */
  constructor(public loaderService: LoaderService) {
    this.loaderService = loaderService;
  }

  /**
   * Interceptor function. Needed in all interceptors
   * @param req - the httpClient request
   * @param next - httpHandler object
   * @returns - the event
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.addActiveRequest(req);

    this.loaderService.displayLoader();

    return next.handle(req).pipe(
      finalize(() => {
        this.removeFinishedRequest(req);

        this.manageLoaderObserver(this.activeRequests);
      })
    );
  }

  /**
   * add the request to the activeRequest array
   * @param req - the request
   */
  private addActiveRequest(req: HttpRequest<any>): void {
    this.activeRequests.push(req);
  }

  /**
   * Remove the request to the activeRequest array
   * @param req - the request
   */
  private removeFinishedRequest(req: HttpRequest<any>): void {
    this.activeRequests = this.activeRequests.filter((activeReq) => activeReq !== req);
  }

  /**
   * hide/ show loader.
   * @param activeRequests - active request array
   */
  private manageLoaderObserver(activeRequests: HttpRequest<any>[]): void {
    if (activeRequests.length === 0) {
      this.loaderService.hideLoader();
    } else {
      this.loaderService.displayLoader();
    }
  }
}
