import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';

import { Table, TableCol } from '../../no-category/table/models/table';

enum FilterActive {
  FirstFilter = 'firstFilter',
  SecondFilter = 'secondFilter'
}

@Component({
  selector: 'app-mobile-filter-menu',
  templateUrl: './mobile-filter-menu.component.html',
  styleUrls: ['./mobile-filter-menu.component.scss']
})
export class MobileFilterMenuComponent implements OnInit {
  //#region PROPS
  @Input() tableData: Table;

  @Output() closeModal = new EventEmitter<any>();
  @Output() updateTable = new EventEmitter<any>();
  //#endregion

  //#region STATE
  public firstFilterItems: string[] = [];
  public secondFilterItems: string[] = [];

  // Manage visualization of filter (col - data)
  public isFilterData = false;
  public tableColSelected: TableCol;

  public displayData: any[] = [];
  public baseColData: any[] = [];

  public colType: string;

  public selectedHeader: string[] = [];
  //#endregion

  /**
   * Constructor function
   * @param filterService - a FilterService object
   */
  constructor(private filterService: FilterService) {
    this.filterService = filterService;
  }

  /**
   * onInit method
   */
  ngOnInit(): void {
    this.markAllCurrentColSelected();
  }

  /**
   * Get the selected columns
   */
  private markAllCurrentColSelected(): void {
    this.firstFilterItems = this.filterService.getColsSelected();
  }

  /**
   *  emit event when click close modal.
   */
  onClose(): void {
    this.closeModal.emit();
  }

  /**
   * Call function toggleFilterVisualization
   */
  onGoBack(): void {
    this.toggleFilterVisualization();
  }

  /**
   * Add filters if there are some marked.
   * @param colSelected - a table column selected to apply the filter
   * @param isCheckActive - determine if there are active filters in mobile version page
   */
  onFirstFilterSelect(colSelected: TableCol, isCheckActive: boolean): void {
    // Store current col selected in STATE
    this.tableColSelected = colSelected;

    const { field }: { field: string} = colSelected;

    if (isCheckActive) {
      this.filterService.addFilter(field, []);
      this.markAllCurrentFiltersSelected(field);

      this.filterDataToDisplay();
      this.storeBaseData();
      this.setColType();
      this.toggleFilterVisualization();
    } else {
      this.filterService.deleteCol(field);
    }
  }

  /**
   * Get an array of data elements selected from col
   * @param currentCol - current col
   */
  private markAllCurrentFiltersSelected(currentCol: string): void {
    this.secondFilterItems = this.filterService.getDataSelectedFromCol(currentCol);
  }

  /**
   * Store BASE DATA to manage it in case of reset filter (use to manage OnSearchData method)
   */
  private storeBaseData(): void {
    this.baseColData = [...this.displayData];
  }

  /**
   * Set initial data of the list, depends on COL selected. Not repeated
   */
  private filterDataToDisplay(): void {
    this.displayData = [...new Set(this.tableData.dataSource.map((elem: any) => elem[this.tableColSelected.field]))];
  }

  /**
   * Set colType to manage columns with different TableData
   */
  private setColType(): void {
    this.colType = this.tableColSelected.colType || null;
  }

  /**
   * Toggle variable isFilterData
   */
  private toggleFilterVisualization(): void {
    this.isFilterData = !this.isFilterData;
  }

  /**
   * Apply the filter value in the selected table col before
   * @param dataSelected - The value to filter
   * @param isCheckActive - DEtermine if this option must be included in the filter
   */
  onSecondFilterSelect(dataSelected: string, isCheckActive: boolean): void {
    if (isCheckActive) {
      this.filterService.addIndividualFilter(this.tableColSelected.field, dataSelected);
    } else {
      this.filterService.removeIndividualFilter(this.tableColSelected.field, dataSelected);
    }
  }

  /**
   * Show the available options based on the written value
   * @param inputData - Written data to display filter options
   */
  onSearchInput(inputData: any): void {
    const upperInputData: string = inputData.value.toLowerCase();

    this.displayData = this.baseColData.filter((elem: string) => {
      return elem.toLowerCase().includes(upperInputData);
    });
  }

  /**
   * Delete the indicated filter
   * @param currentState - The filter state: firstFilter or secordFilter
   */
  onDeleteFilter(currentState: string): void {
    if (currentState === FilterActive.FirstFilter) {
      this.firstFilterItems = [];
      this.secondFilterItems = [];

      this.toggleFilterVisualization();
      this.updateTable.emit(this.tableData);
      this.closeModal.emit();
    } else if (currentState === FilterActive.SecondFilter) {
      this.toggleFilterVisualization();
      this.filterService.deleteCol(this.tableColSelected.field);
      this.secondFilterItems = [];
      this.firstFilterItems = this.filterService.getColsSelected();
    }
  }

  /**
   * Apply the filter.
   */
  onApplyFilter(): void {
    this.closeModal.emit();
    this.updateTable.emit();
  }

  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
