import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class ErrorsHandler implements ErrorHandler {

  /**
   * Constructor function
   * @param injector - a Injector object
   */
  constructor( private injector: Injector) {
    this.injector = injector;
  }

  /**
   * Manage a specific error.
   * @param error - the error to manage
   */
  handleError(error: Error | HttpErrorResponse): void {
    const router: Router = this.injector.get(Router);
    // const user$ = this.injector.get(UsersService);
    // const translate$ = this.injector.get(TranslateService);
    if (error instanceof HttpErrorResponse && !navigator.onLine) {
      // No Internet connection
      // toast$.error(translate$.instant('NETWORK_ERRORS.OFF'), translate$.instant('TOAST.ERROR'), { enableHtml: true });
    } else if (error instanceof HttpErrorResponse && navigator.onLine) {

      // Http Error
      switch (error.status.toString()) {
        case '401':
          // user$.removeUserSession();
          router.navigateByUrl('/auth/login');
          break;
        default:
          // toast$.error(`${error.status} - ${translate$.instant(`NETWORK_ERRORS.${error.status}`)}`,
          //   translate$.instant('TOAST.ERROR'), { enableHtml: true });
          break;
      }
    }

    console.error(error);
  }
}
