import { FormGroup } from '@angular/forms';

/**
 * @description Mark all form controls as dirty to active the validations and be able to show error messages
 * @param form - The form to evaluate
 */
export const markAllInputsAsTouched: (form: FormGroup) => void = (form: FormGroup): void =>  {
  Object.keys(form.controls).forEach((controlName: string) => {
    form.get(controlName).markAsDirty();
  });
};

/**
 * @description Convert server Master data into array of values for Dropdown
 * @param data - Array of data from Master service
 * @returns - Array of values to be handled for Drodpdown (value - label)
 */
export const transformIntoDropdownValues: (data: Array<{ Id: any; Description: any; }>) => Array<{
  value: any;
  label: any;
}> = (data: Array<{ Id: any; Description: any }>): Array<{ value: any; label: any }> => {
  return data.reduce((acc, elem) => {
    return [...acc, { ['value']: String(elem.Id) || '', ['label']: elem.Description || '' }];
  }, []);
};
