import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';

export interface StatusModal {
  display: boolean;
  isSuccess: boolean;
  translateText?: string;
  displayedText?: string;
  variableText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatusModalService {
  public subject: Subject<StatusModal> = new Subject<StatusModal>();
  public statusModalState: Observable<StatusModal> = this.subject.asObservable();

  /**
   * constructor function
   * @param translateService - a TranslateService object
   */
  constructor(private translateService: TranslateService) {
    this.translateService = translateService;
  }

  /**
   * Show a succes message
   * @param text - The text to show
   * @param isFromTranslator - if is from translator
   * @param variableText - a part of message to show
   */
  displaySuccessMessage(text?: string, isFromTranslator?: boolean, variableText?: any): void {
    const displayedText: string = this.getDisplayText(text, isFromTranslator, true, variableText);

    this.subject.next({
      display: true,
      isSuccess: true,
      displayedText
    });
  }

  /**
   * Show an error message
   * @param text - Text to show
   * @param isFromTranslator - if is from translator
   * @param variableText - a part of message to show
   */
  displayErrorMessage(text?: string, isFromTranslator?: boolean, variableText?: string): void {
    const displayedText: string = this.getDisplayText(text, isFromTranslator, false, variableText);

    this.subject.next({
      display: true,
      isSuccess: false,
      displayedText
    });
  }

  /**
   * Build the display text to show
   * @param texto - The message first part to show
   * @param isFromTranslator - if is fromTranslator
   * @param isSuccess - is is a success message
   * @param variableText - a part of message to show
   * @returns the message to show
   */
  private getDisplayText(texto: string, isFromTranslator: boolean, isSuccess: boolean, variableText: string): string {
    let messageToDisplay: string;
    let stop = false;
    let variableSuffix = '';

    if (variableText) {
      variableSuffix = variableText;
    }

    if (!texto) {
      let statusModalMessage: 'STATUS-MODAL.RES' | 'STATUS-MODAL.REJ'  = 'STATUS-MODAL.REJ';
      if (isSuccess) {
        statusModalMessage = 'STATUS-MODAL.RES';
      }
      messageToDisplay = this.translateService.instant(statusModalMessage);
      stop = true;
    }

    if (!stop && isFromTranslator ) {
      let statusModalMessagePrefix: 'STATUS-MODAL.SUCCESS.' | 'STATUS-MODAL.ERROR.' = 'STATUS-MODAL.ERROR.';
      if (isSuccess) {
        statusModalMessagePrefix = 'STATUS-MODAL.SUCCESS.';
      }
      messageToDisplay = this.translateService.instant(statusModalMessagePrefix + texto);
    } else if (!stop && !isFromTranslator) {
      messageToDisplay = texto;
    }

    let textToDisplay: string = messageToDisplay;
    if (!stop) {
      textToDisplay =  messageToDisplay + variableSuffix;
    }
    return textToDisplay;
  }

  /**
   * turn off the status display
   */
  public turnOffStatusDisplay(): void {
    this.subject.next();
  }

}
