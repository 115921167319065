import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StatusModalService } from 'projects/portal-proveedores-front/src/app/shared/components/modals/status-modal/status-modal.service';
import { Utils } from 'projects/portal-proveedores-front/src/app/shared/utils/utils';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { FAQSService } from '../../api/datos-comunes/fAQS.service';
import { IdentidadDigitalSOSService } from '../../api/datos-comunes/identidadDigitalSOS.service';
import { ProveedoresAseguradosService } from '../../api/datos-comunes/proveedoresAsegurados.service';
import { CredencialesService } from '../../api/identidad-digital/credenciales.service';
import { IdentidadDigitalService } from '../../api/identidad-digital/identidadDigital.service';

import { Adjunto } from '../../../models/api/datos-comunes/Adjunto';
import { Adjuntos } from '../../../models/api/datos-comunes/Adjuntos';
import { DevolverIdPregunta } from '../../../models/api/datos-comunes/DevolverIdPregunta';
import { DevolverMensaje } from '../../../models/api/datos-comunes/DevolverMensaje';
import { DevolverNotas } from '../../../models/api/datos-comunes/DevolverNotas';
import { IdentidadActualizar } from '../../../models/api/datos-comunes/IdentidadActualizar';
import { Identidades } from '../../../models/api/datos-comunes/Identidades';
import { IdentidadesSalida } from '../../../models/api/datos-comunes/IdentidadesSalida';
import { PreguntasRespuestas } from '../../../models/api/datos-comunes/PreguntasRespuestas';
import { PreguntasRespuestasI } from '../../../models/api/datos-comunes/PreguntasRespuestasI';
import { PreguntasRespuestasM } from '../../../models/api/datos-comunes/PreguntasRespuestasM';
import { IdentidadDigitalAlta, TipoIdentidadEnum } from '../../../models/api/identidad-digital/IdentidadDigitalAlta';
import { IdentidadDigitalBusqueda } from '../../../models/api/identidad-digital/IdentidadDigitalBusqueda';
import { IdentidadDigitalConsulta } from '../../../models/api/identidad-digital/IdentidadDigitalConsulta';
import { IdentidadDigitalModificacion } from '../../../models/api/identidad-digital/IdentidadDigitalModificacion';
import { IdentidadDigitalParcheo } from '../../../models/api/identidad-digital/IdentidadDigitalParcheo';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';
import { ProveedoresService } from '../../api/proveedores/proveedores.service';

@Injectable({
    providedIn: 'root'
})
export class ManageRequestServiceUtils {

    /**
     * Show the error in console and launch it.
     * @param err - The error
     * @returns The error launched
     */
    public handleBaseErrorFromServer(err: HttpErrorResponse): Observable<HttpErrorResponse> {
        const errorMsg: string = Utils.error.displayServerErrorInConsole(err);
        this.insertarErrorTabla(errorMsg).subscribe();
        if (err.status !== Numbers.number504) {
          this.statusModalServ.displayErrorMessage(err.error.mensaje);
        }
        return throwError(err);
    }

    /**
     * Constructor function
     * @param statusModalServ - a StatusModalService object
     * @param credentServ - a CredencialesService object
     * @param identDigSOSServ - a IdentidadDigitalSOSService object
     * @param provAsegServ - a ProveedoresAseguradosService object
     * @param faqsServ - a FAQSService object
     * @param identDigSLServ - a IdentidadDigitalService object
     */
    constructor(
        public statusModalServ: StatusModalService,
        public credentServ: CredencialesService,
        private identDigSOSServ: IdentidadDigitalSOSService,
        private provServ: ProveedoresService,
        private provAsegServ: ProveedoresAseguradosService,
        private faqsServ: FAQSService,
        private identDigSLServ: IdentidadDigitalService) {
            this.statusModalServ = statusModalServ;
            this.credentServ = credentServ;
            this.identDigSOSServ = identDigSOSServ;
            this.provServ = provServ;
            this.provAsegServ = provAsegServ;
            this.faqsServ = faqsServ;
            this.identDigSLServ = identDigSLServ;
    }

    //#region *** CREDENCIALES ***
    // (C1) Reseteo de userPassword. Se recibe un id polimorfico
    /**
     * User password reset
     * @param identidad identity id. It is usually NIF/NIE
     * @param tipoIdentidad Identity type: 'C','E','M','P','A'
     * @param xRequestId request Id.
     * @returns updated User
     */
    credentResetearPassSL$(identidad: string, tipoIdentidad: TipoIdentidadEnum, xRequestId?: string): Observable<any> {
    return this.credentServ.putReseteoPassword(identidad, tipoIdentidad, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISL6) Modificar atributos particulares de la identidad digital
    /**
     * Upedate digital identity
     * @param identidad identyty id. It is usually NIF
     * @param datosParcheoIdentidadDigital digital identity data
     * @param xRequestId request id.
     * @returns updated identity
     */
    identDigSLModificarAtributosParticularesIdentidadDigital$(identidad: string, datosParcheoIdentidadDigital: IdentidadDigitalParcheo, xRequestId?: string): Observable<any> {
        return this.identDigSLServ.patchIdentidadDigital(identidad, datosParcheoIdentidadDigital, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISL5) Borrado identidad digital
    /**
     * Delete digital identity
     * @param identidad identity Id
     * @param tipoIdentidad identity type: 'C','E','M','P','A'
     * @param xRequestId request id
     * @returns Observable
     */
    identDigSLBorrarIdentidadDigital$(identidad: string, tipoIdentidad: TipoIdentidadEnum, xRequestId?: string): Observable<any> {
    return this.identDigSLServ.deleteIdentidadDigital(identidad, tipoIdentidad, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISL4) Modificación de la identidad digital
    /**
     * Update digital identity
     * @param identidad identity id
     * @param datosIdentidadDigitalActualizar data
     * @param xRequestId request id
     * @returns updated digital identity
     */
    identDigSLModificarIdentidadDigital$(identidad: string, datosIdentidadDigitalActualizar: IdentidadDigitalModificacion, xRequestId?: string): Observable<any> {
        return this.identDigSLServ.putIdentidadDigital(identidad, datosIdentidadDigitalActualizar, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISL3) Consulta de la identidad digital
    /**
     * Get digital identity
     * @param identidad identity id
     * @param tipoIdentidad identity type: 'C','E','M','P','A'
     * @param xRequestId request id
     * @returns Digital identity
     */
    identDigSLConsultarIdentidadDigital$(identidad: string, tipoIdentidad: TipoIdentidadEnum, xRequestId?: string): Observable<IdentidadDigitalConsulta | HttpErrorResponse> {
        return this.identDigSLServ.getIdentidadDigital(identidad, tipoIdentidad, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISL2) Búsqueda identidad digital
    /**
     * Search a digital identity
     * @param datosBusquedaIdentidadDigital data to search
     * @param xRequestId request id
     * @returns digital identity
     */
    identDigSLBuscarIdentidadDigital$(datosBusquedaIdentidadDigital: IdentidadDigitalBusqueda, xRequestId?: string): Observable<Array<IdentidadDigitalConsulta> | HttpErrorResponse> {
        return this.identDigSLServ.postBusquedaIdentidades(datosBusquedaIdentidadDigital, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISL1) Alta identidad digital
    /**
     * Add a digital identity
     * @param datosIdentidadDigital data identity
     * @param xRequestId request id
     * @returns the identity
     */
    identDigSLCrearIdentidadDigital$(datosIdentidadDigital: IdentidadDigitalAlta, xRequestId?: string): Observable<any> {
        return this.identDigSLServ.postIdentidades(datosIdentidadDigital, xRequestId).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 11) Consulta el detalle de un encargo
    /**
     * Get detail order
     * @param identificador - order id
     * @param idProveedor - supplier id
     * @returns - The detail order
     */
    provAsegConsultarDetalleEncargo$(identificador: string, idProveedor: string): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.provAsegServ.proveedoresAseguradosConsultarDetalle(identificador, idProveedor, 'PP').pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 10) Devuelve un texto desencriptado
    /**
     * Decrypt a text
     * @param texto - The text to decrypt
     * @returns The decrypted text
     */
    provAsegDevolverTextoDesencriptado$(texto: string): Observable<string | HttpErrorResponse> {
        return this.provAsegServ.proveedoresAseguradosDesencriptar(texto).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 9) Guarda un justificante de entrega
    /**
     * Save a delivery supporting document
     * @param identificador - reference id
     * @param uploadedFile - File to upload
     * @returns -
     */
    provAsegGuardarJustificanteEntrega$(identificador: string, uploadedFile: Blob): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.provAsegServ.proveedoresAseguradosGuardarJustificanteEntrega(identificador, uploadedFile).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 8) Elimina una nota en un caso
    /**
     * Delete a case note
     * @param idCasoPadre -
     * @param identificador - reference note id
     * @param idNota - note id
     * @param tipoEncargo - order Type
     * @returns The deleted note
     */
    provAsegEliminarNota$(idCasoPadre: string, identificador: string, idNota: string, tipoEncargo?: string): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.provAsegServ.proveedoresAseguradosEliminarNota(idCasoPadre, identificador, idNota, 'PP', tipoEncargo).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 7) Inserta una nota nueva en un caso
    /**
     * Save a note.
     * @param idCasoPadre -
     * @param identificador - id where insert the note
     * @param usuario - user who saves the note
     * @param comentario -  message to save
     * @param tipoEncargo - order type
     * @returns The saved note
     */
    provAsegInsertarNota$(idCasoPadre: string, identificador: string, usuario: string, comentario: string, tipoEncargo?: string): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.provAsegServ
        .proveedoresAseguradosInsertarNota(idCasoPadre, identificador, usuario, comentario, 'PP', tipoEncargo)
        .pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 6) Devuelve el link para poder hacer login
    /**
     * Get the link to login
     * @param identificador - case id
     * @param empresa - company id
     * @param email - Email. Optional to supplier employees
     * @returns The link to login
     */
    provAsegDevolverLinkParaLogin$(identificador: string, empresa?: string, email?: string): Observable<IdentidadesSalida | HttpErrorResponse> {
        return this.provAsegServ.proveedoresAseguradosGenerarLink(identificador, 'PP', empresa, email).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 5) Listado de las notas del identificador
    /**
     * Get the notes list with one id
     * @param identificador case id
     * @param tipoEncargo  order type
     * @returns A id notes list
     */
    provAsegDevolverListadoNotas$(identificador: string, tipoEncargo?: string): Observable<DevolverNotas | HttpErrorResponse> {
        return this.provAsegServ.proveedoresAseguradosRecuperarNota(identificador, 'PP', tipoEncargo).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 4) Devuelve el contenido de un fichero
    /**
     * Get a file content
     * @param identificador - case/reference id
     * @param nombreFichero - File name
     * @param tipoEncargo - order type
     * @returns The file content
     */
    provAsegDevolverContenidoFichero$(identificador: string, nombreFichero: string, tipoEncargo?: string): Observable<Adjunto | HttpErrorResponse> {
        return this.provAsegServ
        .proveedoresAseguradosRecuperarFicherosDocumentacion(identificador, nombreFichero, 'PP', tipoEncargo)
        .pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 3) Elimina un adjunto de un caso, referencia, etc...
    /**
     * Delete a file
     * @param identificador - referece/case id
     * @param nombreFichero - The file name to delete
     * @param tipoEncargo - order type
     * @returns The deleted file
     */
    provAsegEliminarAdjunto$(identificador: string, nombreFichero: string, tipoEncargo?: string): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.provAsegServ
        .proveedoresAseguradosEliminarFicherosDocumentacion(identificador, 'PP', nombreFichero, tipoEncargo)
        .pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 2) Adjunta un fichero a una referencia, caso, etc...
    /**
     * Insert a file
     * @param identificador reference/case id
     * @param uploadedFile The file to upload
     * @param tipoEncargo order type
     * @returns The file
     */
    provAsegAdjuntarFichero$(identificador: string, uploadedFile: Blob, tipoEncargo?: string): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.provAsegServ
        .proveedoresAseguradosAdjuntarDocumentacion(identificador, 'PP', uploadedFile, tipoEncargo)
        .pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (PA 1) Devuelve un listado de ficheros
    /**
     * Get a files list
     * @param identificador - case/reference id
     * @param showError - A flag to show or no an error message
     * @param tipoEncargo - order type
     * @returns The documents list
     */
    provAsegRecuperarListadoFicheros$(identificador: string, showError: boolean, tipoEncargo?: string): Observable<Array<Adjuntos> | HttpErrorResponse> {
        return this.provAsegServ
        .proveedoresAseguradosRecuperarListaFicherosDocumentacion(identificador, 'PP', tipoEncargo)
        .pipe(
            catchError((err: HttpErrorResponse) => {
              if (showError) {
                err.error.mensaje = `La orden ${identificador} no contiene archivos adjuntos`;
              }
              return this.handleBaseErrorFromServer(err);
            }),
          );
    }

    //#region *** IDENTIDAD DIGITAL SOS***
    // (ISOS1) Devuelve los datos de un usuario
    /**
     * Get data user
     * @param hash - A hash string
     * @returns Data user
     */
    identDigSOSDevolverDatosUsuario$(hash: string): Observable<Identidades | HttpErrorResponse> {
        return this.identDigSOSServ.identidadDigitalSOSRecuperarIdentidades('0', hash).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISOS2) Actualiza un usuario en el sistema
    /**
     * Update an user
     * @param identificacion - It is usually DNI
     * @param identidad - Params to create an identity
     * @returns Updated user
     */
    identDigSOSActualizarUsuario$(identificacion: string, identidad: IdentidadActualizar): Observable<DevolverMensaje | HttpErrorResponse> {
        return this.identDigSOSServ.identidadDigitalSOSActualizarIdentidades(identificacion, identidad).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISOS3) Creación de un usuario en el sistema
    /**
     * Create an user
     * @param identidad - Params to create an identity
     * @param identificacion - It is usually be DNI
     * @returns The created user
     */
    identDigSOSCrearUsuario$(identidad: Identidades, identificacion: string): Observable<IdentidadesSalida | HttpErrorResponse> {
        return this.identDigSOSServ.identidadDigitalSOSCrearIdentidades(identidad, identificacion).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    // (ISOS4) Eliminación de un usuario en el sistema
    /**
     * Delte an user
     * @param identificacion It is usually DNI
     * @param tipoDocumento document Type GD,AJT,DJ...
     * @returns The deleted user
     */
    identDigSOSEliminarUsuario$(identificacion: string, tipoDocumento: string): Observable<IdentidadesSalida | HttpErrorResponse> {
        return this.identDigSOSServ.identidadDigitalSOSEliminarIdentidades(identificacion, tipoDocumento).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    //#endregion

    //#region  *** FAQS ***
    // (FQ1) Devuelve un listado de preguntas y respuestas
    /**
     * Get a questions and answers list.
     * @param idAplicacion - Application to consult. GD or FDC
     * @param estado - State: 0 - No active 1 - Active 2 - All
     * @returns The questions and answers list
     */
    faqsDevolverListadoPreguntasRespuestas$(idAplicacion: string, estado: string): Observable<Array<PreguntasRespuestas> | HttpErrorResponse> {
        return this.faqsServ.fAQSConsultarPreguntasRespuestas(idAplicacion, estado).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }
    // (FQ2) Inserta preguntas y respuestas
    /**
     * Create a question and answers
     * @param preguntas The question (with thev answer)
     * @returns THe question
     */
    faqsInsertarPreguntasRespuestas$(preguntas: PreguntasRespuestasI): Observable<DevolverIdPregunta | HttpErrorResponse> {
        return this.faqsServ.fAQSInsertarPreguntasRespuestas(preguntas).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }
    // (FQ3) Actualiza las preguntas y respuestas
    /**
     * Update a question
     * @param idPregunta the id question
     * @param preguntas The updated question (and answer)
     * @returns The update question
     */
    faqsActualizarPreguntasRespuestas$(idPregunta: number, preguntas: PreguntasRespuestasM): Observable<DevolverIdPregunta | HttpErrorResponse> {
        return this.faqsServ.fAQSActualizarPreguntasRespuestas(idPregunta, preguntas).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }
    // (FQ4) Elimina preguntas y respuestas
    /**
     * Delete a question
     * @param idPregunta The id question
     * @returns The deleted question
     */
    faqsEliminarPreguntasRespuesta$(idPregunta: number): Observable<DevolverIdPregunta | HttpErrorResponse> {
        return this.faqsServ.fAQSBorrarPreguntasRespuestas(idPregunta).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }
    //#endregion

    /**
     * Inster Error in errores web table
     * @param errorText error to insert
     */
    public insertarErrorTabla(errorText: string): Observable<any> {
        return this.provServ.logError(errorText);
    }
}
