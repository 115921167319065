import { Component } from '@angular/core';
import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoading: Observable<boolean> = this.loaderService.isLoading$;

  /**
   * Constructor function
   * @param loaderService - a LoaderService object
   */
  constructor(private loaderService: LoaderService) {
    this.loaderService = loaderService;
  }
}
