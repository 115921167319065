// Table interface
export interface Table {
  dataSource: TableDataSource[];
  cols: TableCol[];
  selectable?: TableSelectableType;
  pagination?: boolean;
  rowsPerPage?: number;
}

// Table Data Source interface
export interface TableDataSource {
  [name: string]: string | number;
}

// TableCol interface
export interface TableCol {
  field: string;
  header: string;
  showFilter: boolean;
  class?: string;
  columnOrderInTable?: number;
  columnOrderInCard?: number;
  hideInTable?: boolean;
  hideInCard?: boolean;
  icon?: string;
  order?: TableOrder;
  dateFormat?: string;
  launchSelectRowEvent?: string;
  isBoolean?: boolean;
  colType?: string;
  width?: string;
}

// Enum to show the options to select
export enum TableSelectableType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  NONE = 'none'
}

// Enum to show the order options: Up/Down
export enum TableOrder {
  UP = 'up',
  DOWN = 'down'
}

// Table Status interface
export interface TableStatus {
  rowsToDisplay: number;
  firstElement: number;
  rowsSelected?: any | any[];
}
