import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakInstance } from 'keycloak-js';

interface UserData {
  userName?: string; // Nombre usuario
  userSurname?: string; // Apellidos usuario
  userMail?: string; // Email usuario
  userDocId?: string; // Número documento identidad (NIF, DNI, CIF)
  userIdRequestISOS?: string; // Identificador para peticiones ISOS
  userIdRequestIdentDig?: string; // Identificador para peticiones Identidad Digital
  userCompany?: string; // Compañia (válido para PA)
  lang?: string;
  userType?: 'Administrador' | 'Empleado'; // Tipo de usuario
}

@Injectable()
/**
 * Simple class to manage the app auth
 */
export class AuthService {

  /**
   * constructor function
   * @param httpClient - a HttpClient object
   * @param keyCloakServ - a KeycloakService object
   */
  constructor(protected httpClient: HttpClient, private keyCloakServ: KeycloakService) {
    this.httpClient = httpClient;
    this.keyCloakServ = keyCloakServ;
  }

  private userData: UserData;
  /**
   * Method to manage USER DATA
   */
  public getUserData(): UserData {
    return this.userData;
  }

  /**
   * Method to manage the user param
   * @param param - the param name to get its value
   * @returns the user param
   */
  public getUserParam(param: 'userName' | 'userSurname' | 'userMail' | 'userDocId' | 'userIdRequestISOS' | 'userIdRequestIdentDig' | 'userType' | 'userCompany' | 'lang'): string {
    return this.userData[param] || null;
  }

  /**
   * Set a userData
   * @param newUserData - the new userData
   */
  public setUserData(newUserData: UserData): void {
    this.userData = newUserData;
  }

  /**
   * Choose the new language
   * @param selected - the new language
   */
  public changeLang(selected: string): void {
    this.userData.lang = selected;
  }

  /**
   * Choose the new language
   * @param newLang - The new language
   */
  public changeUserLang(newLang: 'ES' | 'CT'): void {
    this.userData.lang = newLang;
  }

  /**
   * Logout current user session from Keycloak
   */
  public logout(): void {
    sessionStorage.clear();
    this.keyCloakServ.logout();
  }

  /**
   * Get a keycloak instance
   * @returns keycloak instance
   */
  keycloakInstance(): KeycloakInstance {
    return this.keyCloakServ.getKeycloakInstance();
  }
}
