import { Component, Input } from '@angular/core';
import { Adjuntos } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/Adjuntos';

@Component({
  selector: 'app-docu-list-modal',
  templateUrl: './docu-list-modal.component.html',
  styleUrls: ['./docu-list-modal.component.scss']
})
export class DocuListModalComponent {
  //#region PROPS
  @Input() documents: { [key: string]: any };

  /**
   * Constructor function
   */
  constructor() {}

}
