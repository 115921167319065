import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';

@Component({
  selector: 'app-delete-file-modal',
  templateUrl: './delete-file-modal.component.html',
  styleUrls: ['./delete-file-modal.component.scss']
})
export class DeleteFileModalComponent implements OnInit, OnChanges, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: any;
  @Input() selectedDoc: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  private subscriptions: Subscription[] = [];
  private orderId: string;
  public idFile: string;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   */
  constructor(private manageReqSOSUtils: ManageRequestServiceUtils) {
    this.manageReqSOSUtils = manageReqSOSUtils;
  }

  /**
   * OnInit method.
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
  }

  /**
   * OnChanges method. Update the orderId and idFile
   */
  ngOnChanges(): void {
    this.orderId = this.selectedDetail.datosCaso.encargoID;
    this.idFile = this.selectedDoc.nombre;
  }

  /**
   * OnDestroy method.
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Delete the attached file
   */
  public handleClick(): void {
    const isProcesal = (this.selectedDetail && (this.selectedDetail.datosCaso.tipoFDC || '').toLowerCase() === 'procesal') || false;

    let procesal: string = null;
    if (isProcesal) {
      procesal = 'FDC-Procesal';
    }

    this.subscriptions.push(
      this.manageReqSOSUtils
        .provAsegEliminarAdjunto$(this.orderId, this.idFile, procesal)
        .pipe(
          finalize(() => {
            this.manageSelfDisplay.emit(false);
            this.updateTableData.emit();
          })
        )
        .subscribe((res: any) => {
          this.successOperation.emit({ success: true, text: 'FILE-DELETED', translate: true });
        })
    );
  }
}
