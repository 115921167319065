import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { AccesTokenResponse, AppConfig, TokenUrl } from './models/boot.model';

@Injectable()
export class BootService {

  /**
   * constructor function
   * @param http - a HttpClient object
   */
  constructor(private http: HttpClient) {
    this.http = http;
  }

  private appConfig: AppConfig;

  /**
   * Get the token request and set the token results in localStorage
   * @returns - A promise
   */
  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      forkJoin([this.getTokenRequest('comunes'), this.getTokenRequest('identidad')]).subscribe((result: AccesTokenResponse[]) => {
        localStorage.setItem('X-TOKEN-DatosComunes', result[0].access_token);
        localStorage.setItem('X-TOKEN-IdentidadDigital', result[1].access_token);
        resolve();
      });
    });
  }

  /**
   * get the token request
   * @param selectedToken - the selected token
   * @returns -
   */
  getTokenRequest(selectedToken: 'comunes' | 'identidad'): Observable<any> {

    let url: TokenUrl = null;

    if (selectedToken === 'comunes') {
      url = TokenUrl.CommonData;
    } else if ( selectedToken === 'identidad' ) {
      url = TokenUrl.IdentDig;
    } else {}

    if (url) {
      return this.http.get(url);
    } else {
      console.error(`Can't get token from server`);
    }
    /*switch (selectedToken) {
      case 'comunes':
        return this.http.get(TokenUrl.CommonData);
      case 'identidad':
        return this.http.get(TokenUrl.IdentDig);
      default:
        console.error(`Can't get token from server`);
        break;
    }*/
  }

  /**
   * load and store the app config
   * @returns an AppConfig Promise
   */
  loadAndStoreAppConfig(): Promise<AppConfig> {
    return this.http
      .get('/credentials')
      .toPromise()
      .then((res: AppConfig) => {
        this.appConfig = res;
        return res;
      });
  }

  /**
   * get the appConfig
   * @returns The appCongif
   */
  getAppConfig(): AppConfig {
    return this.appConfig;
  }
}
//
