import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusModalService } from 'projects/portal-proveedores-front/src/app/shared/components/modals/status-modal/status-modal.service';
import { Utils } from 'projects/portal-proveedores-front/src/app/shared/utils/utils';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TiposIncidencias } from '../../../models/api/datos-comunes/TiposIncidencias';
import { MaestrosService } from '../../api/datos-comunes/maestros.service';
import { MaestroEstados } from '../../../models/api/datos-comunes/MaestroEstados';
import { MaestroCertFiltrado } from '../../../models/api/datos-comunes/MaestroCertFiltrado';
import { RespuestaServiciosFacturados } from '../../../models/api/datos-comunes/RespuestaServiciosFacturados';
import { ResMaestrosCita } from '../../../models/api/datos-comunes/ResMaestrosCita';
import { ResultadoMaestros } from '../../../models/api/datos-comunes/ResultadoMaestros';
import { ResMaestros } from '../../../models/api/datos-comunes/ResMaestros';
import { MaestroEstadosCertificados } from '../../../models/api/datos-comunes/MaestroEstadosCertificados';
import { ProveedoresService } from '../../api/proveedores/proveedores.service';
// import { LogErrorsService } from '../../api/log-errors/logErrors.service';


@Injectable({
    providedIn: 'root'
})

export class ManageRequestServiceUtilsExtended {

    /**
     * Constructor function
     * @param statusModalServ a StatusModalService object
     * @param maestrosServ a MaestrosService object
     */
    constructor( public statusModalServ: StatusModalService, private maestrosServ: MaestrosService, private provServ: ProveedoresService) {
        this.statusModalServ = statusModalServ;
        this.maestrosServ = maestrosServ;
        this.provServ = provServ;
    }

    /**
     * Show the error in console and launch it.
     * @param err - The error
     * @returns The error launched
     */
    public handleBaseErrorFromServer(err: HttpErrorResponse): Observable<HttpErrorResponse> {
        const errorMsg: string = Utils.error.displayServerErrorInConsole(err);
        this.insertarErrorTabla(errorMsg).subscribe();
        if (err.status !== Numbers.number504) {
          this.statusModalServ.displayErrorMessage(err.error.mensaje);
        }
        return throwError(err);
    }

    /**
     *
     * @param httpRequest - httpRequest
     * @param sessionStorageCode - sessionStorageCode
     * @returns Observable with the sessionStorage or an error
     */
    private cacheRequestResponse(httpRequest: Observable<any>, sessionStorageCode: string): Observable<any | HttpErrorResponse> {
        {
            let requestResponse: Observable<any | HttpErrorResponse>;

            if (sessionStorage.getItem(sessionStorageCode)) {
                requestResponse = of(JSON.parse(sessionStorage.getItem(sessionStorageCode)));
            } else {
                requestResponse = httpRequest.pipe(
                    tap((res) => {
                    sessionStorage.setItem(sessionStorageCode, JSON.stringify(res));
                    }),
                    catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err))
                );
            }

            return requestResponse;
        }
    }

    // <<<<----- COMUNES ----->>>>>
    //#region  *** MAESTROS ***
    // (M1) Muestra los tipos de incidencias
    /**
     * Get the incidence types
     * @returns The incidence types
     */
    maestrosMostrarTiposIncidencias$(): Observable<Array<TiposIncidencias> | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarTiposIncidencias(), 'tiposIncidencias');
    }
    // (M2) Muestra los tipos de estados
    /**
     * Get the state types
     * @returns the state types
     */
    maestrosMostrarEstados$(): Observable<Array<MaestroEstados> | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarEstados(), 'estados');
    }
    // (M3) Muestra los certificados
    /**
     * Get the certificates
     * @returns the certificates
     */
    maestrosMostrarCertificados$(): Observable<MaestroCertFiltrado | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarCertificadosFiltrados(), 'certificados');
    }
    // (M4) Muestra los servicios facturados
    /**
     * Get the billed services
     * @returns the billed services
     */
    maestrosMostrarServiciosFacturados$(): Observable<RespuestaServiciosFacturados | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarServiciosFacturados(), 'serviciosFacturados');
    }
    // (M5) Muestra los resultados de la cita
    /**
     * Get the date results
     * @returns the date results
     */
    maestrosMostrarResultadosCita$(): Observable<Array<ResMaestrosCita> | HttpErrorResponse> {
        return this.cacheRequestResponse(
        this.maestrosServ.maestrosConsultarResultadoCita().pipe(
            map((data: Array<ResMaestrosCita>) => {
            let other: any;
            const dataFiltered: ResMaestrosCita[] = data.filter((elem: any) => {
                if (elem.Id === 'Other') {
                other = elem;
                }

                return elem.Id !== 'Other';
            });
            dataFiltered.push(other);
            return dataFiltered;
            })
        ),
        'resultadosCita'
        );
    }
    // (M6) Muestra los motivos de cancelación
    /**
     * Get the cancelation reasons
     * @returns the cancelation reasons
     */
    maestrosMostrarMotivosCancelacion$(): Observable<ResultadoMaestros | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosConsultarMotivosCancelacion(), 'motivosCancelacion');
    }
    // (M7) Muestra los estados civiles
    /**
     * Get the civil status
     * @returns the civil status
     */
    maestrosMostrarEstadosCiviles$(): Observable<ResMaestros | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosEstadoCivil(), 'estadosCiviles');
    }
    // (M8) Muestra los tipos de personas
    /**
     * Get the person types
     * @returns the person types
     */
    maestrosMostrarTiposPersonas$(): Observable<ResMaestros | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosTipoPersona(), 'tiposPersonas');
    }
    // (M9) Muestra las relaciones
    /**
     * Get the relationships
     * @returns the relationships
     */
    maestrosMostrarRelaciones$(): Observable<ResMaestros | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosRelacion(), 'relaciones');
    }
    // (M10) Muestra los estados de los certificados por idioma
    /**
     * Get the certificate states by language
     * @param idioma ES,CA,EN or FR
     * @returns The certificate states
     */
    maestrosMostrarEstadosCertificados$(idioma: string): Observable<Array<MaestroEstadosCertificados> | HttpErrorResponse> {
        return this.maestrosServ.maestrosConsultarEstadosCertificados(idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }
    // (M11) Muestra los tipos de estados de consulta por idioma
    /**
     * Get the consult state types by language
     * @param idioma ES,CA,EN or FR
     * @returns The consult state types
     */
    maestrosMostrarTiposEstadosConsultas$(idioma: string): Observable<Array<MaestroEstadosCertificados> | HttpErrorResponse> {
        return this.maestrosServ.maestrosConsultarEstadosConsulta(idioma).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }
    // (M12) Muestra las relaciones de fallecidos
    /**
     * Get the deceased relationship
     * @return the deceased relationship
     */
    maestrosMostrarRelacionesFallecidos$(): Observable<ResMaestros | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosRelacionFallecido(), 'relacionesFallecidos');
    }
    // (M13) Muestra las provincias
    /**
     * Get the provinces
     * @returns the provinces
     */
    maestrosMostrarProvincias$(): Observable<ResMaestros | HttpErrorResponse> {
        return this.cacheRequestResponse(this.maestrosServ.maestrosProvincias(), 'provincias');
    }
    // (M14) Muestra las localidades por provincia
    /**
     * Get the province localities
     * @param provincia - The province
     * @returns The localities
     */
    maestrosMostrarLocalidades$(provincia: string): Observable<ResMaestros | HttpErrorResponse> {
        return this.maestrosServ.maestrosLocalidades(provincia).pipe(catchError((err: HttpErrorResponse) => this.handleBaseErrorFromServer(err)));
    }

    //#endregion


  /**
   * Inster Error in errores web table
   * @param errorText error to insert
   */
  public insertarErrorTabla(errorText: string): Observable<any> {
    return this.provServ.logError(errorText);
  }
}
