import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';

import { TableCol, TableDataSource } from '../../no-category/table/models/table';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('filterFocus', { static: false }) filterFocus: ElementRef;

  //#region PROPS
  @Input() isFilterDisplayed: boolean;
  @Input() filterData: TableDataSource[];
  @Input() col: TableCol;

  @Output() closeFilter = new EventEmitter();
  @Output() filterSelected = new EventEmitter();
  //#endregion

  //#region STATE
  public displayData: any[] = [];
  public baseColData: any[] = [];
  public inputText = '';

  // Store elements selected from filter
  public elementsSelected: string[] = [];
  public colType: string;
  //#endregion

  /**
   * constructor function
   * @param filterServ - a FilterService object
   */
  constructor(private filterServ: FilterService) {
    this.filterServ = filterServ;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.filterDataToDisplay();
    this.storeBaseData();
    this.setColType();
    this.checkElementsSelected();
  }

  /**
   * Set initial data of the list
   */
  private filterDataToDisplay(): void {
    // Set initial data of the list, depends on COL selected. Not repeated
    this.displayData = [...new Set(this.filterData.map((elem) => elem[this.col.field]))];
  }

  /**
   * Store BASE DATA to manage it in case of reset filter
   */
  private storeBaseData(): void {
    this.baseColData = [...this.displayData];
  }

  /**
   * Set colType to manage columns with different TableData
   */
  private setColType(): void {
    this.colType = this.col.colType || null;
  }

  /**
   * Check selected elements on current filter
   */
  private checkElementsSelected(): void {
    this.elementsSelected = this.filterServ.getDataSelectedFromCol(this.col.field);
  }

  /**
   * AfterViewInit method.
   */
  ngAfterViewInit(): void {
    this.filterFocus.nativeElement.focus();
  }

  /**
   * Show the available options based on the written value
   * @param inputData - Written data to display filter options
   */
  onSearchData(inputData: string): void {
    const upperInputData: string = inputData.toLowerCase();
    const NO_ACCENTS_DATA: string = upperInputData.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    this.displayData = this.baseColData.filter((elem: string) => elem.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(NO_ACCENTS_DATA));
  }

  /**
   * emit a event when close the filter
   */
  onClose(): void {
    this.closeFilter.emit(null);
  }

  /**
   * add or remove filters based on the state.
   * @param state - THe state to decide if remove or add filters
   */
  onFilter(state: string | string[]): void {
    const filterServ: FilterService = this.filterServ;
    let dataToEmit: TableDataSource[];

    if (state === 'reset' || state.length === 0) {
      filterServ.deleteCol(this.col.field);
      dataToEmit = filterServ.getDataFiltered();
    } else {
      filterServ.addFilter(this.col.field, state as string[]);
      dataToEmit = filterServ.getDataFiltered(this.filterData);
    }

    this.closeFilter.emit(state);
    this.filterSelected.emit(dataToEmit);
  }

  /**
   * Focus on an element or display/show the menu
   * @param event - the event generated by keyboard
   */
  onRestartTabNavigation(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.focusElement(this.filterFocus);
    } else if (event.key === ' ' || event.key === 'Enter') {
      this.onClose();
    }
  }

  /**
   * Focus on a specific element
   * @param elem - the reference where focus on
   */
  private focusElement(elem: ElementRef): void {
    elem.nativeElement.focus();
  }
}
