import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { AuthService } from './core/providers/local/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /**
   * constructor function
   * @param angulartics2GoogleTagManager - a Angulartics2GoogleTagManager object
   * @param translateServ - a TranslateService object
   * @param authServ - a AuthService object
   */
  constructor(angulartics2GoogleTagManager: Angulartics2GoogleTagManager, private translateServ: TranslateService, private authServ: AuthService) {
    this.translateServ = translateServ;
    this.authServ = authServ;
    angulartics2GoogleTagManager.startTracking();
  }

  /**
   * OnInit method.
   */
  ngOnInit(): void {
    this.consentCookies();

    this.translateServ.onLangChange.subscribe((translateData) => {
      const { lang }: any = translateData;
      this.authServ.changeUserLang(lang.toUpperCase());
    });
  }

  /**
   * get the cookies
   */
  private consentCookies(): void {
    this.translateServ.get('COOKIES').subscribe((cookieText) => {
      this.setCookiesMessage(cookieText);
    });
  }

  /**
   * set a cookie message
   * @param cookietext - the message
   */
  private setCookiesMessage(cookietext: any): void {
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#28334a'
        },
        button: {
          background: '#fff',
          text: '#28334a'
        }
      },
      theme: 'classic',
      type: 'opt-out',

      content: {
        header: cookietext['COOKIE-HEADER'],
        message: cookietext['COOKIE-MESSAGE'],
        dismiss: cookietext['COOKIE-DISMISS'],
        allow: cookietext['COOKIE-ALLOW'],
        deny: cookietext['COOKIE-DENY'],
        link: cookietext['COOKIE-LINK'],
        href: 'https://web.irisglobal.es/docs/Politica_de_cookies_SOS.pdf',
        close: '&#x274c;',
        target: '_blank'
      }
    });
  }
}
