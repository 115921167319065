// Set the first character to upperCase of every words
export const capitalizeFirstLetterOfEveryWord: (text: string) => string = (text: string): string =>
  text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// Delete underscore and set the first character to upperCase
export const deleteUnderscoreAndCapitalize: (text: string) => string = (text: string): string =>
  text
    .toLowerCase()
    .replace(/_\w/g, (x) => ` ${x[1]}`)
    .replace(/^\w/, (x) => x.toUpperCase());
