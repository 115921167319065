import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListadoRegistroEntregaSalida } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListadoRegistroEntregaSalida';
import { ListadoRegistroSalida } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListadoRegistroSalida';
import { ListaEncargosEntrada } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/ListaEncargosEntrada';
import { AuthService } from 'projects/portal-proveedores-front/src/app/core/providers/local/auth/auth.service';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { Observable } from 'rxjs';
import { Utils } from '../../../utils/utils';
import { StatusModalService } from '../status-modal/status-modal.service';

@Component({
  selector: 'app-envelope-modal',
  templateUrl: './envelope-modal.component.html',
  styleUrls: ['./envelope-modal.component.scss']
})
export class EnvelopeModalComponent implements OnInit {
  @Output() clickEmitter = new EventEmitter<any>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();

  public envelopeForm: FormGroup = this.fb.group({
    certs: this.fb.array([])
  });
  public certsForm: FormGroup = this.fb.group({
    code: ['GD-', Validators.required]
  });

  /**
   * constructor function
   * @param fb - a FormBuilder object
   * @param manageReqSOS - a ManageRequestSosService object
   * @param statusModal - a StatusModalService object
   * @param authServ - a AuthService object
   * @param renderer - Renderer2 object
   */
  constructor(
    private fb: FormBuilder,
    readonly manageReqSOS: ManageRequestSosService,
    readonly statusModal: StatusModalService,
    readonly authServ: AuthService,
    readonly renderer: Renderer2
  ) {
    this.fb = fb;
    this.manageReqSOS = manageReqSOS;
    this.statusModal = statusModal;
    this.authServ = authServ;
    this.renderer = renderer;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
  }

  /**
   * Get the field certs as a FormArray object
   * @returns - The envelopeForm certs field
   */
  get certs(): FormArray {
    return this.envelopeForm.controls.certs as FormArray;
  }

  /**
   * Get the value of the field certs
   */
  get certsArray(): string[] {
    return this.envelopeForm.controls.certs.value as Array<string>;
  }

  /**
   * add a cert to the FormArray certs
   */
  public addCert(): void {
    const certForm: FormGroup = this.fb.group({
      id: [this.certsForm.controls.code.value, Validators.required]
    });
    this.certs.push(certForm);
    this.certsForm.controls.code.reset('GD-');
  }

  /**
   * remove a certificate
   * @param certIndex - id certificate
   */
  deleteCert(certIndex: number): void {
    this.certs.removeAt(certIndex);
  }

  /**
   * check if there are some certs to add and generate pdf document
   */
  handleClick(): void {
    if (this.certsArray.length > 0) {
      const USER_ID: string = this.authServ.getUserParam('userIdRequestISOS');
      const listaSobres: Array<any> = this.certsArray.map((elem: any) => ({
        idExpediente: elem.id
      }));
      const currentDate = new Date();
      const today: number = currentDate.getDate();
      const month: number = currentDate.getMonth();
      const year: number = currentDate.getFullYear();
      const hour: number = currentDate.getHours();
      const minutes: number = currentDate.getMinutes();
      const numeroListado = 'E' + today + month + year + hour + minutes;

      this.manageReqSOS.provImprimirSobresListadoEntrega$(numeroListado, listaSobres, USER_ID).subscribe({
        next: (res1) => {
          Utils.managePdfUtils.downloadPDF(res1.ficheroPDFBase64, `sobres-${numeroListado}`, this.renderer);
          this.statusModal.displaySuccessMessage(res1.mensaje);
          this.closeModalComponent();
          while (this.certsArray.length !== 0) {
            this.certs.removeAt(0);
          }
        }
      });
    } else {
      this.statusModal.displayErrorMessage('ENVELOPE-ERROR', true);
    }
  }

  /**
   * Emit an event that close the modal
   */
  private closeModalComponent(): void {
    this.manageSelfDisplay.emit(false);
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
