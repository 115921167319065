import { Renderer2 } from '@angular/core';
import { Numbers } from '../../../utils/constants';

/**
 * @description Download document into desktop
 * @param base64 Document in base64 format
 * @param documentName Document Name
 */
export const downloadPDF: (base64: string, documentName: string, renderer: Renderer2) => void =
(base64: string, documentName: string, renderer: Renderer2): void => {
  const documentType: string = getDocumentType(base64);

  const linkSource = 'data:' + documentType + ';base64,' + base64;
  const downloadLink: HTMLAnchorElement = renderer.createElement('a');
  const fileName: string = documentName;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * @description Display PDF from base64 in a new tab
 * @param base64 Document in base64 format
 */
export const showPDF: (base64: string, renderer: Renderer2) => void =
(base64: string, renderer: Renderer2): void => {
  const byteArray = new Uint8Array(
    atob(base64)
      .split('')
      .map((char) => char.charCodeAt(0))
  );

  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url: string = window.URL.createObjectURL(blob);
  const downloadLink: HTMLAnchorElement = renderer.createElement('a');
  downloadLink.target = '_blank';
  downloadLink.href = url;

  downloadLink.click();
};

const getDocumentType: (base64: string) => string = (base64: string): string => {
  const fileType: { [key: string]: string } = {
    25504446: 'application/pdf',
    '504B0304': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    D0CF11E0: 'application/msword',
    FFD8FFDB: 'image/jpeg',
    FFD8FFE0: 'image/jpeg'
  };

  return fileType[getMagicNumber(base64)];
};

const getMagicNumber: (base64: string) => string = (base64: string): string => {
  const decodeBase64: string = atob(base64);

  const byteArray: string[] = decodeBase64
    .slice(Numbers.number0, Numbers.number4)
    .split('')
    .map((elem) => elem.charCodeAt(Numbers.number0).toString(Numbers.number16));

  const magicNumber: string = byteArray
    .map((elem) => {
      let result: string = elem;
      if (elem.length !== Numbers.number2) {
        result = Numbers.number0 + elem;
      }
      return result;
    })
    .join('')
    .toUpperCase();

  return magicNumber;
};
