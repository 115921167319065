import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: [ './info-modal.component.scss' ]
})
export class InfoModalComponent {
  @Input() infoModalShown: boolean;

  public draggable = false;
  public mask = true;

  /**
   * Constructor function
   */
  constructor() { }

  /**
   * Toggle the basic modal
   */
  public showBasicModal(): void {
    this.infoModalShown = !this.infoModalShown;
  }
}
