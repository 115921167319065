import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { concat, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Incidencias } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/Incidencias';
import { DevolverAvances } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvances';
import { UploadFileComponent } from 'projects/portal-proveedores-front/src/app/shared/components/no-category/upload-file/upload-file.component';
import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { TiposIncidencias } from 'projects/portal-proveedores-front/src/app/core/models/api/datos-comunes/TiposIncidencias';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterService } from 'projects/portal-proveedores-front/src/app/core/providers/local/filter/filter.service';
import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';
import { ManageRequestServiceUtilsExtended } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils-extended';

@Component({
  selector: 'app-change-incidence-modal',
  templateUrl: './change-incidence-modal.component.html',
  styleUrls: ['./change-incidence-modal.component.scss']
})
export class ChangeIncidenceModalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('uploadFileComp', { static: true }) uploadFileComp: UploadFileComponent;

  //#region PROPS
  @Input() selectedRow: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  @Output() updateTableData = new EventEmitter<any>();
  //#endregion

  //#region STATE
  // Manage subscriptions
  private encargoID: string;
  private subscriptions: Subscription[] = [];
  public incidenceList: TiposIncidencias[];
  public incidenceForm = new FormGroup({
    incidenceType: new FormControl(null, Validators.required),
    incidenceText: new FormControl('')
  });
  public fileStored: File;
  public disableSendData: boolean;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   * @param manageReqSOSUtilsExtended - a ManageRequestServiceUtilsExtended object
   * @param filterService - a FilterService object
   */
  constructor(
    private manageReqSOS: ManageRequestSosService,
    private manageReqSOSUtils: ManageRequestServiceUtils,
    private manageReqSOSUtilsExtended: ManageRequestServiceUtilsExtended,
    private filterService: FilterService,
  ) {
    this.manageReqSOS = manageReqSOS;
    this.manageReqSOSUtils = manageReqSOSUtils;
    this.manageReqSOSUtilsExtended = manageReqSOSUtilsExtended;
    this.filterService = filterService;
  }

  /**
   * onInit method
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.manageReqSOSUtilsExtended.maestrosMostrarTiposIncidencias$().subscribe((res: Array<TiposIncidencias>) => {
        this.incidenceList = res;
      })
    );
    this.clickEmitter.emit(this.handleClick.bind(this));
  }

  /**
   * onChanges method
   */
  ngOnChanges(): void {
    this.encargoID = String((this.selectedRow[0] || []).encargoID || this.selectedRow.datosCaso.encargoID);
    this.fileStored = null;
    this.incidenceForm.reset();
    this.uploadFileComp.fileSelectedName = null;
  }

  /**
   * onDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all the active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * check if incidence form is valid and there is file store. Emit updateTableData event
   */
  private handleClick(): void {
    if (this.incidenceForm.valid) {
      this.disableSendData = false;

      if (this.fileStored) {
        const source1$: Observable<DevolverAvances | HttpErrorResponse> = this.uploadService$();
        const source2$: Observable<DevolverAvances | HttpErrorResponse> = this.sendIncidence$();
        this.subscriptions.push(
          concat(source1$, source2$)
            .pipe(
              finalize(() => {
                this.manageSelfDisplay.emit(false);
                this.updateTableData.emit();
                this.filterService.clearSavedSelectedRows();
              })
            )
            .subscribe((res: any) => {
              this.successOperation.emit({ success: true, text: 'TO-INCIDENCES-WITH-ATTACHMENT', translate: true });
              this.filterService.clearSavedSelectedRows();
            })
        );
      } else {
        const source2$: Observable<DevolverAvances | HttpErrorResponse> = this.sendIncidence$();
        this.subscriptions.push(
          source2$
            .pipe(
              finalize(() => {
                this.manageSelfDisplay.emit(false);
                this.updateTableData.emit();
                this.filterService.clearSavedSelectedRows();
              })
            )
            .subscribe((res: any) => {
              this.successOperation.emit({ success: true, text: 'TO-INCIDENCES-WITHOUT-ATTACHMENT', translate: true });
              this.filterService.clearSavedSelectedRows();
            })
        );
      }
    } else if (!this.incidenceForm.valid) {
      this.disableSendData = true;
    } else {
      return;
    }
  }

  /**
   * Store a file when upload it
   * @param event the file
   */
  public storeFile(event: any): void {
    this.fileStored = event;
  }

  /**
   * Call a function to upload a file
   * @returns -
   */
  private uploadService$(): Observable<DevolverAvances | HttpErrorResponse> {
    const identificador: any = this.encargoID;
    const uploadFile: File = this.fileStored;

    return this.manageReqSOSUtils.provAsegAdjuntarFichero$(identificador, uploadFile);
  }

  /**
   * Send a incidence
   * @returns the results
   */
  private sendIncidence$(): Observable<DevolverAvances | HttpErrorResponse> {
    const reqData: Incidencias = this.createIncidence();
    return this.manageReqSOS.provCrearIncidencia$(reqData);
  }

  /**
   * Create an incidence
   * @returns The created incidence
   */
  private createIncidence(): Incidencias {
    let incidenceType = '';
    let observations = '';

    if (this.incidenceForm.get('incidenceType').value) {
      incidenceType = this.incidenceForm.get('incidenceType').value.idTipoIncidencia;
    }

    if (this.incidenceForm.get('incidenceText').value) {
      observations = this.incidenceForm.get('incidenceText').value;
    }
    const sendData: Incidencias = {
      identificador: this.encargoID,
      idTipoIncidencia: incidenceType,
      observaciones: observations
    };

    return sendData;
  }
}
