import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { TableStatus } from '../table/models/table';
import { Utils } from '../../../utils/utils';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() dataElements: number;

  @Output() tableUpdate = new EventEmitter<TableStatus>();

  public tableStatus: TableStatus = {
    rowsToDisplay: Numbers.number20,
    firstElement: Numbers.number0
  };

  public dropDownValues: { label: number; value: number }[]  = [
    { label: Numbers.number10, value: Numbers.number10 },
    { label: Numbers.number20, value: Numbers.number20 },
    { label: Numbers.number50, value: Numbers.number50 },
    { label: Numbers.number100, value: Numbers.number100 }
  ];

  public positionSelected: number;
  public totalPages: number;
  public range: Array<number> = [];

  /**
   * constructor function
   */
  constructor(private renderer: Renderer2) {
    this.renderer = renderer;
  }

  /**
   * onInit method - Generate the paginator and update the table
   */
  ngOnInit(): void {
    this.positionSelected = 1;
    this.updateTable();
    this.generatePaginator();
  }

  /**
   * onChanges method - Generate the paginator and update the table
   */
  ngOnChanges(): void {
    this.positionSelected = 1;
    this.generatePaginator();
    this.updateTable();
  }

  /**
   * Calculate the pages based on the number of rows to display
   */
  generatePaginator(): void {
    this.range = [];
    this.totalPages = Math.ceil(this.dataElements / this.tableStatus.rowsToDisplay);

    for (let i = 1; i < this.totalPages; i++) {
      this.range.push(i);
    }
  }

  /**
   * Change the paginator page
   * @param dir - direction: advance/descend
   */
  onChangeSelectPosition(dir: string): void {
    if (dir === 'advance' && this.positionSelected < this.totalPages) {
      this.positionSelected++;
      this.updateTable();
    } else if (dir === 'descend' && this.positionSelected > 1) {
      this.positionSelected--;
      this.updateTable();
    }
  }

  /**
   * Select a paginator position
   * @param n - the position
   */
  onSelectPosition(n: number): void {
    this.positionSelected = n;
    this.updateTable();
  }

  /**
   * change the number of elements to display by page
   * @param event the new number of elements
   */
  onChangeRowsToDisplay(event: any): void {
    this.positionSelected = 1;
    this.generatePaginator();
    this.updateTable();

    Utils.screenReader.speak(`Se están mostrando ${event.value} elementos`, this.renderer);
  }

  /**
   * Show the table and paginator with the selected number of elements by page
   */
  private updateTable(): void {
    const rowsPerPage: number = this.tableStatus.rowsToDisplay;
    this.tableStatus.firstElement = this.positionSelected * rowsPerPage - rowsPerPage;
    this.tableUpdate.emit(this.tableStatus);
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
