import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ManageRequestSosService } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service';
import { DevolverAvances } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DevolverAvances';
import { DatosContacto } from 'projects/portal-proveedores-front/src/app/core/models/api/proveedores/DatosContacto';
import { HttpErrorResponse } from '@angular/common/http';
import { Utils } from '../../../utils/utils';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';

// import { google } from 'google-maps';

@Component({
  selector: 'app-update-user-modal',
  templateUrl: './update-user-modal.component.html',
  styleUrls: ['./update-user-modal.component.scss']
})
export class UpdateUserModalComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('map', { static: false }) map: ElementRef;
  @ViewChild('address', { static: false }) address: ElementRef;

  //#region PROPS
  @Input() selectedDetail: any;
  @Input() inputUserData: any;

  @Input() userDataSelected: string;
  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region STATE
  // Manage subscriptions
  private subscriptions: Subscription[] = [];
  // Manage UserForm
  public userForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.pattern(Utils.variables.NAME_REGEX)]),
    surname1: new FormControl('', [Validators.pattern(Utils.variables.NAME_REGEX)]),
    surname2: new FormControl('', [Validators.pattern(Utils.variables.NAME_REGEX)]),
    phone1: new FormControl('', [Validators.pattern('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{0,9}$')]),
    phone2: new FormControl('', [Validators.pattern('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{0,9}$')]),
    mail: new FormControl('', [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')]),
    dirName: new FormControl(),
    dirNum: new FormControl(),
    dirFloor: new FormControl(),
    dirLocality: new FormControl(),
    dirProvince: new FormControl(),
    dirPostalCode: new FormControl(),
    dirCountry: new FormControl(),
    address: new FormControl()
  });
  private orderId: string;
  public invalidAddress: boolean;
  public autocomplete: google.maps.places.Autocomplete;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOS - a ManageRequestSosService object
   */
  constructor(private manageReqSOS: ManageRequestSosService) {
    this.manageReqSOS = manageReqSOS;
  }

  /**
   * onInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;

    this.manageInputUserData();
  }

  /**
   * AfterViewInit method.
   */
  ngAfterViewInit(): void {
    if (this.userDataSelected === 'userAddress') {
      this.getLocation(this.inputUserData.direccion)
        .then((geo) => {
          this.updateMap(geo.lat, geo.lng, geo.mark);
        })
        .catch((baseGeo) => {
          this.updateMap(baseGeo.lat, baseGeo.lng, baseGeo.mark);
        });

      this.initAutocomplete();
    }
  }

  /**
   * OnChanges method.
   */
  ngOnChanges(): void {
    this.manageInputUserData();
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all actibe subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Fill the userForm value with the recived values
   */
  private manageInputUserData(): void {
    this.userForm.controls.name.setValue(this.inputUserData.nombre);
    this.userForm.controls.surname1.setValue(this.inputUserData.apellido1);
    this.userForm.controls.surname2.setValue(this.inputUserData.apellido2);
    this.userForm.controls.phone1.setValue(this.inputUserData.telefono1);
    this.userForm.controls.phone2.setValue(this.inputUserData.telefono2);
    this.userForm.controls.mail.setValue(this.inputUserData.email);
    this.userForm.controls.dirName.setValue(this.inputUserData.direccion.nombreVia);
    this.userForm.controls.dirNum.setValue(this.inputUserData.direccion.numVia);
    this.userForm.controls.dirFloor.setValue(this.inputUserData.direccion.pisoLetra);
    this.userForm.controls.dirLocality.setValue(this.inputUserData.direccion.localidad);
    this.userForm.controls.dirProvince.setValue(this.inputUserData.direccion.provincia);
    this.userForm.controls.dirPostalCode.setValue(this.inputUserData.direccion.codigoPostal);
    this.userForm.controls.dirCountry.setValue(this.inputUserData.direccion.pais);
  }

  /**
   * Called when click the button modal.
   */
  public handleClick(): void {
    this.subscriptions.push(
      this.updateUser$()
        .pipe(
          finalize(() => {
            this.manageSelfDisplay.emit(false);
            this.updateTableData.emit();
          })
        )
        .subscribe((res: any) => {
          this.successOperation.emit({ success: true, text: 'USER-DATA-UPDATED', translate: true });
        })
    );
  }

  /**
   * Update the user
   * @returns a DevolverAvances object
   */
  private updateUser$(): Observable<DevolverAvances | HttpErrorResponse> {
    const idOrder: string = this.orderId;
    const bodytoSend: DatosContacto = {};

    const isProcesal = (this.selectedDetail && (this.selectedDetail.datosCaso.tipoFDC || '').toLowerCase() === 'procesal') || false;

    const address: any = {
      nombreVia: this.userForm.controls.dirName.value,
      numVia: this.userForm.controls.dirNum.value,
      pisoLetra: this.userForm.controls.dirFloor.value,
      codigoPostal: this.userForm.controls.dirPostalCode.value,
      localidad: this.userForm.controls.dirLocality.value,
      provincia: this.userForm.controls.dirProvince.value,
      pais: this.userForm.controls.dirCountry.value
    };

    bodytoSend.nombre = this.userForm.controls.name.value;
    bodytoSend.apellido1 = this.userForm.controls.surname1.value;
    bodytoSend.apellido2 = this.userForm.controls.surname2.value;
    bodytoSend.telefonoMovil = this.userForm.controls.phone1.value;
    bodytoSend.telefonoFijo = this.userForm.controls.phone2.value;
    bodytoSend.email = this.userForm.controls.mail.value;

    bodytoSend.direccion = address;
    let procesal: string = null;
    if (isProcesal) {
      procesal = 'FDC-Procesal';
    }
    return this.manageReqSOS.provActualizarContacto$(idOrder, bodytoSend, procesal);
  }

  /**
   * Paint a map and a possible marker in a determinate point
   * @param lat - latitude
   * @param lng - longitude
   * @param showMarker - indicate if show a marker.
   */
  private updateMap(lat: number, lng: number, showMarker?: boolean): void {
    const coordinates = new google.maps.LatLng(lat, lng);
    let zoomToApply: number = Numbers.number5;
    if (showMarker) {
      zoomToApply = Numbers.number17;
    }
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: zoomToApply
    };
    const map = new google.maps.Map(this.map.nativeElement, mapOptions);
    if (showMarker) {
      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(Numbers.number0, Numbers.numberN29)
      });
      marker.setVisible(true);
      marker.setPosition({ lat, lng });
    }
  }

  /**
   * Create an autocomplete object and, when the user select an address, populate the address field in the form
   */
  public initAutocomplete(): void {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    this.autocomplete = new google.maps.places.Autocomplete(this.address.nativeElement as HTMLInputElement, { types: ['geocode'] });

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this));
  }

  /**
   * Get the place details from the autocomplete object and fill the field in the form
   */
  public fillInAddress(): void {
    const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

    this.updateMap(place.geometry.location.lat(), place.geometry.location.lng(), true);
    const componentForm: any = {
      street_number: 'dirNum',
      route: 'dirName',
      locality: 'dirLocality',
      administrative_area_level_1: 'dirProvince',
      country: 'dirCountry',
      postal_code: 'dirPostalCode'
    };

    for (const component in componentForm) {
      if (componentForm.hasOwnProperty(component)) {
        const field: AbstractControl = this.userForm.controls[componentForm[component]];
        field.setValue('');
      }
    }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    place.address_components.forEach((comp) => {
      const addressType: string = comp.types[0];
      if (componentForm[addressType]) {
        this.userForm.controls[componentForm[addressType]].setValue(comp.long_name);
      }
    });
  }

  /**
   * Get an address location
   * @param address - the address
   * @returns The address location
   */
  private async getLocation(address: any): Promise<{ lat: number; lng: number; mark: boolean }> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address.nombreVia }, (results, status) => {
        if (status === 'OK') {
          resolve({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            mark: true
          });
        } else {
          // If address can't be checked, set base address as center of Spain
          reject({
            lat: Numbers.number38_831,
            lng: Numbers.numberN2_0509,
            mark: false
          });
        }
      });
    });
  }
}
