import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusModal, StatusModalService } from './status-modal.service';
import { Numbers } from 'projects/portal-proveedores-front/src/app/utils/constants';


interface StatusModalId {
  identif: number;
  selfDisplay: boolean;
  isSuccess: boolean;
  translateText?: string;
  displayedText?: string;
  variableText?: string;
}

@Component({
  selector: 'app-status-modal',
  templateUrl: './status-modal.component.html',
  styleUrls: ['./status-modal.component.scss']
})
export class StatusModalComponent implements OnInit {
  public observable: Observable<any> = this.statusModalServ.statusModalState;

  public statusModalArray: any[] = [];

  public selfDisplay: boolean;
  public isSuccess: boolean;
  public translateText: string;
  public variableText: string;

  /**
   * constructor function
   * @param statusModalServ - A StatusModalService object
   */
  constructor(private statusModalServ: StatusModalService) {
    this.statusModalServ = statusModalServ;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.observable.subscribe((res: StatusModal) => {
      if (res) {
        if (this.statusModalArray.length === Numbers.number2) {
          this.statusModalArray.shift();
        }

        this.statusModalArray.push(this.createNewStatusModal(res, Math.floor(Math.random() * Numbers.number1000)));
      } else {
        this.onTurnOff();
      }
    });
  }

  /**
   * Create a status modal
   * @param statusModal - the status modal to generate
   * @param id - a random number bettwen 0 and 1000
   * @returns - the status modal created with id
   */
  private createNewStatusModal(statusModal: StatusModal, id: number): StatusModalId {
    return {
      identif: id,
      selfDisplay: statusModal.display,
      isSuccess: statusModal.isSuccess,
      translateText: statusModal.displayedText,
      variableText: statusModal.variableText
    };
  }

  /**
   * Turn off all status modals
   */
  public onTurnOff(): void {
    this.statusModalArray = [];
  }

  /**
   * Turn off a status modal
   * @param id - status modal id
   */
  public onTurnOffSpecificModal(id: number): void {
    this.statusModalArray = this.statusModalArray.filter((statusModal) => statusModal.id !== id);
  }
  /**
   * @param item item
   * @returns id
   */
  trackByFn(item: any): number {
    return item.id;
  }
}
