import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './modules/layout/layout.module';

/**
 * Main module.
 * Imports all the needed modules when the app start.
 * Declarate the main Angular component.
 */
@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    LayoutModule
  ],
  providers: [],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
