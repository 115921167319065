import { Renderer2 } from '@angular/core';
import { Numbers } from '../../../utils/constants';

/**
 * @description Create a div element. Set the text and, after a short delay, delete it.
 * @param text - The text to insert
 * @param priority - The priority
 */
export const speak: (text: string, renderer: Renderer2, priority?: string) => void =
(text: string, renderer: Renderer2, priority?: string): void => {
  const el: HTMLDivElement = renderer.createElement('div');
  const idSpeak = 'speak-' + Date.now();
  renderer.setAttribute(el, 'id', idSpeak);
  renderer.setAttribute(el, 'aria-live', priority || 'polite');
  renderer.addClass(el, 'sr-only');
  renderer.appendChild(document.body, el);

  window.setTimeout(() => {
    document.getElementById(idSpeak).innerHTML = text;
  }, Numbers.number100);

  window.setTimeout(() => {
    // Remove the div element
    renderer.removeChild(document.body, document.getElementById(idSpeak));
  }, Numbers.number1000);
};
