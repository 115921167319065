import { Directive, ElementRef, HostListener, Host } from '@angular/core';
import { Numbers } from '../../utils/constants';

@Directive({
  selector: '[appThousandSep]'
})
export class ThousandSepDirective {

  /**
   * constructor function
   * @param el - a ElementRef object
   */
  constructor(private el: ElementRef) {
    this.el = el;
  }

  /**
   * Calls the evt.preventDefault() in some occasions
   * @param evt - a keyboardEvent object
   */
  @HostListener('keydown', ['$event']) onPreventNoValidDigit(evt: KeyboardEvent): void {
    const key: number = +evt.key;

    if (!((key >= 0 && key < Numbers.number10) || evt.key === 'Backspace' || evt.key === 'Delete')) {
      evt.preventDefault();
    }
  }

  /**
   * transform a number
   */
  @HostListener('keyup') onTransformNumber(): void {
    const inputNumber: any = this.el.nativeElement.value;

    this.el.nativeElement.value = this.numberWithCommas(inputNumber);
  }

  /**
   * Transform a number
   * @param x the number to transform
   * @returns the transformed number
   */
  numberWithCommas(x: any): string {
    const numberConverted: any = x
      .toString()
      .replace(/\D/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    return numberConverted;
  }
}
