import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDataInfo'
})
export class ConvertDataInfoPipe implements PipeTransform {

  /**
   * Pipe method
   * @param valueRecived - the recived value
   * @param convertValues - a property to value
   * @returns - the coverted value
   */
  transform(valueRecived: any, convertValues: object): string {
    let valueConverted: string = valueRecived;
    if (convertValues.hasOwnProperty(valueRecived.toLowerCase())) {
      valueConverted = convertValues[valueRecived.toLowerCase()];
    }

    return valueConverted;
  }
}
