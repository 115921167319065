import { Component } from '@angular/core';

@Component({
  selector: 'app-message-pop-up',
  templateUrl: './message-pop-up.component.html',
  styleUrls: [ './message-pop-up.component.scss' ]
})
// Component related to the popup message
export class MessagePopUpComponent {
}
