import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ManageRequestServiceUtils } from 'projects/portal-proveedores-front/src/app/core/providers/local/manage-request-SOS/manage-request-sos.service.utils';

@Component({
  selector: 'app-delete-note-modal',
  templateUrl: './delete-note-modal.component.html',
  styleUrls: ['./delete-note-modal.component.scss']
})
export class DeleteNoteModalComponent implements OnInit, OnDestroy {
  //#region PROPS
  @Input() selectedDetail: any;
  @Input() selectedDoc: any;

  @Output() clickEmitter = new EventEmitter<any>();
  @Output() updateTableData = new EventEmitter<any>();
  @Output() successOperation = new EventEmitter<{ success: boolean; text?: string; translate?: boolean; variableText?: string }>();
  @Output() manageSelfDisplay = new EventEmitter<boolean>();
  //#endregion

  //#region
  private subscriptions: Subscription[] = [];
  private orderId: string;
  public idNote: string;
  //#endregion

  /**
   * constructor function
   * @param manageReqSOSUtils - a ManageRequestServiceUtils object
   */
  constructor(private manageReqSOSUtils: ManageRequestServiceUtils) {
    this.manageReqSOSUtils = manageReqSOSUtils;
  }

  /**
   * OnInit method
   */
  ngOnInit(): void {
    this.clickEmitter.emit(this.handleClick.bind(this));
    this.orderId = this.selectedDetail.datosCaso.encargoID;
    this.idNote = this.selectedDoc.idNota;
  }

  /**
   * OnDestroy method
   */
  ngOnDestroy(): void {
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Unsubscribe all active subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  /**
   * Delete a note
   */
  handleClick(): void {
    const isProcesal = (this.selectedDetail && (this.selectedDetail.datosCaso.tipoFDC || '').toLowerCase() === 'procesal') || false;
    const encargoID = this.selectedDetail.datosCaso.casoID || '';

    let procesal: string = null;
    if (isProcesal) {
      procesal = 'FDC-Procesal';
    }

    this.subscriptions.push(
      this.manageReqSOSUtils
        .provAsegEliminarNota$(encargoID, this.orderId, this.idNote, procesal)
        .pipe(
          finalize(() => {
            this.manageSelfDisplay.emit(false);
          })
        )
        .subscribe((res: any) => {
          this.successOperation.emit({ success: true, text: 'NOTE-DELETED', translate: true });
          this.updateTableData.emit();
        })
    );
  }
}
