/**
 * iSOS Datos Comunes
 * La API de datos comunes iSOS proporciona a los desarrolladores un conjunto de operaciones comunes a los asegurados y proveedores
 *
 * OpenAPI spec version: 1.3
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DevolverIdPregunta } from '../../../models/api/datos-comunes/DevolverIdPregunta';
import { PreguntasRespuestas } from '../../../models/api/datos-comunes/PreguntasRespuestas';
import { PreguntasRespuestasI } from '../../../models/api/datos-comunes/PreguntasRespuestasI';
import { PreguntasRespuestasM } from '../../../models/api/datos-comunes/PreguntasRespuestasM';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class FAQSService {

  protected basePath = '/isos/datos-comunes';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath + '';
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param - consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Actualiza las preguntas y respuestas.
   *
   * @param - idPregunta Identificador de la pregunta a modificar
   * @param - preguntas Plantilla para actualizar una pregunta
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public fAQSActualizarPreguntasRespuestas(
    idPregunta: number,
    preguntas: PreguntasRespuestasM,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverIdPregunta>;
  public fAQSActualizarPreguntasRespuestas(
    idPregunta: number,
    preguntas: PreguntasRespuestasM,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverIdPregunta>>;
  public fAQSActualizarPreguntasRespuestas(
    idPregunta: number,
    preguntas: PreguntasRespuestasM,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverIdPregunta>>;
  public fAQSActualizarPreguntasRespuestas(
    idPregunta: number,
    preguntas: PreguntasRespuestasM,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (idPregunta === null || idPregunta === undefined) {
      throw new Error('Required parameter idPregunta was null or undefined when calling fAQSActualizarPreguntasRespuestas.');
    }

    if (preguntas === null || preguntas === undefined) {
      throw new Error('Required parameter preguntas was null or undefined when calling fAQSActualizarPreguntasRespuestas.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<DevolverIdPregunta>(`${ this.basePath }/faqs/v1/preguntas-respuestas/${ encodeURIComponent(String(idPregunta)) }`,
      preguntas,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Elimina preguntas y respuestas.
   *
   * @param - idPregunta Identificador de la pregunta a eliminar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public fAQSBorrarPreguntasRespuestas(
    idPregunta: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverIdPregunta>;
  public fAQSBorrarPreguntasRespuestas(
    idPregunta: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverIdPregunta>>;
  public fAQSBorrarPreguntasRespuestas(
    idPregunta: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverIdPregunta>>;
  public fAQSBorrarPreguntasRespuestas(
    idPregunta: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (idPregunta === null || idPregunta === undefined) {
      throw new Error('Required parameter idPregunta was null or undefined when calling fAQSBorrarPreguntasRespuestas.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DevolverIdPregunta>(`${ this.basePath }/faqs/v1/preguntas-respuestas/${ encodeURIComponent(String(idPregunta)) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Devuelve un listado de preguntas y respuestas.
   *
   * @param - idAplicacion Aplicacion a consultar GD o FDC
   * @param - estado 0 - No activas 1 - Activas 2 - Todas
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public fAQSConsultarPreguntasRespuestas(
    idAplicacion: string,
    estado: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<PreguntasRespuestas>>;
  public fAQSConsultarPreguntasRespuestas(
    idAplicacion: string,
    estado: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<PreguntasRespuestas>>>;
  public fAQSConsultarPreguntasRespuestas(
    idAplicacion: string,
    estado: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<PreguntasRespuestas>>>;
  public fAQSConsultarPreguntasRespuestas(
    idAplicacion: string,
    estado: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (idAplicacion === null || idAplicacion === undefined) {
      throw new Error('Required parameter idAplicacion was null or undefined when calling fAQSConsultarPreguntasRespuestas.');
    }

    if (estado === null || estado === undefined) {
      throw new Error('Required parameter estado was null or undefined when calling fAQSConsultarPreguntasRespuestas.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (estado !== undefined && estado !== null) {
      queryParameters = queryParameters.set('estado', estado as any);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<PreguntasRespuestas>>(`${ this.basePath }/faqs/v1/aplicaciones/${ encodeURIComponent(String(idAplicacion)) }/preguntas-respuestas`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Inserta preguntas y respuestas.
   *
   * @param - preguntas Plantilla para dar de alta una pregunta
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public fAQSInsertarPreguntasRespuestas(
    preguntas: PreguntasRespuestasI,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DevolverIdPregunta>;
  public fAQSInsertarPreguntasRespuestas(
    preguntas: PreguntasRespuestasI,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DevolverIdPregunta>>;
  public fAQSInsertarPreguntasRespuestas(
    preguntas: PreguntasRespuestasI,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DevolverIdPregunta>>;
  public fAQSInsertarPreguntasRespuestas(
    preguntas: PreguntasRespuestasI,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (preguntas === null || preguntas === undefined) {
      throw new Error('Required parameter preguntas was null or undefined when calling fAQSInsertarPreguntasRespuestas.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DevolverIdPregunta>(`${ this.basePath }/faqs/v1/preguntas-respuestas`,
      preguntas,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

}
