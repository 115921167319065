import { Numbers } from '../../../utils/constants';

/**
 * @description Depends on document type returns a valid regex
 * @param type Document type (DNI, NIE, CIF, NIF, Pasaporte)
 * @returns RegExp
 */
export const getValidatorFromType: (type: string) => RegExp = (type: string): RegExp => {
  let regex: RegExp;

  switch (type.toLowerCase()) {
    case 'dni':
      regex = /^(\d{8})([a-zA-Z]{1})$/;
      break;
    case 'nie':
      regex = /^([XYZ]{1})(\d{7,8})([A-Z]{1})$/;
      break;
    case 'nif':
      regex = /^(\d{8})([a-zA-Z]{1})$/;
      break;
    case 'cif':
      regex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-XJ])$/;
      break;
    case 'pasaporte':
      regex = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
      break;
    default:
      regex = /(.*?)/;
      break;
  }

  return regex;
};

/**
 * @description Validate NIF
 * @param nif User NIF
 * @returns if valid or not the NIF
 */
export const validateNIF: (nif: string) => boolean = (nif: string): boolean => {
  const allNumbersRegex: RegExp = new RegExp(/\d+/);
  const letterRegex: RegExp = new RegExp(/[A-Z]/);
  const nifRegex: RegExp = new RegExp(/^\d{8}[a-zA-Z]$/);
  let correctNIF = true;
  let isValidLetter = false;

  const letters = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];

  // ** Check if NIF has the correct format
  if (!nifRegex.test(nif)) {
    correctNIF = false;
  }

  if (correctNIF) {
    // ** Get the "REMAINDER" of the NUM and the current Letter
    const nifNumber = (nif.match(allNumbersRegex) || [])[0] || '';
    const remainder = Number(nifNumber) % Numbers.number23;

    const nifLetter = (nif.match(letterRegex) || [])[0] || '';

    isValidLetter = letters[remainder] === nifLetter;
  }

  let isValidDni = false;
  if (correctNIF) {
    isValidDni = isValidLetter;
  }
  return isValidDni;
};
