/**
 * iSOS Datos Comunes
 * La API de datos comunes iSOS proporciona a los desarrolladores un conjunto de operaciones comunes a los asegurados y proveedores
 *
 * OpenAPI spec version: 1.3
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ActualizarConsulta } from '../../../models/api/datos-comunes/ActualizarConsulta';
import { InsertarConsulta } from '../../../models/api/datos-comunes/InsertarConsulta';
import { ResActualizarConsulta } from '../../../models/api/datos-comunes/ResActualizarConsulta';
import { ResConsultasAsegurado } from '../../../models/api/datos-comunes/ResConsultasAsegurado';
import { ResInsertarConsulta } from '../../../models/api/datos-comunes/ResInsertarConsulta';
import { ResListadoMisConsultas } from '../../../models/api/datos-comunes/ResListadoMisConsultas';
import { ResMisConsultas } from '../../../models/api/datos-comunes/ResMisConsultas';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class MisConsultasService {

  protected basePath = '/isos/datos-comunes';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath + '';
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param - consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Actualiza una consulta determinada
   *
   * @param - idConsulta Id del caso que se modifica
   * @param - consulta Datos de la consulta a modificar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public misConsultasActualizarConsulta(
    idConsulta: string,
    consulta: ActualizarConsulta,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResActualizarConsulta>;
  public misConsultasActualizarConsulta(
    idConsulta: string,
    consulta: ActualizarConsulta,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResActualizarConsulta>>;
  public misConsultasActualizarConsulta(
    idConsulta: string,
    consulta: ActualizarConsulta,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResActualizarConsulta>>;
  public misConsultasActualizarConsulta(
    idConsulta: string,
    consulta: ActualizarConsulta,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (idConsulta === null || idConsulta === undefined) {
      throw new Error('Required parameter idConsulta was null or undefined when calling misConsultasActualizarConsulta.');
    }

    if (consulta === null || consulta === undefined) {
      throw new Error('Required parameter consulta was null or undefined when calling misConsultasActualizarConsulta.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<ResActualizarConsulta>(`${ this.basePath }/mis-consultas/v1/preguntas/${ encodeURIComponent(String(idConsulta)) }`,
      consulta,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Insertar una consulta
   *
   * @param - consulta Datos de la consulta a guardar
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public misConsultasInsertarConsulta(
    consulta: InsertarConsulta,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResInsertarConsulta>;
  public misConsultasInsertarConsulta(
    consulta: InsertarConsulta,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResInsertarConsulta>>;
  public misConsultasInsertarConsulta(
    consulta: InsertarConsulta,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResInsertarConsulta>>;
  public misConsultasInsertarConsulta(
    consulta: InsertarConsulta,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (consulta === null || consulta === undefined) {
      throw new Error('Required parameter consulta was null or undefined when calling misConsultasInsertarConsulta.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ResInsertarConsulta>(`${ this.basePath }/mis-consultas/v1/preguntas`,
      consulta,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Devuelve una consulta determinada
   *
   * @param - idConsulta Id del caso que se consulta
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public misConsultasRecuperarConsulta(
    idConsulta: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResMisConsultas>;
  public misConsultasRecuperarConsulta(
    idConsulta: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResMisConsultas>>;
  public misConsultasRecuperarConsulta(
    idConsulta: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResMisConsultas>>;
  public misConsultasRecuperarConsulta(
    idConsulta: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (idConsulta === null || idConsulta === undefined) {
      throw new Error('Required parameter idConsulta was null or undefined when calling misConsultasRecuperarConsulta.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ResMisConsultas>(`${ this.basePath }/mis-consultas/v1/preguntas/${ encodeURIComponent(String(idConsulta)) }`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Devuelve una consulta por asegurado
   *
   * @param - idAsegurado Id del asegurado que se consulta
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public misConsultasRecuperarConsultaAsegurado(
    idAsegurado: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResConsultasAsegurado>;
  public misConsultasRecuperarConsultaAsegurado(
    idAsegurado: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResConsultasAsegurado>>;
  public misConsultasRecuperarConsultaAsegurado(
    idAsegurado: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResConsultasAsegurado>>;
  public misConsultasRecuperarConsultaAsegurado(
    idAsegurado: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (idAsegurado === null || idAsegurado === undefined) {
      throw new Error('Required parameter idAsegurado was null or undefined when calling misConsultasRecuperarConsultaAsegurado.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ResConsultasAsegurado>(`${ this.basePath }/mis-consultas/v1/asegurados/${ encodeURIComponent(String(idAsegurado)) }/preguntas`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Devuelve un listado de consultas de un caso
   *
   * @param - identificador Id del caso que se consulta
   * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param - reportProgress flag to report request and response progress.
   */
  public misConsultasRecuperarListadoConsulta(
    identificador: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResListadoMisConsultas>;
  public misConsultasRecuperarListadoConsulta(
    identificador: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResListadoMisConsultas>>;
  public misConsultasRecuperarListadoConsulta(
    identificador: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResListadoMisConsultas>>;
  public misConsultasRecuperarListadoConsulta(
    identificador: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {

    if (identificador === null || identificador === undefined) {
      throw new Error('Required parameter identificador was null or undefined when calling misConsultasRecuperarListadoConsulta.');
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ResListadoMisConsultas>(`${ this.basePath }/mis-consultas/v1/casos/${ encodeURIComponent(String(identificador)) }/preguntas`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

}
